import styled from 'styled-components'
import { SearchBarColors } from '../../../styles/setings/colors'
import { desktop, tablet, mobile } from '../../../styles/setings/mediaScreens'

export const SearchStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 30px;
  background-color: ${SearchBarColors.colorBgSearch};
  height: 75px;
  padding-top: 10px;
  ${desktop`
    margin-top: -45px;
    align-items: flex-end;
    // margin-bottom: 20px;
    padding-right: 15px;
  `}
  ${tablet`
    margin-top: -32px;
    align-items: flex-end;
    // margin-bottom: 20px;
    padding-right: 15px;
  `}
  ${mobile`
    margin-top: 0px;
    align-items: flex-end;
    // margin-bottom: 20px;
    padding-right: 15px;
  `}
  .searchContainer {
    position: relative;

    ${mobile`
        width: calc(100% - 15px);
      `}

    input {
      display: inline-block;
      border: none;
      /* border-bottom: solid ${SearchBarColors.colorBgSearchButton} 2px; */
      // border-bottom-left-radius: 15px 15px;
      // border-top-left-radius: 15px 15px;
      border-radius: 15px;
      height: 1.6em;
      width: 190px;
      color: $black;
      font-size: 1em;
      // font-weight: bold;
      text-align: center;
      ${tablet`
        width: 100%;
      `}
      ${mobile`
        width: 100%;
      `}

      &:focus {
        background-color: #ccc;
        border-bottom: none;
        border-radius: 50px;
        border: transparent;

        &::placeholder {
          color: transparent;
        }
      }
    }

    .searchBtn {
      position: absolute;
      background-color: ${SearchBarColors.colorBgSearchButton};
      color: ${SearchBarColors.colorTextSearchButton};
      border-radius: 50%;
      border: none;
      right: 0;
      top: 1px;
      height: 1.7em;
      width: 1.7em;
      padding: 5px;
      &:hover {
        background-color: ${SearchBarColors.buttonColorHoverDefault};
      }
    }

    .clearSearch {
      position: absolute;
      right: 1px;
      top: 1px;
      height: 23px;
      width: 23px;
      border-radius: 50%;
      color: #f0f0f0;
      background-color: #f00;
      border: none;
      font-weight: bold;
      font-size: 1.2rem;
      cursor: pointer;
      &:hover {
        background-color: #ca4848;
      }
    }
  }

  p {
    margin-top: 5px;
    /* background-color: green; */
    ${tablet`
        margin-top: 15px;
      `}
  }

  ul {
    position: fixed;
    width: 100%;
    min-width: 300px;
    max-height: 300px; /* Set a max height for the scrollable area */
    top: 130px;
    right: 5px;
    overflow-y: auto;
    background-color: ${SearchBarColors.resultBgColor};
    z-index: 100;
    padding: 0; /* Remove any default padding */
    margin: 0; /* Remove any default margin */
    ${desktop`
      top: 100px;
    `}
    ${desktop`
      top: 100px;
    `}
    ${tablet`
      top: 98px;
    `}
    ${mobile`
      top: 120px;
    `}

    li {
      padding: 5px;
      margin: 5px;
      color: ${SearchBarColors.resultTextColor};
      cursor: pointer;
      &:hover {
        background-color: ${SearchBarColors.resultHoverBgColor};
      }
    }
  }
`

// .search .container {
//   margin-top: 10px;
//   .row {
//     .searchBar {
//       display: flex;
//       justify-content: flex-end;
//       align-items: center;
//       ${tablet`
//         width: 100%;
//         justify-content: center;
//         `}

//       ${mobile`
//         width: 100%;
//         justify-content: center;
//         `}

//       form {
//         ${tablet`
//           width: 300px;
//           `}

//         ${mobile`
//           width: 300px;
//           `}
// .searchInput {
//   position: relative;
//   input {
//     display: inline-block;
//     border: none;
//     border-bottom: solid ${COLORS.colorBgSearchButton} 2px;
//     // border-bottom-left-radius: 15px 15px;
//     // border-top-left-radius: 15px 15px;
//     border-radius: 15px;
//     height: 1.6em;
//     width: 190px;
//     color: $black;
//     font-size: 1em;
//     // font-weight: bold;
//     text-align: center;
//     ${tablet`
//       width: 100%;
//       `}

//     &:focus {
//       background-color: #ccc;
//       border-bottom: none;
//       border-radius: 50px;
//       border: transparent;

//       &::placeholder {
//         color: transparent;
//       }
//     }
//   }
// .searchBtn {
//   position: absolute;
//   background-color: ${COLORS.colorBgSearchButton};
//   color: ${COLORS.colorTextSearchButton};
//   border-radius: 50%;
//   border: none;
//   left: 0;
//   top: 1px;
//   height: 1.7em;
//   width: 1.7em;
//   padding: 5px;
//   &:hover {
//     background-color: ${COLORS.buttonColorHoverDefault};
//   }
// }
//         }
//       }
//     }
//   }
// }
