// import React, { useRef } from 'react'
// import { HorizontalScrollingMenuStyled } from './HorizontalScrollingMenuStyled'

// const HorizontalScrollingMenu = ({ items, onElementClick }) => {
//   const scrollContainerRef = useRef(null)

//   const handleScroll = (direction) => {
//     const container = scrollContainerRef.current
//     const scrollAmount = direction === 'left' ? -160 : 160
//     container.scrollBy({ left: scrollAmount, behavior: 'smooth' })
//   }

//   const handleItemClick = (index, link) => {
//     const container = scrollContainerRef.current
//     const nextIndex = (index + 1) % items.length // Get the next index, looping back to the first
//     const nextItem = container.children[nextIndex]
//     nextItem.scrollIntoView({ behavior: 'smooth', inline: 'center' })

//     // Trigger the external callback function
//     if (onElementClick) {
//       onElementClick(index, link)
//     }
//   }

//   return (
//     <HorizontalScrollingMenuStyled>
//       <button
//         className='scroll-button left'
//         onClick={() => handleScroll('left')}
//       >
//         {'<'}
//       </button>
//       <div className='scrolling-menu' ref={scrollContainerRef}>
//         {items.map((el, index) => (
//           <div
//             className='menu-item'
//             key={index}
//             onClick={() => handleItemClick(index, el.category_link)}
//             style={{ cursor: 'pointer' }}
//           >
//             {el.name}
//           </div>
//         ))}
//       </div>
//       <button
//         className='scroll-button right'
//         onClick={() => handleScroll('right')}
//       >
//         {'>'}
//       </button>
//     </HorizontalScrollingMenuStyled>
//   )
// }

// export default HorizontalScrollingMenu

// ------------------
// import React, { useRef } from 'react'
// import { HorizontalScrollingMenuStyled } from './HorizontalScrollingMenuStyled'

// const HorizontalScrollingMenu = ({ items, onElementClick }) => {
//   const scrollContainerRef = useRef(null)

//   const handleScroll = (direction) => {
//     const container = scrollContainerRef.current
//     const scrollAmount = direction === 'left' ? -135 : 135
//     container.scrollBy({ left: scrollAmount, behavior: 'smooth' })
//   }

//   const handleItemClick = (index, link) => {
//     const container = scrollContainerRef.current
//     const nextIndex = (index + 1) % items.length // Get the next index, looping back to the first

//     // Calculate the next item's position and scroll horizontally
//     // const nextItem = container.children[nextIndex]
//     // const itemWidth = nextItem.offsetWidth
//     // const scrollLeft = nextItem.offsetLeft - container.scrollLeft

//     // container.scrollBy({ left: scrollLeft, behavior: 'smooth' })

//     // Calculate the next item's position and scroll horizontally
//     const nextItem = container.children[nextIndex]
//     const scrollLeft = nextItem.offsetLeft - (container.scrollLeft + 75)

//     container.scrollBy({ left: scrollLeft, behavior: 'smooth' })

//     // Trigger the external callback function
//     if (onElementClick) {
//       onElementClick(index, link)
//     }
//   }

//   return (
//     <HorizontalScrollingMenuStyled>
//       <button
//         className='scroll-button left'
//         onClick={() => handleScroll('left')}
//       >
//         {'<'}
//       </button>
//       <div className='scrolling-menu' ref={scrollContainerRef}>
//         {items.map((el, index) => (
//           <div
//             className='menu-item'
//             key={index}
//             onClick={() => handleItemClick(index, el.category_link)}
//             style={{ cursor: 'pointer' }}
//           >
//             {/* {el.name}
//             {el.image && (
//               <img src={el.image} alt={el.name} style={{ maxWidth: '100%' }} />
//             )} */}
//             <div className='itemName'>
//               <h2>{el.name}</h2>
//             </div>
//             {el.image && <img src={el.image} alt={el.name} />}
//           </div>
//         ))}
//       </div>
//       <button
//         className='scroll-button right'
//         onClick={() => handleScroll('right')}
//       >
//         {'>'}
//       </button>
//     </HorizontalScrollingMenuStyled>
//   )
// }

// export default HorizontalScrollingMenu

// ------------------

// import React, { useRef, useEffect } from 'react'
// import { HorizontalScrollingMenuStyled } from './HorizontalScrollingMenuStyled'

// const HorizontalScrollingMenu = ({ items, onElementClick }) => {
//   const scrollContainerRef = useRef(null)

//   const handleScroll = (direction) => {
//     const container = scrollContainerRef.current
//     const scrollAmount = direction === 'left' ? -135 : 135
//     container.scrollBy({ left: scrollAmount, behavior: 'smooth' })
//   }

//   const handleItemClick = (index, link) => {
//     const container = scrollContainerRef.current
//     const nextIndex = (index + 1) % items.length

//     const nextItem = container.children[nextIndex]
//     const scrollLeft = nextItem.offsetLeft - (container.scrollLeft + 20)

//     container.scrollBy({ left: scrollLeft, behavior: 'auto' })

//     if (onElementClick) {
//       onElementClick(index, link)
//     }
//   }

//   const handleWheel = (event) => {
//     const container = scrollContainerRef.current

//     if (!container) return

//     // Prevent vertical scrolling and enable horizontal scrolling
//     event.preventDefault()
//     container.scrollBy({ left: event.deltaY, behavior: 'auto' })
//   }

//   useEffect(() => {
//     const container = scrollContainerRef.current

//     if (container) {
//       container.addEventListener('wheel', handleWheel, { passive: false })
//     }

//     return () => {
//       if (container) {
//         container.removeEventListener('wheel', handleWheel)
//       }
//     }
//   }, [])

//   return (
//     <HorizontalScrollingMenuStyled>
//       <button
//         className='scroll-button left'
//         onClick={() => handleScroll('left')}
//       >
//         {'<'}
//       </button>
//       <div className='scrolling-menu' ref={scrollContainerRef}>
//         {items.map((el, index) => (
//           <div
//             className='menu-item'
//             key={index}
//             onClick={() => handleItemClick(index, el.category_link)}
//             style={{ cursor: 'pointer' }}
//           >
//             <div className='itemName'>
//               <h2>{el.name}</h2>
//             </div>
//             {el.image && <img src={el.image} alt={el.name} />}
//           </div>
//         ))}
//       </div>
//       <button
//         className='scroll-button right'
//         onClick={() => handleScroll('right')}
//       >
//         {'>'}
//       </button>
//     </HorizontalScrollingMenuStyled>
//   )
// }

// export default HorizontalScrollingMenu

// ------------------
import React, { useRef, useEffect, useState } from 'react'
import { HorizontalScrollingMenuStyled } from './HorizontalScrollingMenuStyled'

const HorizontalScrollingMenu = ({ items, onElementClick }) => {
  const scrollContainerRef = useRef(null)
  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)
  const [activeIndex, setActiveIndex] = useState(null) // Track the active item

  const handleScroll = (direction) => {
    const container = scrollContainerRef.current
    const scrollAmount = direction === 'left' ? -135 : 135
    container.scrollBy({ left: scrollAmount, behavior: 'smooth' })
  }

  const handleItemClick = (index, link) => {
    const container = scrollContainerRef.current
    const nextIndex = (index + 1) % items.length
    const nextItem = container.children[nextIndex]
    const scrollLeft = nextItem.offsetLeft - (container.scrollLeft + 20)

    container.scrollBy({ left: scrollLeft, behavior: 'auto' })

    // Update the active item index
    setActiveIndex(index)

    if (onElementClick) {
      onElementClick(index, link)
    }
  }

  const handleWheel = (event) => {
    const container = scrollContainerRef.current

    if (!container) return

    event.preventDefault()
    container.scrollBy({ left: event.deltaY, behavior: 'auto' })
  }

  const handleMouseDown = (event) => {
    const container = scrollContainerRef.current
    setIsDragging(true)
    setStartX(event.pageX - container.offsetLeft)
    setScrollLeft(container.scrollLeft)
  }

  const handleMouseMove = (event) => {
    if (!isDragging) return
    event.preventDefault()
    const container = scrollContainerRef.current
    const x = event.pageX - container.offsetLeft
    const walk = (x - startX) * 1.5
    container.scrollLeft = scrollLeft - walk
  }

  const handleMouseUp = () => {
    setIsDragging(false)
  }

  const handleMouseLeave = () => {
    setIsDragging(false)
  }

  useEffect(() => {
    const container = scrollContainerRef.current

    if (container) {
      container.addEventListener('wheel', handleWheel, { passive: false })
    }

    return () => {
      if (container) {
        container.removeEventListener('wheel', handleWheel)
      }
    }
  }, [])

  return (
    <HorizontalScrollingMenuStyled>
      <button
        className='scroll-button left'
        onClick={() => handleScroll('left')}
      >
        {'<'}
      </button>
      <div
        className='scrolling-menu'
        ref={scrollContainerRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseLeave}
      >
        {items.map((el, index) => (
          <div
            className={`menu-item ${activeIndex === index ? 'active' : ''}`} // Apply active class
            key={index}
            onClick={() => handleItemClick(index, el.category_link)}
            style={{ cursor: 'pointer' }}
          >
            <div className='itemName'>
              <h2>{el.name}</h2>
            </div>
            {el.image && <img src={el.image} alt={el.name} />}
          </div>
        ))}
      </div>
      <button
        className='scroll-button right'
        onClick={() => handleScroll('right')}
      >
        {'>'}
      </button>
    </HorizontalScrollingMenuStyled>
  )
}

export default HorizontalScrollingMenu

// ------------------

// usage in parent
// const [selectedElement, setSelectedElement] = useState(0) // Default to the first category
// const navigate = useNavigate()

// const handleElementClick = (index, categoryLink) => {
//   setSelectedElement(index)
//   navigate(`/categories/${categoryLink}`)
// }

// <HorizontalScrollingMenu
//   items={categories}
//   onElementClick={handleElementClick}
// />
