import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useParams, useNavigate } from 'react-router'
import { getExtraProductOptions } from '../../../api/shopAPI'
import { ExtraProductOptionsStyled } from '../../../styles/elements/products/ExtraProductOptionsStyled'
import capitalizeFirstLetter from '../../../functions/capinalizeFirstLetter'

import TogleSwitch from '../../../components/common/buttons/togleSwitch/TogleSwitch'

function ExtraProductOptions({
  inCartextraProducts,
  dayOpen,
  prodCat,
  onExtraOptions,
}) {
  const [extraOptions, setExtraOptions] = useState([])

  useEffect(() => {
    getExtraProductOptions(prodCat, (response) => {
      setExtraOptions(response)
    })
  }, [prodCat])

  const handleCheckboxChange = (id, name, price, currency, event) => {
    const numericPrice = parseFloat(price) // Convert price to a number
    onExtraOptions(id, name, numericPrice, currency, event.target.checked)
  }

  return (
    <>
      {extraOptions.length > 0 ? (
        <>
          <ExtraProductOptionsStyled>
            <div>
              <h2>Optiuni extra</h2>
            </div>
            <div className='listContainer'>
              <ul className='optionList'>
                {extraOptions.map((el, index) => (
                  <li key={index}>
                    <label>
                      {/* <input
                        type='checkbox'
                        onChange={(event) =>
                          handleCheckboxChange(
                            el.id,
                            el.name,
                            el.price,
                            el.currency.currency_short,
                            event
                          )
                        }
                      /> */}
                      <TogleSwitch
                        // checked={el.active === 1}
                        onChange={(event) =>
                          handleCheckboxChange(
                            el.id,
                            el.name,
                            el.price,
                            el.currency.currency_short,
                            event
                          )
                        }
                        // label={el.active === 1 ? 'Dezactiveaza' : 'Activeaza'}
                      />
                      {capitalizeFirstLetter(el.name)} {el.weight}
                      {el.weight_classes.name_short} - {el.price}{' '}
                      {el.currency.currency_short}
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          </ExtraProductOptionsStyled>
        </>
      ) : (
        ''
      )}
    </>
  )
}

export default ExtraProductOptions

// <div className='extraProductsContainer'>
//   <div className='extraProductsName'>
//     <h1>
//       {extraProducts.name} {extraProducts.weight}
//       {/* {extraProducts.weight_classes.name_short} */}
//     </h1>
//   </div>
//   <div className='shortNavContainer'>
//     <Link to='/' className='shortNav'>
//       HOME
//     </Link>{' '}
//     /{' '}
//     <Link to='/meniu' className='shortNav'>
//       Meniu
//     </Link>{' '}
//     /{' '}
//     <Link
//       to={`/meniu/${extraProducts.extraProducts_category_link}`}
//       className='shortNav'
//     >
//       {extraProducts.extraProducts_category_link}
//     </Link>
//   </div>
//   <div className='extraProducts'>
//     <div className='extraProductsData'>
//       <div className='price'>
//         {extraProducts.price}
//         <span className='currency'>
//           {/* {extraProducts.currency.currency_short} */}
//         </span>
//       </div>
//       <div className='eanCode'>
//         Cod produs:
//         <span className='currency'>{extraProducts.ean_code}</span>
//       </div>
//       <div className='extraProductsDetails'>
//         {extraProducts.ingredients && (
//           <div className='ingredients'>
//             <span>Ingrediente:</span> {extraProducts.ingredients}
//           </div>
//         )}
//         {extraProducts.allergens && (
//           <div className='allergens'>
//             <span>Allergeni:</span> {extraProducts.allergens}
//           </div>
//         )}
//         {extraProducts.nutritional_declaration && (
//           <div className='nutritionalDeclaration'>
//             <span>Declaratie nutritionala:</span>{' '}
//             {extraProducts.nutritional_declaration}
//           </div>
//         )}
//         {extraProducts.energetic_value && (
//           <div className='energeticValue'>
//             <span>Valoare energetica:</span>{' '}
//             {extraProducts.energetic_value} kcal / 100{' '}
//             {extraProducts.units}
//           </div>
//         )}
//         {extraProducts.frozen_extraProductss === 1 && (
//           <div className='frozenextraProducts'>
//             Contine produse congelata
//           </div>
//         )}
//         {extraProducts.aditivi === 1 && (
//           <div className='frozenextraProducts'>Contine aditivi</div>
//         )}
//       </div>

//       {/* {isOpen &&
//       getCurrentTime() >
//         extraProducts.extraProducts_category.delivery_from_time &&
//       getCurrentTime() <
//         extraProducts.extraProducts_category.delivery_to_time ? (
//         <AddToCartLocalStorage
//           id={extraProducts.id}
//           eanCode={extraProducts.ean_code}
//           extraProductsName={extraProducts.name}
//           addToCartStyle='singleextraProducts'
//           inCartextraProducts={inCartextraProducts}
//         />
//       ) : (
//         <div className='noDeliveryTime'>
//           <span>{messages.msgNoDeliveryTime}</span>
//           <span>
//             {messages.msgDeliveryHours}{' '}
//             {
//               extraProducts.extraProducts_category
//                 .delivery_from_time
//             }{' '}
//             -{' '}
//             {extraProducts.extraProducts_category.delivery_to_time}
//           </span>
//         </div>
//       )} */}
//     </div>
//   </div>
//   <div>extra extraProducts</div>
// </div>
