import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import {
  getAboutUnit,
  updateAboutUnit,
  updateAboutUnitActive,
} from '../../../../api/unitDataAPI'
import { AboutUnitFormStyled } from './AboutUnitFormStyled'
import {
  GeneralFormStyled,
  FieldLabel,
  InfoLabel,
  SuccesLabel,
  ErrorLabel,
} from '../../../../styles/elements/formsStyle'
import {
  SubmitButton,
  RedSubmitButton,
  GreenSubmitButton,
} from '../../../../styles/elements/buttons'

import SuccesAlert from '../../../common/alert/SuccesAlert'
import ErrorAlert from '../../../common/alert/ErrorAlert'
import BackToSetings from '../../../common/buttons/backToSetings/BackToSetings'
import FadeLoading from '../../../common/loaders/fadeLoading/FadeLoading'

import SingleImageUploaderForm from '../singleImageUploaderForm/SingleImageUploaderForm'

const cleanFormData = {
  // unit_id: '',
  title: '',
  description: '',
  // image: '',
}

const validation = {
  title: (data) => data.title.length < 10,
  description: (data) => data.description.length < 50,
}

function AboutUnitForm() {
  const [formData, setFormData] = useState(cleanFormData)
  const [unitAbout, setUnitAbout] = useState({})
  const [showChangeAboutImage, setShowChangeAboutImage] = useState(null)
  const [errors, setErrors] = useState([])
  const [responseErrors, setResponseErrors] = useState([])
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const ActivateButtonComponent =
    unitAbout.active === 1 ? RedSubmitButton : GreenSubmitButton

  useEffect(() => {
    getAboutUnit((response) => {
      setUnitAbout(response)
    })
  }, [])

  console.log(unitAbout)

  useEffect(() => {
    if (unitAbout && Object.keys(unitAbout).length > 0) {
      setFormData({
        title: unitAbout.title || '',
        description: unitAbout.description || '',
        // image: unitAbout.image || '',
      })
    }
  }, [unitAbout])

  const handleChange = (e) => {
    const { name, value } = e.target

    // Handle other fields
    if (!validation[name]({ ...formData, [name]: value })) {
      setErrors(errors.filter((el) => el !== name))
    } else {
      if (!errors.includes(name)) setErrors([...errors, name])
    }

    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleActivateSection = (event) => {
    event.preventDefault()
    const newActive = unitAbout.active === 1 ? { active: 0 } : { active: 1 }

    // Set loading state to true
    setIsLoading(true)

    updateAboutUnitActive(
      unitAbout.id,
      newActive,
      handleActivateSuccess,
      handleError
    )
  }

  function handleActivateSuccess(response) {
    console.log(response.active)

    setAlertMessage(
      'Sectiunea a fost ' +
        (unitAbout.active === 1 ? 'dezactivata' : 'activata') +
        ' cu succes!'
    )
    setShowAlert(true)

    // Reset loading state
    setIsLoading(false)
    // set new unit data
    setUnitAbout(response)
    const timer = setTimeout(() => {
      // reload page
      window.location.reload()
    }, 2100)
    return () => clearTimeout(timer)
  }

  // console.log(formData.image);
  const handleSubmit = (event) => {
    event.preventDefault()

    // Set loading state to true
    setIsLoading(true)

    const validationErrors = Object.keys(validation).filter((el) =>
      validation[el](formData)
    )

    setErrors(validationErrors)

    if (validationErrors.length === 0) {
      updateAboutUnit(unitAbout.id, formData, handleSuccess, handleError)
    }
  }

  function handleError(error) {
    console.error(error)

    // Parse the error response
    const errorResponse = error.response.data
    const parsedErrors = Object.values(errorResponse).flat()

    // Update the state with the parsed errors
    setResponseErrors(parsedErrors)

    // Reset loading state
    setIsLoading(false)
  }

  function handleSuccess(response) {
    // console.log(response)
    // alert('Contul a fost creat cu succes!')
    setAlertMessage('Datele au fost modificate cu succes!')
    setShowAlert(true)
    // setFormData(cleanFormData)
    // navigate('/login')

    // Reset loading state
    setIsLoading(false)

    const timer = setTimeout(() => {
      // window.location.reload()
      // window.location.href = '/setari'
      setShowChangeAboutImage(
        showChangeAboutImage === unitAbout.id ? null : unitAbout.id
      )
    }, 2100)
    return () => clearTimeout(timer)
  }

  // Function to remove an error message from the state
  const removeError = (index) => {
    setResponseErrors(responseErrors.filter((_, i) => i !== index))
  }

  return (
    <>
      {showAlert && <SuccesAlert message={alertMessage} />}

      <div>
        {responseErrors.map((message, index) => (
          <ErrorAlert
            key={index}
            message={message}
            onRemove={() => removeError(index)}
          />
        ))}
      </div>
      <BackToSetings />
      {unitAbout ? (
        <AboutUnitFormStyled>
          <div className='title'>
            <h2>Despre unitate</h2>
          </div>
          <div className='changeStatusFormContainer'>
            <ActivateButtonComponent
              onClick={handleActivateSection}
              disabled={isLoading}
            >
              {unitAbout.active === 1
                ? isLoading
                  ? 'Asteapta! Se incarca...'
                  : 'Dezactiveaza sectiunea'
                : isLoading
                ? 'Asteapta! Se incarca...'
                : 'Activeaza sectiunea'}
            </ActivateButtonComponent>
          </div>
          {unitAbout.id && (
            <GeneralFormStyled>
              <form onSubmit={handleSubmit}>
                <div className='oneField'>
                  <div>
                    <FieldLabel>
                      Titlu
                      {errors.includes('title') === true ? (
                        <ErrorLabel>Titlu prea scurt!</ErrorLabel>
                      ) : formData.title.length >= 10 ? (
                        <SuccesLabel>&#10004;</SuccesLabel>
                      ) : (
                        <InfoLabel>Minim 10 caractere</InfoLabel>
                      )}
                    </FieldLabel>
                    <input
                      type='text'
                      name='title'
                      value={formData.title}
                      onChange={handleChange}
                      placeholder='Titlu'
                    />
                  </div>
                </div>
                <div className='oneField'>
                  <div>
                    <FieldLabel>
                      Descriere unitate
                      {errors.includes('description') === true ? (
                        <ErrorLabel>Descrierea este prea scurta!</ErrorLabel>
                      ) : formData.description.length >= 50 ? (
                        <SuccesLabel>&#10004;</SuccesLabel>
                      ) : (
                        <InfoLabel>Minim 3 caractere</InfoLabel>
                      )}
                    </FieldLabel>
                    <textarea
                      name='description'
                      value={formData.description}
                      onChange={handleChange}
                      placeholder='Descriere unitate'
                    />
                  </div>
                </div>
                <div className='aboutImage'>
                  <img src={unitAbout.image} alt='' />
                </div>
                <div className='submitContainer'>
                  <SubmitButton disabled={isLoading}>
                    {isLoading
                      ? 'Asteapta! Se incarca...'
                      : !unitAbout.title
                      ? 'Salveaza'
                      : 'Modifica datele'}
                  </SubmitButton>
                </div>
              </form>
            </GeneralFormStyled>
          )}
          {unitAbout.id && (
            <div className='changeImageButtonFormContainer'>
              Nu este obilgatoriu sa incarcati o imagine dar poate avea un
              impact placut pentru utilizatori.
              <RedSubmitButton
                onClick={() => {
                  setShowChangeAboutImage(
                    showChangeAboutImage === unitAbout.id ? null : unitAbout.id
                  )
                }}
              >
                {unitAbout.image ? 'Schimba imaginea' : 'Adauga imagine'}
              </RedSubmitButton>
              {showChangeAboutImage === unitAbout.id ? (
                <SingleImageUploaderForm
                  apiName={'updateAboutUnitPhoto'}
                  id={unitAbout.id}
                  formTitle={
                    unitAbout.image ? 'Schimba imaginea' : 'Adauga imagine'
                  }
                  oldImage={unitAbout.image}
                  uploadFolder={'about'}
                  fileType={''}
                  cancel={() => setShowChangeAboutImage(null)}
                  backUrl={'setari'}
                />
              ) : (
                (document.body.style.overflow = '') // Re-enable body scroll
              )}
            </div>
          )}
        </AboutUnitFormStyled>
      ) : (
        <FadeLoading />
        // <p>Incarc datele</p>
      )}
    </>
  )
}

export default AboutUnitForm
