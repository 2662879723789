// PaginationStyled.js
import styled from 'styled-components'
import { mobile } from '../../../styles/setings/mediaScreens'

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
  /* width: 100%; */

  .PageButton {
    /* background: ${(props) => (props.active ? '#007bff' : '#fff')}; */
    background-color: #fff;
    border: 1px solid #ccc;
    /* color: ${(props) => (props.active ? '#fff' : '#007bff')}; */
    /* padding: 10px 20px; */
    margin: 0 5px;
    /* cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')}; */
    height: 50px;
    width: 50px;
    border-radius: 50%;
    font-size: 1.2em;
    font-weight: bold;

    ${mobile`
    // padding: 5px 10px;
        height: 25px;
        width: 25px;
        font-size: 0.8em;
    `}

    &:hover {
      /* background: ${(props) =>
        !props.active && !props.disabled ? '#f0f0f0' : ''}; */
      background-color: #f0f0f0;
      cursor: pointer;
    }
  }

  .ActiveButton {
    background-color: #007bff;
    color: #fff;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    border: 1px solid #ccc;
    /* padding: 10px 20px; */
    margin: 0 5px;
    font-size: 1.2em;
    font-weight: bold;
    ${mobile`
    // padding: 5px 10px;
        height: 25px;
        width: 25px;
        font-size: 0.8em;
    `}
  }
  .dots {
    padding: 5px 10px;
    font-size: 1.2em;
    color: #007bff;
    ${mobile`
        font-size: 0.8em;
    `}
  }
`
