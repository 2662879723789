import styled from 'styled-components'
import { ProductColors } from '../setings/colors'
import { desktop, tablet, mobile } from '../setings/mediaScreens'

export const NoDataStyled = styled.div`
  .noData {
    margin: 8px 8px 8px 0;
    padding: 5px;
    background-color: ${ProductColors.colorProductTitleBg};
    border-bottom: solid ${ProductColors.colorProductBorder} 1px;

    ${mobile`
        border-bottom: none;
        width: 100%;
      `}

    h1 {
      font-size: 2rem;
      font-weight: bold;
      color: ${ProductColors.colorProductTitle};
      text-align: center;
    }
  }
`
