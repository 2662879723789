import styled from 'styled-components'
import { desktop, tablet, mobile } from '../../setings/mediaScreens'
import { ProductColors } from '../../setings/colors'

export const ProductsListStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  border-radius: 15px 15px 0 0;
  ${desktop`
    margin-top: 0;
    `}
  ${tablet`
    margin-top: 20px;
     width: 100%;
    `}
  ${mobile`
    margin-top: 20px;
     width: 100%;
    `}
    
  .catTitle {
    width: 100%;
    display: flex;
    padding: 10px 0;
    margin-bottom: 20px;

    h2 {
      display: inline-flex;
      color: ${ProductColors.colorProductCategoryTitle};
      /* text-decoration: underline;
      text-decoration-thickness: 4px;
      text-decoration-color: ${ProductColors.colorProductUnderline}; */

      ${desktop`
        align-items: center;
        justify-content: center;    
        width: 100%;
        font-size: 1.8rem;
      `}
      ${tablet`
        align-items: center;
        justify-content: center;    
        width: 100%;
        font-size: 1.5em;
      `}
    ${mobile`
        align-items: center;
        justify-content: center;    
        width: 100%;
      font-size: 1.3em;
    `}
    }
  }

  .listContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    max-width: 1300px;
  }

  .productContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: calc(50% - 25px);
    background-color: ${ProductColors.colorProductBg};
    border: none;
    border-bottom: solid ${ProductColors.colorProductBorder} 1px;
    margin: 0px 10px 30px 10px;
    ${mobile`
        width: 100%;
      `}

    .productDataContainer {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      /* height: 220px; */
      cursor: pointer;
      ${mobile`
        flex-direction: column;
        height: auto;
      `}

      .productImage {
        /* width: 100%; */
        width: 160px;
        ${mobile`
          width: 100%;
        `}

        .coverImg {
          width: 155px;
          border-radius: 15px;
          border: solid ${ProductColors.colorProductImageBorder} 3px;
          height: 120px;
          padding: 5px;
          ${mobile`
            width: 100%;
            height: auto;
          `}
        }
      }

      .productData {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin: 0 0 0 10px;
      }

      .productName {
        margin: 8px 8px 8px 0;
        padding: 5px;
        background-color: ${ProductColors.colorProductTitleBg};
        border-bottom: solid ${ProductColors.colorProductBorder} 1px;
        ${mobile`
            border-bottom: none;
        `}
        h2 {
          font-size: 1.4rem;
          font-weight: bold;
          color: ${ProductColors.colorProductTitle};

          /* line-height: 1.2em; */
          ${mobile`
            text-align: center;
          `}
        }

        ${mobile`
      width: 100%;
      `}
      }

      .productDetails {
        margin: 5px 0;
        color: ${ProductColors.colorProductText};
      }

      .price {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        color: ${ProductColors.colorProductPrice};
        margin-top: 10px;
        padding: 10px 0;
        font-size: 1.8rem;
        font-weight: bold;
        /* border-radius: 0 0 15px 15px; */
        .currency {
          margin-left: 5px;
          color: ${ProductColors.colorProductCurrency};
          font-size: 1.2rem;
        }
      }

      .stock {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        /* background-color: grey; */
        padding: 10px 0;
        font-weight: bold;
      }
      .frozenProduct {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        /* background-color: grey; */
        padding: 10px 0;
        height: 1.8em;
        font-weight: bold;
      }
    }

    .noProducts {
      width: 100%;
      display: flex;
      padding: 10px 0;
      margin-bottom: 20px;

      h2 {
        display: inline-flex;
        color: ${ProductColors.colorProductCategoryTitle};

        ${desktop`
        align-items: center;
        justify-content: center;    
        width: 100%;
        font-size: 1.8rem;
        `}
        ${tablet`
        align-items: center;
        justify-content: center;    
        width: 100%;
        font-size: 1.5em;
        `}
        ${mobile`
            align-items: center;
            justify-content: center;    
            width: 100%;
          font-size: 1.3em;
        `}
      }
    }

    .noDeliveryTime {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 10px 0;
      margin: 10px 0;
      text-align: center;
      font-size: 1rem;
      font-weight: bold;
      letter-spacing: 2px;
      background-color: ${ProductColors.colorProductNoDeliveryBg};
      color: ${ProductColors.colorProductNoDeliveryText};
      span {
        margin: 2px 0;
      }
    }
  }

  .optionsview {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 1.1em;
    color: ${ProductColors.colorProductCategoryTitle};
    width: 100%;
    max-width: 1300px;
    margin: 0 0 15px 0;

    ${mobile`
      flex-direction: column;
      justify-content: none;
      align-items: center;
    `}
    .pagesView {
      /* display: flex; */
      flex-direction: row;
      button {
        background-color: transparent;
        border: none;
        margin: 0 5px;
        font-size: 1.1em;
        color: ${ProductColors.colorProductCategoryTitle};
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .orderByView {
      display: flex;
      flex-wrap: wrap;
      /* flex-direction: row; */
      justify-content: center;
      align-items: center;
      ${mobile`
        margin: 8px;
      `}
      button {
        background-color: transparent;
        border: none;
        margin: 0 5px;
        font-size: 1.1em;
        color: ${ProductColors.colorProductCategoryTitle};
        text-decoration: underline;
        cursor: pointer;
      }
      .orderBySelect {
        --arrow-bg: #ffffff4d;
        --arrow-icon: url(https://upload.wikimedia.org/wikipedia/commons/9/9d/Caret_down_font_awesome_whitevariation.svg);
        --option-bg: #363636dc;
        --select-bg: #363636dc;
        --boxShadow: transparent;
        /* Reset */
        appearance: none;
        border: 0;
        outline: 0;
        min-width: 180px;
        margin: 0 10px;
        font: inherit;
        /* Personalize */
        /* width: 20rem; */
        padding: 8px 5px 8px 8px;
        background: var(--arrow-icon) no-repeat right 0.8em center / 1.4em,
          linear-gradient(to left, var(--arrow-bg) 3em, var(--select-bg) 3em);
        color: white;
        border-radius: 0.25em;
        box-shadow: 0 0 1em 0 var(--boxShadow);
        cursor: pointer;
        /* Remove IE arrow */
        &::-ms-expand {
          display: none;
        }
        /* Remove focus outline */
        &:focus {
          outline: none;
        }
        /* <option> colors */
        option {
          color: inherit;
          background-color: var(--option-bg);
        }
      }
    }
  }
`

// html structure

// <ProductsListStyled id='top'>
//   <div className='catTitle'>
//     <h2>Produse</h2>
//   </div>
//   <div className='listContainer'>
//     {products.map((el, index) => (
//       <div className='productContainer' key={index}>

//         <div
//           className='productDataContainer'
//           onClick={(e) => {
//             e.preventDefault()
//             navigate(`/produs/${el.product_link}`)
//           }}
//         >
//           <div className='productImage'>
//             {el.cover_image !== null ? (
//               <img
//                 src={el.cover_image.image_link}
//                 alt={el.name}
//                 className='coverImg'
//               />
//             ) : (
//               <img
//                 src={
//                   'https://www.aroma-pasiunii.intorsura.ro/media/aroma-pasiunii-no-photo.png'
//                 }
//                 alt={el.name}
//                 className='coverImg'
//               />
//             )}
//           </div>
//           <div className='productData'>
//             <div className='productName'>
//               <h2>
//                 {toUpperCase(el.name)} {el.weight}
//                 {el.weight_classes.name_short}
//               </h2>
//             </div>
//             <div className='productDetails'>
//               {capitalizeFirstLetter(el.ingredients)}
//             </div>
//             <div className='price'>
//               {el.price}
//               <span className='currency'>{el.currency.currency_short}</span>
//             </div>

//           </div>
//         </div>
//         {getCurrentTime() > el.product_category.delivery_from_time &&
//         getCurrentTime() < el.product_category.delivery_to_time ? (
//           <AddToCartLocalStorage
//             index={index}
//             id={el.id}
//             eanCode={el.ean_code}
//             productName={el.name}
//             addToCartStyle={addToCartStyle}
//             inCartProducts={inCartProducts}
//           />
//         ) : (
//           <div className='noDeliveryTime'>
//             <span>Puteti comanda</span>
//             <span>
//               intre orele: {el.product_category.delivery_from_time} -{' '}
//               {el.product_category.delivery_to_time}
//             </span>
//           </div>
//         )}
//       </div>
//     ))}
//   </div>
// </ProductsListStyled>
