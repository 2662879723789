import styled from 'styled-components'
import {
  desktop,
  tablet,
  mobile,
} from '../../../../styles/setings/mediaScreens'
import { COLORS } from '../../../../styles/setings/colors'

export const LogoUploaderFormStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-direction: row; */
  /* background-color: grey; */
  ${desktop`
        // flex-direction: column;
        `}
  ${tablet`
        // flex-direction: column;
        `}
        ${mobile`
        flex-direction: column;
        `} /*
        div {
    width: 50%;
    margin-right: 5px;
    background-color: grey;
  } */

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .previewContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #5c1914;
    width: 220px;
    height: 220px;
    margin: 10px;
  }
  .imagePreview {
    width: 100%;
    max-height: 90px;
    object-fit: contain;
    margin: 10px 0;
  }
  /* img {
    width: 150px;
    height: auto;
    margin-top: 10px;
    background-color: #4caf50;
  } */
  input {
    padding: 10px 15px;
    border: transparent;
    margin: 10px;
    background-color: #fff;
    font-size: 1.2em;
    font-weight: bold;
  }

  /* button {
    padding: 10px 15px;
    border-radius: 5px;
    border: transparent;
    margin: 10px;
    background-color: #fff;
    font-size: 1.2em;
    font-weight: bold;
    width: 220px;
    cursor: pointer;
  } */
  .previewText {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
    font-weight: bold;
    background-color: #b93329;
    color: #fff;
    width: 100%;
    padding: 10px 20px;
    margin-bottom: 10px;
  }
  .selectButton {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border: transparent;
    margin: 10px;
    border-radius: 5px;
    font-size: 1.2em;
    font-weight: bold;
    width: 200px;
    padding: 10px 20px;
    background-color: #235425;
    cursor: pointer;
    transition: background-color 0.4s;
    &:hover {
      background-color: #4caf50;
    }
  }

  .uploadButton {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border: transparent;
    margin: 10px;
    border-radius: 5px;
    font-size: 1.2em;
    font-weight: bold;
    width: 220px;
    height: 220px;
    padding: 10px 20px;
    background-color: #235425;
    cursor: pointer;
    transition: background-color 0.4s;
    ${mobile`
      height: auto;
    `}
    &:hover {
      background-color: #4caf50;
    }
  }

  .deleteButton {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border: transparent;
    margin: 10px;
    border-radius: 5px;
    font-size: 1.2em;
    font-weight: bold;
    width: 200px;
    padding: 10px 20px;
    background-color: #a12820;
    border: solid #f44336 2px;
    cursor: pointer;
    transition: background-color 0.4s;
    &:hover {
      background-color: #f44336;
    }
  }
  .replaceButton {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border: transparent;
    margin: 10px;
    border-radius: 5px;
    font-size: 1.2em;
    font-weight: bold;
    width: 220px;
    height: 220px;
    padding: 10px 20px;
    background-color: #0c3f68;
    cursor: pointer;
    transition: background-color 0.4s;
    ${mobile`
      height: auto;
    `}
    &:hover {
      background-color: #2196f3;
    }
  }
  .hiddenInput {
    display: none;
  }

  .error {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
    font-weight: bold;
    background-color: #b93329;
    color: #fff;
    width: 100%;
    padding: 10px 20px;
    margin-bottom: 10px;
  }
`

// {
//   container: {
//     textAlign: 'center',
//     padding: '20px',
//     border: '1px solid #ddd',
//     borderRadius: '10px',
//     width: '300px',
//     margin: '0 auto',
//   },

//   buttonGroup: {
//     display: 'flex',
//     justifyContent: 'center',
//     gap: '10px',
//   },
//
// }
