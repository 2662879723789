import styled from 'styled-components'
import {
  desktop,
  tablet,
  mobile,
} from '../../../../styles/setings/mediaScreens'
import { COLORS } from '../../../../styles/setings/colors'

export const ChangeOrderStatusFormStyled = styled.div`
  display: flex;
  flex-direction: column;
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    h4 {
      text-decoration: underline;
      text-decoration-thickness: 4px;
      text-decoration-color: ${COLORS.colorTextUnderlineDefault};
      text-align: center;
      ${desktop`  
        font-size: 1.5rem;
        font-weight: bold;
      `}
      ${tablet`
        width: 100%;
        font-size: 1.5rem;
        font-weight: bold;
      `}
    ${mobile` 
        width: 100%;
        font-size: 1.5rem;
        font-weight: bold;
    `}
    }
  }
  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 15px 0;
    padding: 15px;
    background-color: #151515;
    ${mobile`
      flex-direction: column;
      `}

    .cancelOrder {
      background-color: #691818;
      border: solid #9a0000 2px;
      color: #fff;
      text-align: center;
      padding: 20px 25px;
      margin: 0 7px;
      font-size: 1rem;
      font-weight: bold;
      text-transform: uppercase;
      &:hover {
        background-color: #972929;
        border: solid #fff 2px;
        color: #fff;
      }
    }
    .prepareOrder {
      background-color: #d2521b;
      border: solid #9a2e00 2px;
      color: #fff;
      text-align: center;
      padding: 20px 25px;
      margin: 0 7px;
      font-size: 1rem;
      font-weight: bold;
      text-transform: uppercase;
      &:hover {
        background-color: #ff4d00;
        border: solid #fff 2px;
        color: #fff;
      }
    }
    .finishOrder {
      background-color: #005816;
      border: solid #00370e 2px;
      color: #fff;
      text-align: center;
      padding: 20px 25px;
      margin: 0 7px;
      font-size: 1rem;
      font-weight: bold;
      text-transform: uppercase;
      &:hover {
        background-color: #008c23;
        border: solid #fff 2px;
        color: #fff;
      }
    }
  }
`
