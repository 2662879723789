import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { getCustomerByID, updateCustomerEmail } from '../../../../api/customers'
import { ModifyEmailStyled } from './ModifyEmailStyled'
import {
  GeneralFormStyled,
  FieldLabel,
  InfoLabel,
  SuccesLabel,
  ErrorLabel,
  CancelButton,
} from '../../../../styles/elements/formsStyle'
import SuccesAlert from '../../../../components/common/alert/SuccesAlert'
import ErrorAlert from '../../../../components/common/alert/ErrorAlert'
import { PopUpLevel2Styled } from '../../../../components/common/popUpWindows/PopUpLevel2Styled'

function validateComplexEmail(email) {
  const complexEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  return complexEmailRegex.test(email)
}

const cleanFormData = {
  email: '',
}

const validation = {
  email: (data) => data.email.length < 5 || !validateComplexEmail(data.email),
}

function ModifyEmail({ cancel, elID }) {
  const [formData, setFormData] = useState(cleanFormData)
  const [errors, setErrors] = useState([])
  const [responseErrors, setResponseErrors] = useState([])
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    getCustomerByID(elID, (response) => {
      setFormData(response)
    })
  }, [])

  const handleChange = (e) => {
    const { name, value } = e.target

    if (!validation[name]({ ...formData, [name]: value })) {
      setErrors(errors.filter((el) => el !== name))
    } else {
      if (!errors.includes(name)) setErrors([...errors, name])
    }

    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    const validationErrors = Object.keys(validation).filter((el) =>
      validation[el](formData)
    )

    setErrors(validationErrors)

    if (validationErrors.length === 0) {
      updateCustomerEmail(formData.id, formData, handleSuccess, handleError)
    }
  }

  function handleError(error) {
    console.error(error)

    // Parse the error response
    const errorResponse = error.response.data
    const parsedErrors = Object.values(errorResponse).flat()

    // Update the state with the parsed errors
    setResponseErrors(parsedErrors)
  }

  function handleSuccess(response) {
    // console.log(response);
    setAlertMessage('E-Mail-ul a fost modificat cu succes!')
    setShowAlert(true)
    setFormData(cleanFormData)
    localStorage.removeItem('userData')
    const timer = setTimeout(() => {
      // window.location.reload()
      // window.location.href = '/login'
      document.body.style.overflow = ''
      cancel(false)
    }, 2100)
    return () => clearTimeout(timer)
  }

  // Function to remove an error message from the state
  const removeError = (index) => {
    setResponseErrors(responseErrors.filter((_, i) => i !== index))
  }

  return (
    <>
      {showAlert && <SuccesAlert message={alertMessage} />}

      <div>
        {responseErrors.map((message, index) => (
          <ErrorAlert
            key={index}
            message={message}
            onRemove={() => removeError(index)}
          />
        ))}
      </div>
      <PopUpLevel2Styled>
        <div className='popUpLevel2Container'>
          <ModifyEmailStyled>
            <div className='title'>
              <h2>Modifica E-Mail-ul</h2>
            </div>
            <CancelButton>
              <button className='cancel' onClick={() => cancel(false)}>
                Anuleaza
              </button>
            </CancelButton>
            <GeneralFormStyled>
              <form onSubmit={handleSubmit}>
                <div className='oneField'>
                  <div>
                    <FieldLabel>
                      Email
                      {errors.includes('email') === true ? (
                        <ErrorLabel>Email invalid!</ErrorLabel>
                      ) : formData.email.length >= 6 ? (
                        <SuccesLabel>&#10004;</SuccesLabel>
                      ) : (
                        <InfoLabel>Minim 6 caractere</InfoLabel>
                      )}
                    </FieldLabel>
                    <input
                      type='text'
                      name='email'
                      value={formData.email}
                      onChange={handleChange}
                      placeholder='Email'
                    />
                  </div>
                </div>
                <div className='submitContainer'>
                  <button className='submitButton'>Modifica E-Mail-ul</button>
                </div>
              </form>
            </GeneralFormStyled>
          </ModifyEmailStyled>
        </div>
      </PopUpLevel2Styled>
    </>
  )
}

export default ModifyEmail
