import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import {
  getShopCategoryById,
  addOrUpdateShopCategory,
} from '../../../../api/unitDataAPI'
import { CategoryAddFormStyled } from './CategoryAddFormStyled'
import {
  GeneralFormStyled,
  FieldLabel,
  InfoLabel,
  SuccesLabel,
  ErrorLabel,
} from '../../../../styles/elements/formsStyle'
import {
  SubmitButton,
  RedSubmitButton,
  GreenSubmitButton,
} from '../../../../styles/elements/buttons'

import SuccesAlert from '../../../common/alert/SuccesAlert'
import ErrorAlert from '../../../common/alert/ErrorAlert'

import BackToSetings from '../../../common/buttons/backToSetings/BackToSetings'
import SingleImageUploaderForm from '../singleImageUploaderForm/SingleImageUploaderForm'
import FadeLoading from '../../../common/loaders/fadeLoading/FadeLoading'
import IconUploaderForm from '../iconUploaderForm/IconUploaderForm'
import TimeSelectFieldForm from '../timeSelectFieldForm/TimeSelectFieldForm'
import { PopUpLevel1Styled } from '../../../common/popUpWindows/PopUpLevel1Styled'

const cleanFormData = {
  name: '',
  sort: '',
  delivery_from_time: '',
  delivery_to_time: '',
}

const validation = {
  name: (data) => data.name.length < 3,
  sort: (data) => data.sort.length < 1 || isNaN(data.sort),

  delivery_from_time: (data) =>
    data.delivery_from_time === '' || data.delivery_from_time === 'default',

  delivery_to_time: (data) =>
    data.delivery_to_time === '' || data.delivery_to_time === 'default',
}

function CategoryAddForm({ elID, cancel }) {
  const [formData, setFormData] = useState(cleanFormData)
  const [elementId, setElementId] = useState(elID)
  const [category, setCategory] = useState({})
  const [showChangeImage, setShowChangeImage] = useState(null)
  const [errors, setErrors] = useState([])
  const [responseErrors, setResponseErrors] = useState([])
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const ActivateButtonComponent =
    category.active === 1 ? RedSubmitButton : GreenSubmitButton

  // Fetch the special offer by ID whenever `elID` changes
  useEffect(() => {
    if (elementId) {
      getShopCategoryById(elementId, (response) => {
        setCategory(response)
      })
    }
  }, [elementId, showChangeImage]) // Trigger this effect when `elID` changes

  useEffect(() => {
    if (category && Object.keys(category).length > 0) {
      setFormData({
        name: category.name || '',
        sort: category.sort || '',
        delivery_from_time: category.delivery_from_time || '',
        delivery_to_time: category.delivery_to_time || '',
      })
    }
    document.body.style.overflow = 'hidden'
  }, [category])

  const handleChange = (e) => {
    const { name, value } = e.target

    // Handle other fields
    if (!validation[name]({ ...formData, [name]: value })) {
      setErrors(errors.filter((el) => el !== name))
    } else {
      if (!errors.includes(name)) setErrors([...errors, name])
    }

    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    const validationErrors = Object.keys(validation).filter((el) =>
      validation[el](formData)
    )

    setErrors(validationErrors)

    if (validationErrors.length === 0) {
      // Set loading state to true
      setIsLoading(true)
      addOrUpdateShopCategory(category.id, formData, handleSuccess, handleError)
    }
  }

  function handleError(error) {
    console.error(error)

    // Parse the error response
    const errorResponse = error.response.data
    const parsedErrors = Object.values(errorResponse).flat()

    // Update the state with the parsed errors
    setResponseErrors(parsedErrors)

    // Reset loading state
    setIsLoading(false)
  }

  // console.log('arata imagine ' + showChangeImage)
  // console.log('elId ' + elementId)

  function handleSuccess(response) {
    // console.log(response.id)
    // alert('Contul a fost creat cu succes!')
    setAlertMessage('Datele au fost salvate cu succes!')
    setShowAlert(true)
    // setFormData(cleanFormData)
    // navigate('/login')

    // Reset loading state
    setIsLoading(false)
    resetShowAlert()

    const timer = setTimeout(() => {
      // window.location.reload()
      // window.location.href = '/setari'
      // setShowChangeImage(showChangeImage === category.id ? null : category.id)
      setShowChangeImage(response.id)
      setElementId(response.id)
    }, 2100)
    return () => clearTimeout(timer)
  }

  const resetShowAlert = () => {
    const timer = setTimeout(() => {
      setShowAlert(false)
    }, 2100)
    return () => clearTimeout(timer)
  }

  // Function to remove an error message from the state
  const removeError = (index) => {
    setResponseErrors(responseErrors.filter((_, i) => i !== index))
  }

  return (
    <>
      {showAlert && <SuccesAlert message={alertMessage} />}

      <div>
        {responseErrors.map((message, index) => (
          <ErrorAlert
            key={index}
            message={message}
            onRemove={() => removeError(index)}
          />
        ))}
      </div>
      {/* <BackToSetings /> */}
      {category ? (
        <>
          <PopUpLevel1Styled>
            <div className='popUpLevel1Container'>
              <CategoryAddFormStyled>
                <div className='specOffFormContainer'>
                  <div className='title'>
                    <h2>
                      {!elementId ? 'Adauga o categorie' : 'Editeaza categoria'}
                    </h2>
                  </div>
                  <div className='cancelButtonContainer'>
                    <button
                      className='closeButton'
                      onClick={() => {
                        // setIsOpen(false)
                        // window.location.reload()
                        setTimeout(cancel, 300) // Delay cancel to allow fade-out
                        document.body.style.overflow = '' // Re-enable body scroll
                      }}
                    >
                      Renunta
                    </button>
                  </div>
                  {/* {category.id && ( */}
                  {elementId && (
                    <div className='changeImageButtonFormContainer'>
                      <RedSubmitButton
                        onClick={() => {
                          setShowChangeImage(
                            showChangeImage === category.id ? null : category.id
                          )
                        }}
                      >
                        {category.image ? 'Schimba imaginea' : 'Adauga imagine'}
                      </RedSubmitButton>
                      {showChangeImage === category.id && (
                        // <IconUploaderForm
                        //   apiName={'updateFacilityIcon'}
                        //   id={category.id}
                        //   formTitle={
                        //     category.icon ? 'Schimba iconita' : 'Adauga iconita'
                        //   }
                        //   oldImage={category.icon}
                        //   uploadFolder={'icons'}
                        //   fileType={'png'}
                        //   cancel={() => setShowChangeImage(null)}
                        //   backUrl={'categorii-produse'}
                        // />
                        <SingleImageUploaderForm
                          apiName={'updateShopCategoryImage'}
                          id={category.id}
                          formTitle={
                            category.image
                              ? 'Schimba imaginea'
                              : 'Adauga imagine'
                          }
                          oldImage={category.image}
                          uploadFolder={'categories'}
                          fileType={''}
                          cancel={() => setShowChangeImage(null)}
                          backUrl={'categorii-produse'}
                        />
                      )}
                      {category.icon ? (
                        ''
                      ) : (
                        <span>Nu este obilgatoriu sa incarcati o iconita.</span>
                      )}

                      <div className='icon'>
                        <img src={category.icon} alt='' />
                      </div>
                    </div>
                  )}
                  <GeneralFormStyled>
                    <form onSubmit={handleSubmit}>
                      <div className='oneField'>
                        <div>
                          <FieldLabel>
                            Nume categorie
                            {errors.includes('name') === true ? (
                              <ErrorLabel>Numele e prea scurt!</ErrorLabel>
                            ) : formData.name.length >= 3 ? (
                              <SuccesLabel>&#10004;</SuccesLabel>
                            ) : (
                              <InfoLabel>Minim 3 caractere</InfoLabel>
                            )}
                          </FieldLabel>
                          <input
                            type='text'
                            name='name'
                            value={formData.name}
                            onChange={handleChange}
                            placeholder='Nume categorie'
                          />
                        </div>
                      </div>
                      <div className='oneField'>
                        <FieldLabel>Perioada livrare</FieldLabel>
                      </div>
                      <div className='multipleFields'>
                        <div>
                          <FieldLabel>
                            De la
                            {errors.includes('delivery_from_time') ? (
                              <ErrorLabel>Selectează ora!</ErrorLabel>
                            ) : formData.delivery_from_time !== '' &&
                              formData.delivery_from_time !== 'default' ? (
                              <SuccesLabel>&#10004;</SuccesLabel>
                            ) : (
                              <InfoLabel>Selecteaza ora</InfoLabel>
                            )}
                          </FieldLabel>
                          <TimeSelectFieldForm
                            selectName={'delivery_from_time'}
                            selectedValue={formData.delivery_from_time}
                            defaultText={'Selecteaza ora'}
                            onChange={handleChange}
                          />
                        </div>
                        <div>
                          <FieldLabel>
                            Pana la
                            {errors.includes('delivery_to_time') ? (
                              <ErrorLabel>Selectează ora!</ErrorLabel>
                            ) : formData.delivery_to_time !== '' &&
                              formData.delivery_to_time !== 'default' ? (
                              <SuccesLabel>&#10004;</SuccesLabel>
                            ) : (
                              <InfoLabel>Selecteaza ora</InfoLabel>
                            )}
                          </FieldLabel>
                          <TimeSelectFieldForm
                            selectName={'delivery_to_time'}
                            selectedValue={formData.delivery_to_time}
                            defaultText={'Selecteaza ora'}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className='oneField'>
                        <div>
                          <FieldLabel>
                            Ordonare
                            {!isNaN(formData.sort) &&
                            errors.includes('sort') === true ? (
                              <ErrorLabel>Numar sortare prea scurt!</ErrorLabel>
                            ) : errors.includes('sort') ? (
                              <ErrorLabel>Numar sortare incorect</ErrorLabel>
                            ) : formData.sort.length >= 1 &&
                              !isNaN(formData.sort) ? (
                              <SuccesLabel>&#10004;</SuccesLabel>
                            ) : (
                              <InfoLabel>Minim 1 cifra</InfoLabel>
                            )}
                          </FieldLabel>
                          <input
                            type='text'
                            name='sort'
                            value={formData.sort}
                            onChange={handleChange}
                            placeholder='Ordonare'
                            maxLength={6}
                          />
                        </div>
                      </div>
                      <div className='submitContainer'>
                        <SubmitButton disabled={isLoading}>
                          {isLoading
                            ? 'Asteapta! Se incarca...'
                            : !category.title
                            ? 'Salveaza'
                            : 'Modifica datele'}
                        </SubmitButton>
                      </div>
                    </form>
                  </GeneralFormStyled>
                </div>
              </CategoryAddFormStyled>
            </div>
          </PopUpLevel1Styled>
        </>
      ) : (
        <p>Incarc datele</p>
        // <FadeLoading />
      )}
    </>
  )
}

export default CategoryAddForm
