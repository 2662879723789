import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { submitOrder } from '../../../api/shopAPI'
import { CheckOutFormStyled } from './CheckOutFormStyled'
import {
  GeneralFormStyled,
  FieldLabel,
  InfoLabel,
  SuccesLabel,
  ErrorLabel,
} from '../../../styles/elements/formsStyle'
import SuccesAlert from '../../../components/common/alert/SuccesAlert'
import ErrorAlert from '../../../components/common/alert/ErrorAlert'

function validateComplexEmail(email) {
  const complexEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  return complexEmailRegex.test(email)
}

const cleanFormData = {
  first_name: '',
  last_name: '',
  phone: '',
  email: '',
  city: '',
  street: '',
  street_number: '',
  building: '',
  staircase: '',
  apartment: '',
  intercom: '',
}

const validation = {
  first_name: (first_name) => first_name.length < 3,
  last_name: (last_name) => last_name.length < 3,
  // phone: (phone) => phone.length < 10,
  phone: (phone) => phone.length < 10 || isNaN(phone),
  email: (email) => email.length < 6 || !validateComplexEmail(email),
  city: (city) => city.length < 3,
  street: (street) => street.length < 3,
  street_number: (street_number) => street_number.length < 0,
  building: (building) => building.length < 0,
  staircase: (staircase) => staircase.length < 0,
  apartment: (apartment) => apartment.length < 0,
  intercom: (intercom) => intercom.length < 0,
}

function CheckOutForm({ cart, isLoggedIn }) {
  const [userData, setUserData] = useState({})
  const [formData, setFormData] = useState(cleanFormData)
  const [errors, setErrors] = useState([])
  const [responseErrors, setResponseErrors] = useState([])
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    const storedUserData = JSON.parse(localStorage.getItem('userData'))
    if (storedUserData) {
      setUserData(storedUserData)
      if (isLoggedIn) {
        setFormData({
          first_name: storedUserData.first_name || '',
          last_name: storedUserData.last_name || '',
          phone: storedUserData.phone || '',
          email: storedUserData.email || '',
          city: storedUserData.city || '',
          street: storedUserData.street || '',
          street_number: storedUserData.street_number || '',
          building: storedUserData.building || '',
          staircase: storedUserData.staircase || '',
          apartment: storedUserData.apartment || '',
          intercom: storedUserData.intercom || '',
        })
      }
    }
  }, [isLoggedIn])

  const total = {
    total_price: cart.reduce(
      (sum, product) => sum + product.price * product.quantity,
      0
    ),
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    if (!validation[name]({ ...formData, [name]: value })) {
      setErrors(errors.filter((el) => el !== name))
    } else {
      if (!errors.includes(name)) setErrors([...errors, name])
    }

    setFormData({
      ...formData,
      [name]: value,
    })
  }
  // const handleChange = (e) => {
  //   if (!validation[e.target.name](e.target.value)) {
  //     setErrors(errors.filter((el) => el !== e.target.name))
  //   }

  //   setFormData({
  //     ...formData,
  //     [e.target.name]: e.target.value,
  //   })
  // }

  function handleSubmit(event) {
    event.preventDefault()

    const validationErrors = Object.keys(validation).filter((el) =>
      validation[el](formData[el])
    )

    setErrors(validationErrors)

    if (validationErrors.length === 0) {
      const orderData = {
        ...formData,
        cart,
        total_price: total.total_price,
        currency: cart[0]?.currency?.currency_short || 'moneda',
      }

      submitOrder(
        orderData,
        handleSuccess,
        // (data) => {
        //   localStorage.removeItem('cart')
        //   alert('Comanda a fost trimisa')
        //   navigate('/')
        // },
        handleError
      )
    }
  }

  // function handleError(error) {
  //   console.error(error)
  // }

  function handleError(error) {
    console.error(error)

    // Parse the error response
    const errorResponse = error.response.data
    const parsedErrors = Object.values(errorResponse).flat()

    // Update the state with the parsed errors
    setResponseErrors(parsedErrors)
  }

  function handleSuccess(response) {
    console.log(response)
    //   localStorage.removeItem('cart')
    //   alert('Comanda a fost trimisa')
    //   navigate('/')
    setAlertMessage('Comanda a fost transmisa!')
    localStorage.removeItem('cart')
    setShowAlert(true)
    setFormData(cleanFormData)
    // navigate('/login')
    const timer = setTimeout(() => {
      // window.location.reload()
      window.location.href = '/'
    }, 2100)
    return () => clearTimeout(timer)
  }

  // Function to remove an error message from the state
  const removeError = (index) => {
    setResponseErrors(responseErrors.filter((_, i) => i !== index))
  }

  return (
    <>
      {showAlert && <SuccesAlert message={alertMessage} />}

      <div>
        {responseErrors.map((message, index) => (
          <ErrorAlert
            key={index}
            message={message}
            onRemove={() => removeError(index)}
          />
        ))}
      </div>
      {total.total_price !== 0 && (
        <CheckOutFormStyled>
          {!isLoggedIn && (
            <div className='registerLink'>
              <Link to={'/register'}>Creeaza cont</Link>
              <span>sau trimite comanda fara cont de client</span>
            </div>
          )}
          <div className='title'>
            <h2>Date livrare</h2>
          </div>
          <GeneralFormStyled>
            <form onSubmit={handleSubmit}>
              <div className='multipleFields'>
                <div>
                  <FieldLabel>
                    Nume
                    {errors.includes('first_name') === true ? (
                      <ErrorLabel>Nume prea scurt!</ErrorLabel>
                    ) : formData.first_name.length >= 3 ? (
                      <SuccesLabel>&#10004;</SuccesLabel>
                    ) : (
                      <InfoLabel>Minim 3 caractere</InfoLabel>
                    )}
                  </FieldLabel>
                  <input
                    type='text'
                    name='first_name'
                    value={formData.first_name}
                    onChange={handleChange}
                    placeholder='Nume'
                  />
                </div>
                <div>
                  <FieldLabel>
                    Prefirst_name
                    {errors.includes('last_name') === true ? (
                      <ErrorLabel>Prefirst_name prea scurt!</ErrorLabel>
                    ) : formData.last_name.length >= 3 ? (
                      <SuccesLabel>&#10004;</SuccesLabel>
                    ) : (
                      <InfoLabel>Minim 3 caractere</InfoLabel>
                    )}
                  </FieldLabel>
                  <input
                    type='text'
                    name='last_name'
                    value={formData.last_name}
                    onChange={handleChange}
                    placeholder='Prefirst_name'
                  />
                </div>
              </div>
              <div className='multipleFields'>
                <div>
                  <FieldLabel>
                    Telefon
                    {!isNaN(formData.phone) &&
                    errors.includes('phone') === true ? (
                      <ErrorLabel>Telefon prea scurt!</ErrorLabel>
                    ) : errors.includes('phone') ? (
                      <ErrorLabel>Numar incorect</ErrorLabel>
                    ) : formData.phone.length >= 10 &&
                      !isNaN(formData.phone) ? (
                      <SuccesLabel>&#10004;</SuccesLabel>
                    ) : (
                      <InfoLabel>Minim 10 cifre</InfoLabel>
                    )}
                  </FieldLabel>
                  <input
                    type='text'
                    name='phone'
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder='Telefon'
                  />
                </div>
                <div>
                  <FieldLabel>
                    Email
                    {errors.includes('email') === true ? (
                      <ErrorLabel>Email invalid!</ErrorLabel>
                    ) : formData.email.length >= 6 ? (
                      <SuccesLabel>&#10004;</SuccesLabel>
                    ) : (
                      <InfoLabel>Minim 6 caractere</InfoLabel>
                    )}
                  </FieldLabel>
                  <input
                    type='text'
                    name='email'
                    value={formData.email}
                    onChange={handleChange}
                    placeholder='Email'
                  />
                </div>
              </div>
              <div className='oneField'>
                <FieldLabel>
                  Localitate
                  {errors.includes('city') === true ? (
                    <ErrorLabel>Localitatea e prea scurta!</ErrorLabel>
                  ) : formData.city.length >= 3 ? (
                    <SuccesLabel>&#10004;</SuccesLabel>
                  ) : (
                    <InfoLabel>Minim 3 caractere</InfoLabel>
                  )}
                </FieldLabel>
                <input
                  type='text'
                  name='city'
                  value={formData.city}
                  onChange={handleChange}
                  placeholder='Localitate'
                />
              </div>
              <div className='multipleFields'>
                <div>
                  <FieldLabel>
                    Strada
                    {errors.includes('street') === true ? (
                      <ErrorLabel>Strada e prea scurta!</ErrorLabel>
                    ) : formData.street.length >= 3 ? (
                      <SuccesLabel>&#10004;</SuccesLabel>
                    ) : (
                      <InfoLabel>Minim 3 caractere</InfoLabel>
                    )}
                  </FieldLabel>
                  <input
                    type='text'
                    name='street'
                    value={formData.street}
                    onChange={handleChange}
                    placeholder='Strada'
                  />
                </div>
                <div>
                  <FieldLabel>Nr Strada</FieldLabel>
                  <input
                    type='text'
                    name='street_number'
                    value={formData.street_number}
                    onChange={handleChange}
                    placeholder='Nr Strada'
                  />
                </div>
              </div>
              <div className='multipleFields'>
                <div>
                  <FieldLabel>Bloc</FieldLabel>
                  <input
                    type='text'
                    name='building'
                    value={formData.building}
                    onChange={handleChange}
                    placeholder='Bloc'
                  />
                </div>
                <div>
                  <FieldLabel>Scara</FieldLabel>
                  <input
                    type='text'
                    name='staircase'
                    value={formData.staircase}
                    onChange={handleChange}
                    placeholder='Scara'
                  />
                </div>
                <div>
                  <FieldLabel>Ap</FieldLabel>
                  <input
                    type='text'
                    name='apartment'
                    value={formData.apartment}
                    onChange={handleChange}
                    placeholder='Ap'
                  />
                </div>
              </div>
              <div className='oneField'>
                <FieldLabel>Nr. intercom</FieldLabel>
                <input
                  type='text'
                  name='intercom'
                  value={formData.intercom}
                  onChange={handleChange}
                  placeholder='Nr. intercom'
                />
              </div>
              <div className='submitContainer'>
                <button className='submitButton'>Trimite comanda</button>
              </div>
            </form>
          </GeneralFormStyled>
        </CheckOutFormStyled>
      )}
    </>
  )
}

export default CheckOutForm
