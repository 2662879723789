import styled from 'styled-components'
import { desktop, tablet, mobile } from '../../../styles/setings/mediaScreens'
import { ProductColors } from '../../../styles/setings/colors'

export const ProductStyled = styled.div`
  /* border: solid ${ProductColors.colorProductBorder} 1px; */
  height: auto;
  display: flex;
  justify-content: center;
  /* margin: auto; */
  margin: 10px 0;

  .shortNavContainer {
    margin: 0 0 10px 0;
    /* position: sticky; */
    /* top: 170px; */
    background-color: ${ProductColors.colorProductShortNavBg};
    padding: 5px;
    /* z-index: 2; */
    /* ${tablet`
      top: 180px;
    `}
    ${mobile`
      top: 140px;
    `} */
    .shortNav {
      font-size: 1.1em;
      color: ${ProductColors.colorProductShortNavText};
      text-transform: uppercase;
      cursor: pointer;
      &:hover {
        color: ${ProductColors.colorProductShortNavTextHover};
      }
    }
  }

  .productContainer {
    width: 100%;
    max-width: 1300px;
    /* display: flex;
    flex-direction: column;
    justify-content: center; */
    /* border: solid ${ProductColors.colorProductBorder} 2px; */
    background-color: ${ProductColors.colorProductBg};
    padding: 0px 0;

    .productName {
      margin: 8px 8px 8px 0;
      padding: 5px;
      background-color: ${ProductColors.colorProductTitleBg};
      border-bottom: solid ${ProductColors.colorProductBorder} 1px;

      ${mobile`
        border-bottom: none;
        width: 100%;
      `}

      h1 {
        font-size: 2rem;
        font-weight: bold;
        color: ${ProductColors.colorProductTitle};

        ${mobile`
            text-align: center;
        `}
      }
    }

    .product {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin: 20px 0;

      ${mobile`
        flex-direction: column;
      `}

      .foto {
        width: 50%;
        max-width: 650px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        padding: 5px 10px;

        ${mobile`
          width: 100%;
          max-width: none;
        `}

        /* .bigImg {
          width: 100%;
          max-width: 600px;
          min-height: 300px;
          max-height: 300px;
          border-radius: 25px;
          border: solid ${ProductColors.colorProductImageBorder} 3px;
          padding: 5px;

          ${mobile`
            // width: 100%;
            height: 100%;
            // min-height: 200px;
            // max-height: 220px;
          `}
        } */
        .bigImg {
          width: 100%; /* Ensures the image takes up the full width of its container */
          height: auto; /* Maintains the aspect ratio */
          object-fit: cover; /* Ensures the image fills the container without distortion */
          border-radius: 25px;
          border: solid ${ProductColors.colorProductImageBorder} 3px;
          padding: 5px;

          ${mobile`
            width: 100%; /* Responsive adjustment for mobile */
            height: 100%; /* Adjusts height dynamically */
            max-height: 200px; /* Ensures the image doesn't exceed a maximum height */
            min-height: 200px; /* Ensures the image doesn't go below a minimum height */
            object-fit: contain; /* For smaller screens, contain the image instead */
          `}
        }

        .thumbnailImg {
          width: 100px;
          cursor: pointer;
          padding: 5px;
          transition: transform 0.3s ease-in-out;
        }

        .thumbnailImg:hover {
          transform: scale(1.1);
        }

        .bigImgFullScreen {
          position: relative;
          top: 0;
          left: 0;
          width: 100%;
          min-height: 300px;
          max-height: 300px;
          /* padding: 5px 10px; */
          border-radius: 25px;
          ${tablet`
            // max-height: 300px;
          `}
          ${mobile`
            // min-height: 200px;

          `}

          ${mobile`
            min-height: 200px;
            // max-height: auto;
            width: 100%;
          `}
        }
      }

      .productData {
        width: 50%;
        padding: 5px 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        ${mobile`
        // margin-top: 25px;
      width: 100%;
      `}

        /* .price {
          display: flex;
          justify-content: flex-start;
          align-items: flex-end;
          width: 100%;
          color: ${ProductColors.colorProductPrice};
          margin-top: 10px;
          padding: 10px 0;
          font-size: 2rem;
          font-weight: bold;
          .currency {
            margin-left: 10px;
            color: ${ProductColors.colorProductCurrency};
            font-size: 1.4rem;
          }
        } */

        .eanCode {
          display: flex;
          justify-content: flex-start;
          align-items: flex-end;
          width: 100%;
          color: ${ProductColors.colorProductPrice};
          margin-top: -8px;
          padding: 3px 0;
          font-size: 0.9rem;
          font-weight: bold;
          /* border-radius: 0 0 15px 15px; */
          .currency {
            margin-left: 10px;
            color: ${ProductColors.colorProductPrice};
            font-size: 0.9rem;
          }
        }

        .stock {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          padding: 0;
          color: white;
          font-size: 1em;
          ${mobile`
            justify-content: flex-start;
            padding: 10px 0;
          `}
        }

        .productDetails {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-top: 10px;
          padding: 0 10px;

          font-size: 1rem;
          line-height: 1.3em;
          letter-spacing: 1px;
          word-spacing: 3px;
          white-space: pre-wrap;
          ${mobile`
            flex-direction: column;
          `}

          .ingredients {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
            color: white;
            /* background-color: grey; */
            padding: 10px 0 10px 20px;

            ${mobile`
              flex-direction: column;
              // justify-content: center;
              // align-items: center;
              // text-align: center;
            `}
            .title {
              margin-right: 10px;
              font-weight: bold;
              width: 100%;
              margin-left: -20px;
            }
          }

          .allergens {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
            color: white;
            /* background-color: grey; */
            padding: 10px 0 10px 20px;

            ${mobile`
              flex-direction: column;
              // justify-content: center;
              // align-items: center;
              // text-align: center;
            `}
            .title {
              margin-right: 10px;
              font-weight: bold;
              width: 100%;
              margin-left: -20px;
            }
          }

          .nutritionalDeclaration {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
            color: white;
            /* background-color: grey; */
            padding: 10px 0 10px 20px;

            ${mobile`
              flex-direction: column;
              // justify-content: center;
              // align-items: center;
              // text-align: center;
            `}
            .title {
              margin-right: 10px;
              font-weight: bold;
              width: 100%;
              margin-left: -20px;
            }
          }

          .energeticValue {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
            color: white;
            /* background-color: grey; */
            padding: 10px 0 10px 20px;

            ${mobile`
              flex-direction: column;
              // justify-content: center;
              // align-items: center;
              // text-align: center;
            `}
            .title {
              margin-right: 10px;
              font-weight: bold;
              width: 100%;
              margin-left: -20px;
            }
          }

          .frozenProduct {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            background-color: #f00;
            padding: 10px;
            font-size: 1.2em;
            font-weight: bold;
            line-height: 1.3em;
            letter-spacing: 1px;
            word-spacing: 3px;
            white-space: pre-wrap;
            color: #fff;
            ${mobile`
          justify-content: center;
          text-align: center;
          `}
          }
        }

        .selectedOptions {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
          margin-top: 10px;
          padding: 0 10px;
          font-size: 1rem;
          line-height: 1.3em;
          letter-spacing: 1px;
          word-spacing: 3px;
          white-space: pre-wrap;
          /* background-color: #ccc; */
          color: #fff;
          ${mobile`
            flex-direction: column;
          `}
          h4 {
            font-weight: bold;
          }
        }
      }
    }
  }

  .price {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
    color: ${ProductColors.colorProductPrice};
    margin-top: 10px;
    padding: 10px 0;
    font-size: 2rem;
    font-weight: bold;
    /* border-radius: 0 0 15px 15px; */
    span {
      margin-right: 8px;
      font-size: 1.3rem;
    }
    .currency {
      margin-left: 10px;
      color: ${ProductColors.colorProductCurrency};
      font-size: 1.4rem;
    }
  }

  .carouselModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    .prevButton {
      left: 20px;
      background-color: #666;
      height: 50px;
      width: 50px;
    }

    .nextButton {
      right: 20px;
      background-color: #666;
      height: 50px;
      width: 50px;
    }
  }

  .bigImgFullScreen {
    max-width: 90%;
    max-height: 80%;
    border-radius: 10px;
  }

  .closeButton,
  .prevButton,
  .nextButton {
    position: absolute;
    color: white;
    background: none;
    border: none;
    font-size: 2em;
    cursor: pointer;
    margin: 0;
  }

  .closeButton {
    top: 10px;
    right: 20px;
    background-color: #000;
    height: 50px;
    width: 50px;
  }

  .noDeliveryTime {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding: 10px 0;
    margin: 10px 0;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    background-color: #fff1e6;
    color: #f00;
    ${mobile`
    flex-direction: column;
    align-items: center;
    `}
    span {
      margin-right: 5px;
      ${mobile`
        margin-right: 0px;
      `}
    }
  }
`

// import styled from 'styled-components';
// import { desktop, tablet, mobile } from '../../styles/setings/mediaScreens';
// import { COLORS } from '../../styles/setings/colors';

// export const ProductStyled = styled.div`
//   /* border: solid #fff 1px; */
//   height: auto;
//   width: 100%;
//   /* margin: auto; */

//   .shortNav {
//     font-size: 1.1em;
//     color: #fff;
//     text-transform: uppercase;
//     cursor: pointer;
//     &:hover {
//       color: #ccc;
//     }
//   }

//   .row.productContainer {
//     width: 100%;
//     border: solid #2c2c2c 2px;
//     background-color: #2c2c2c;

//     /* .productName {
//       display: flex;
//       align-items: center;
//       height: 1.8em;
//       padding-left: 1em;
//       margin-bottom: 1em;
//       h1 {
//         font-size: 1.2em;
//         font-weight: bold;
//       }
//     } */

//     .productDetailsImg {
//       display: flex;
//       flex-direction: row;
//       align-items: flex-start;
//       margin-top: 10px;
//       ${mobile`
//       flex-direction: column;
//       `}
//       .foto {
//         width: 50%;
//         /* background-color: blue; */
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         flex-direction: column;
//         padding: 5px 10px;
//         ${mobile`
//       width: 100%;
//       `}
//         .bigImg {
//           width: 100%;
//           min-height: 300px;
//           /* padding: 5px 10px; */
//           border-radius: 25px;
//           ${tablet`
//             // max-height: 300px;
//           `}
//           ${mobile`
//             // min-height: 200px;

//           `}

//           ${mobile`
//             min-height: 200px;
//             // max-height: auto;
//             width: 100%;
//           `}
//         }
//         .tumbnails {
//           display: flex;
//           align-items: center;
//           justify-content: center;
//           padding: 10px 0;
//           width: 100%;
//           /* background-color: pink; */
//           .tumbnailImg {
//             width: 70px;
//             padding: 5px;
//           }
//         }
//       }

//       .productData {
//         width: 50%;
//         padding: 5px 10px;
//         display: flex;
//         flex-direction: column;
//         justify-content: flex-start;
//         align-items: center;
//         ${mobile`
//         margin-top: 25px;
//       width: 100%;
//       `}

//         .productName {
//           width: 100%;
//           display: flex;
//           /* border: solid #fff 1px; */
//           padding: 10px 0;
//           background-color: #2c2c2c;
//           /* border-radius: 15px 15px 0 0; */

//           h1 {
//             display: inline-flex;
//             text-decoration: underline;
//             text-decoration-thickness: 4px;
//             text-decoration-color: ${COLORS.colorTextUnderlineDefault};
//             ${desktop`
//             align-items: center;
//             justify-content: flex-start;
//             width: 100%;
//             font-size: 1.7em;
//             font-weight: bold;
//             padding-left: 15px
//           `}
//             ${tablet`
//             align-items: center;
//             justify-content: flex-start;
//             width: 100%;
//             font-size: 1.5em;
//             padding-left: 15px
//           `}
//           ${mobile`
//               align-items: center;
//               justify-content: center;
//               width: 100%;
//               font-size: 1.3em;
//               line-height: 130%;
//               text-align: center;
//               padding-left: 0;
//           `}
//           }
//         }

//         .prodWeight {
//           display: flex;
//           align-items: center;
//           justify-content: flex-start;
//           width: 100%;
//           margin: 15px auto;
//           color: white;
//           font-size: 1.2em;
//           font-weight: bold;
//           text-indent: 20px;
//           ${mobile`
//           justify-content: center;
//           padding: 10px 0;
//           `}
//         }

//         .price {
//           display: flex;
//           align-items: flex-end;
//           justify-content: flex-start;
//           width: 100%;
//           padding: 0;
//           color: white;
//           font-size: 2em;
//           font-weight: bold;
//           ${mobile`
//           width: 70%;
//           height: 50px;
//           justify-content: center;
//           align-items: center;
//           border-radius: 25px;
//           background-color: grey;
//           `}
//           span {
//             margin-left: 10px;
//             padding-bottom: 2px;
//             font-size: 0.8em;
//           }
//         }

//         .stock {
//           display: flex;
//           align-items: center;
//           justify-content: flex-start;
//           width: 100%;
//           padding: 0;
//           color: white;
//           font-size: 1em;
//           ${mobile`
//             justify-content: flex-start;
//             padding: 10px 0;
//           `}
//         }

//         /* .addToCart {
//           width: 100%;
//         } */
//       }
//     }

//     .productDetails {
//       display: flex;
//       flex-direction: row;
//       align-items: flex-start;
//       margin-top: 10px;
//       padding: 0 10px;
//       ${mobile`
//       flex-direction: column;
//       `}
//       .prodDescription {
//         display: flex;
//         align-items: center;
//         justify-content: flex-start;
//         width: 100%;
//         margin-top: 10px;
//         color: white;
//         font-size: 1em;
//         line-height: 1.3em;
//         letter-spacing: 1px;
//         word-spacing: 3px;
//         white-space: pre-wrap;
//         /* ${mobile`
//           justify-content: flex-start;
//           padding: 10px 0;
//           `} */
//         ${mobile`
//         flex-direction: column;
//         justify-content: flex-start;
//         align-items: flex-start;
//         text-align: center;
//           `}
//       }

//       .ingredients {
//         display: flex;
//         justify-content: flex-start;
//         align-items: flex-start;
//         width: 100%;
//         color: white;
//         /* background-color: grey; */
//         padding: 10px 0;
//         font-size: 1.2em;
//         line-height: 1.3em;
//         letter-spacing: 1px;
//         word-spacing: 3px;
//         white-space: pre-wrap;
//         ${mobile`
//         flex-direction: column;
//         justify-content: center;
//         align-items: center;
//         text-align: center;
//           `}
//         span {
//           margin-right: 10px;
//           font-weight: bold;
//         }
//       }

//       .allergens {
//         display: flex;
//         justify-content: flex-start;
//         align-items: flex-start;
//         width: 100%;
//         color: white;
//         /* background-color: grey; */
//         padding: 10px 0;
//         font-size: 1.2em;
//         line-height: 1.3em;
//         letter-spacing: 1px;
//         word-spacing: 3px;
//         white-space: pre-wrap;
//         ${mobile`
//         flex-direction: column;
//         justify-content: center;
//         align-items: center;
//         text-align: center;
//           `}
//         span {
//           margin-right: 10px;
//           font-weight: bold;
//         }
//       }

//       .nutritionalDeclaration {
//         display: flex;
//         justify-content: flex-start;
//         align-items: flex-start;
//         width: 100%;
//         color: white;
//         /* background-color: grey; */
//         padding: 10px 0;
//         font-size: 1.2em;
//         line-height: 1.3em;
//         letter-spacing: 1px;
//         word-spacing: 3px;
//         white-space: pre-wrap;
//         ${mobile`
//          flex-direction: column;
//         justify-content: center;
//         align-items: center;
//         text-align: center;
//           `}
//         span {
//           margin-right: 10px;
//           font-weight: bold;
//         }
//       }

//       .energeticValue {
//         display: flex;
//         justify-content: flex-start;
//         align-items: flex-start;
//         width: 100%;
//         color: white;
//         /* background-color: grey; */
//         padding: 10px 0;
//         font-size: 1.2em;
//         line-height: 1.3em;
//         letter-spacing: 1px;
//         word-spacing: 3px;
//         white-space: pre-wrap;
//         ${mobile`
//       flex-direction: column;
//         justify-content: center;
//         align-items: center;
//         text-align: center;
//           `}
//         span {
//           margin-right: 10px;
//           font-weight: bold;
//         }
//       }

//       .frozenProduct {
//         display: flex;
//         justify-content: flex-start;
//         align-items: center;
//         width: 100%;
//         background-color: #f00;
//         padding: 10px;
//         font-size: 1.2em;
//         font-weight: bold;
//         line-height: 1.3em;
//         letter-spacing: 1px;
//         word-spacing: 3px;
//         white-space: pre-wrap;
//         color: #fff;
//         ${mobile`
//           justify-content: center;
//           text-align: center;
//           `}
//       }
//     }
//   }
// `;
