// import React, { useEffect, useState } from 'react'

// const selectedOption = {
//   backgroundColor: 'red',
//   color: 'white', // Optional: to ensure the text is readable on a red background
// }

// function TimeSelectField({ selectName, selectedValue, defaultText, onChange }) {
//   // Generate time options
//   const generateTimeOptions = () => {
//     const options = []
//     for (let hour = 0; hour < 24; hour++) {
//       for (let minute = 0; minute < 60; minute += 15) {
//         const time = `${String(hour).padStart(2, '0')}:${String(
//           minute
//         ).padStart(2, '0')}`
//         options.push(time)
//       }
//     }
//     return options
//   }
//   const handleSelect = (e) => {
//     const value = e.target.value
//     if (onChange) {
//       onChange({ target: { name: selectName, value } }) // This should update `selectedID` in the parent component
//     }
//   }
//   const timeOptions = generateTimeOptions()

//   console.log(selectedValue)

//   return (
//     <select
//       name={selectName}
//       id={selectName}
//       value={selectedValue || 'default'} // Directly use `selectedID`
//       onChange={handleSelect}
//     >
//       <option value='default'>{defaultText}</option>
//       {timeOptions.map((time, index) => (
//         <option
//           key={index}
//           value={time}
//           style={selectedValue === time ? selectedOption : {}}
//         >
//           {time}
//         </option>
//       ))}
//     </select>
//   )
// }

// export default TimeSelectField
// ---------------------
// import React, { useEffect, useRef } from 'react'

// const selectedOptionStyle = {
//   backgroundColor: 'red',
//   color: 'white', // Ensure the text is readable
// }

// function TimeSelectField({ selectName, selectedValue, defaultText, onChange }) {
//   const selectRef = useRef(null)

//   // Generate time options
//   const generateTimeOptions = () => {
//     const options = []
//     for (let hour = 0; hour < 24; hour++) {
//       for (let minute = 0; minute < 60; minute += 15) {
//         const time = `${String(hour).padStart(2, '0')}:${String(
//           minute
//         ).padStart(2, '0')}`
//         options.push(time)
//       }
//     }
//     return options
//   }

//   const handleSelect = (e) => {
//     const value = e.target.value
//     if (onChange) {
//       onChange({ target: { name: selectName, value } }) // Update selectedValue in the parent component
//     }
//   }

//   const timeOptions = generateTimeOptions()

//   // Focus on the select element when a value is selected
//   useEffect(() => {
//     if (selectedValue && selectRef.current) {
//       selectRef.current.focus()
//     }
//   }, [selectedValue])

//   return (
//     <select
//       name={selectName}
//       id={selectName}
//       value={selectedValue || 'default'}
//       onChange={handleSelect}
//       ref={selectRef}
//     >
//       <option value='default'>{defaultText}</option>
//       {timeOptions.map((time, index) => (
//         <option
//           key={index}
//           value={time}
//           style={selectedValue === time ? selectedOptionStyle : {}}
//         >
//           {time}
//         </option>
//       ))}
//     </select>
//   )
// }

// export default TimeSelectField

import React, { useEffect, useRef, useState } from 'react'

const selectedOptionStyle = {
  backgroundColor: 'red',
  color: 'white', // Ensure the text is readable
}

function TimeSelectFieldForm({
  selectName,
  selectedValue,
  defaultText,
  onChange,
}) {
  const selectRef = useRef(null)
  const [inputValue, setInputValue] = useState('') // Tracks user keyboard input

  // Generate time options
  const generateTimeOptions = () => {
    const options = []
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const time = `${String(hour).padStart(2, '0')}:${String(
          minute
        ).padStart(2, '0')}`
        options.push(time)
      }
    }
    return options
  }

  const handleSelect = (e) => {
    const value = e.target.value
    if (onChange) {
      onChange({ target: { name: selectName, value } })
    }
  }

  const timeOptions = generateTimeOptions()

  // Focus on the select element when a value is selected
  useEffect(() => {
    if (selectedValue && selectRef.current) {
      selectRef.current.focus()
    }
  }, [selectedValue])

  // Handle keyboard input to auto-select an option
  const handleKeyDown = (e) => {
    if (e.key === 'Backspace') {
      setInputValue((prev) => prev.slice(0, -1))
      return
    }
    if (e.key === 'Tab' || e.key === 'Enter') {
      return // Skip handling for navigation keys
    }

    if (!isNaN(e.key) && inputValue.length < 2) {
      const newValue = inputValue + e.key
      setInputValue(newValue)

      if (newValue.length === 2) {
        const matchedOption = timeOptions.find((time) =>
          time.startsWith(newValue)
        )
        if (matchedOption) {
          if (onChange) {
            onChange({ target: { name: selectName, value: matchedOption } })
          }
        }
      }
    }
  }

  // Clear the input value after a short delay
  useEffect(() => {
    if (inputValue) {
      const timeout = setTimeout(() => {
        setInputValue('')
      }, 1000) // Clear input after 1 second of inactivity
      return () => clearTimeout(timeout)
    }
  }, [inputValue])

  return (
    <select
      name={selectName}
      id={selectName}
      value={selectedValue || 'default'}
      onChange={handleSelect}
      onKeyDown={handleKeyDown}
      ref={selectRef}
    >
      <option value='default'>{defaultText}</option>
      {timeOptions.map((time, index) => (
        <option
          key={index}
          value={time}
          style={selectedValue === time ? selectedOptionStyle : {}}
        >
          {time}
        </option>
      ))}
    </select>
  )
}

export default TimeSelectFieldForm
