import styled from 'styled-components'
import {
  COLORS,
  addUserColors,
  formColors,
} from '../../../styles/setings/colors'
import { desktop, tablet, mobile } from '../../../styles/setings/mediaScreens'

export const SpecialOffersListStyled = styled.section`
  // margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.colorAboutSectionBg};
  /* background-color: grey; */
  ${desktop`
    margin-top: 20px;
  `}
  ${tablet`
    margin-top: 20px;
  `}
  ${mobile`
    margin-top: 10px;
  `}


.title {
    margin-bottom: 15px;
    h2 {
      display: inline-flex;
      text-decoration: underline;
      text-decoration-thickness: 4px;
      text-decoration-color: ${COLORS.colorTextUnderlineDefault};
      /* background-color: grey; */

      ${desktop`
        align-items: center;
        justify-content: center;    
        width: 100%;
        font-size: 2rem;
        font-weight: bold;
      `}
      ${tablet`
        align-items: center;
        justify-content: center;    
        width: 100%;
        font-size: 2rem;
        font-weight: bold;
      `}
      ${mobile`
          align-items: center;
          justify-content: center;    
          width: 100%;
          font-size: 2rem;
          font-weight: bold;
      `}
    }
  }
  .sectionSpecOfferContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 2048px;
    margin: 10px;
    .specOfferContainer {
      background-color: #141414;
      padding: 10px;
      max-width: 1300px;
      ${desktop`
          margin-bottom: 15px;
        `}
      ${tablet`
          margin-bottom: 15px;
        `}
        ${mobile`
            margin-bottom: 15px;   
        `}

      .offerTitle {
        display: inline-flex;
        align-items: flex-end;
        justify-content: flex-start;
        width: 100%;
        /* margin-top: 10px; */

        ${desktop`
          margin-bottom: 10px;
        `}
        ${tablet`
          margin: 10px 0;
        `}
        ${mobile`
            flex-direction: column;
            align-items: center;
            margin: 10px 0;   
        `}
        h3 {
          font-size: 1.5rem;
          font-weight: bold;
          text-decoration: underline;
          text-decoration-thickness: 4px;
          text-decoration-color: ${COLORS.colorTextUnderlineDefault};
          ${mobile`
            font-size: 1.7rem;
          `}
        }
        .valability {
          font-size: 1rem;
          margin-left: 10px;
          font-weight: bold;
          ${mobile`
            align-self: center;
            margin: 10px 0 0 0;
          `}
        }
      }
      .offerRow {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;

        ${tablet`
          flex-direction: column-reverse;
        `}
        ${mobile`
          flex-direction: column-reverse;
        `}

        .offerDescription {
          /* background-color: grey; */
          /* width: calc(100% - 130px); */
          width: 100%;
          padding: 5px;
          letter-spacing: 2px;
          text-align: justify;
          ${tablet`
            width: 100%;
          `}
          ${mobile`
            width: 100%;
            // margin: 15px;
            text-align: justify;
          `} 
          img {
            float: left;
            min-width: 300px;
            width: 400px;
            padding: 5px;
            border-radius: 15px;
            border: solid ${COLORS.colorImgBorder} 3px;
            ${desktop`
              margin-right: 15px;
              margin-bottom: 15px;
            `}
            ${tablet`
              // border: solid ${COLORS.colorImgBorder} 3px;
              width: 100%;
              max-width: 400px;
              margin-right: 15px;
              margin-bottom: 15px;
            `}
            ${mobile`
              // border: solid ${COLORS.colorImgBorder} 3px;
              width: 100%;
              max-width: 760px;
              margin-right: 15px;
              margin-bottom: 15px;
            `}
          }
        }
        .offerActionButtons {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          margin: 10px 0;
          /* padding: 0 10px; */

          ${tablet`
            flex-direction: row;
            padding: 0;
          `}
          ${mobile`
            flex-direction: column;
            padding: 0;
            width: 100%;
            justify-content: center;
            // background-color: grey;
          `}
        }
      }
    }
  }
  .changeStatusFormContainer {
    margin-bottom: 25px;
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 0 15px 0;
    border-radius: 15px;
    /* border-bottom: solid #fff 3px; */
    /* background-color: #420000; */
    color: #fff;
    ${mobile`
         padding: 15px 0 10px 0;
      `}
  }

  .image {
    max-width: 800px;
    img {
      width: 100%;
    }
  }

  .changeImageButtonFormContainer {
    margin-bottom: 25px;
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 0 40px 0;
    border-radius: 15px;
    /* border-bottom: solid #fff 3px; */
    background-color: #420000;
    color: #fff;
    ${mobile`
         padding: 15px 0 10px 0;
      `}
  }
`
