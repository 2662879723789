import styled from 'styled-components'
import { mobile } from '../../../styles/setings/mediaScreens'
import { AddToCartColors } from '../../../styles/setings/colors'

// export const AddToCartLocalStorageStyled = styled.div`

export const AddToCartListProductsStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  bottom: 0;
  padding: 10px 0;

  ${mobile`
  // margin-top: 15px;
  justify-content: center;`}

  form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    /* background-color: grey; */
    /* border-radius: 0 0 15px 15px; */
    /* Chrome, Safari, Edge, Opera */
    /* Firefox */
    .inputData {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      padding: 10px 0;
      margin: 0 10px 0 0;
      ${mobile`
        margin: none;
      `}
      input {
        display: block;
        text-align: center; /* Center the text */
        font-size: 1.3em;
        font-weight: bold;
        -moz-appearance: textfield;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }

      .increase {
        height: 40px;
        width: 40px;
        border-radius: 0 20px 20px 0;
        background-color: ${AddToCartColors.colorAddToCartIncreaseBg};
        border: ${AddToCartColors.colorAddToCartIncreaseBorder};
        margin-left: 3px;
        padding-right: 10px;
        color: ${AddToCartColors.colorAddToCartIncreaseText};
        font-size: 1.3em;
        font-weight: bold;
        cursor: pointer;
        &:hover {
          background-color: ${AddToCartColors.colorAddToCartIncreaseBgHover};
          color: ${AddToCartColors.colorAddToCartIncreaseTextHover};
        }
      }

      .decrease {
        height: 40px;
        width: 40px;
        border-radius: 20px 0 0 20px;
        background-color: ${AddToCartColors.colorAddToCartDecreaseBg};
        border: ${AddToCartColors.colorAddToCartDecreaseBorder};
        margin-right: 3px;
        padding-left: 10px;
        color: ${AddToCartColors.colorAddToCartDecreaseText};
        font-size: 1.3em;
        font-weight: bold;
        cursor: pointer;
        &:hover {
          background-color: ${AddToCartColors.colorAddToCartDecreaseBgHover};
          color: ${AddToCartColors.colorAddToCartDecreaseTextHover};
        }
      }

      .quantityInput {
        height: 40px;
        width: 40px;
        border: ${AddToCartColors.colorAddToCartQtyBorder};
        color: ${AddToCartColors.colorAddToCartQtyText};
        background-color: ${AddToCartColors.colorAddToCartQtyBg};
      }
      /* .quantitySelect {
        height: 30px;
        width: 80px;
        border-radius: 5px;
      } */
    }

    .addToCart {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      height: 40px;
      background-color: ${AddToCartColors.colorAddToCartAddBtnBg};
      border: ${AddToCartColors.colorAddToCartAddBtnBorder};
      /* margin-left: 20px; */
      border-radius: 40px;
      padding: 0px 40px;
      color: ${AddToCartColors.colorAddToCartAddBtnText};
      font-size: 1.1em;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 2px;
      cursor: pointer;
      &:hover {
        background-color: ${AddToCartColors.colorAddToCartAddBtnBgHover};
        color: ${AddToCartColors.colorAddToCartAddBtnTextHover};
      }
      /* ${mobile`
      margin-left: 50px`} */
    }
  }
`

export const AddToCartSingleStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  bottom: 0;
  padding: 10px 0;

  ${mobile`
  // margin-top: 15px;
  justify-content: center;`}

  form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    /* background-color: grey; */
    /* border-radius: 0 0 15px 15px; */
    /* Chrome, Safari, Edge, Opera */
    /* Firefox */
    .inputData {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      padding: 10px 0;
      margin: 0 10px 0 0;
      ${mobile`
        margin: none;
      `}
      input {
        display: block;
        text-align: center; /* Center the text */
        font-size: 1.3em;
        font-weight: bold;
        -moz-appearance: textfield;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }

      .increase {
        height: 40px;
        width: 40px;
        border-radius: 0 20px 20px 0;
        background-color: ${AddToCartColors.colorAddToCartIncreaseBg};
        border: ${AddToCartColors.colorAddToCartIncreaseBorder};
        margin-left: 3px;
        padding-right: 10px;
        color: ${AddToCartColors.colorAddToCartIncreaseText};
        font-size: 1.3em;
        font-weight: bold;
        cursor: pointer;
        &:hover {
          background-color: ${AddToCartColors.colorAddToCartIncreaseBgHover};
          color: ${AddToCartColors.colorAddToCartIncreaseTextHover};
        }
      }

      .decrease {
        height: 40px;
        width: 40px;
        border-radius: 20px 0 0 20px;
        background-color: ${AddToCartColors.colorAddToCartDecreaseBg};
        border: ${AddToCartColors.colorAddToCartDecreaseBorder};
        margin-right: 3px;
        padding-left: 10px;
        color: ${AddToCartColors.colorAddToCartDecreaseText};
        font-size: 1.3em;
        font-weight: bold;
        cursor: pointer;
        &:hover {
          background-color: ${AddToCartColors.colorAddToCartDecreaseBgHover};
          color: ${AddToCartColors.colorAddToCartDecreaseTextHover};
        }
      }

      .quantityInput {
        height: 40px;
        width: 40px;
        border: ${AddToCartColors.colorAddToCartQtyBorder};
        color: ${AddToCartColors.colorAddToCartQtyText};
        background-color: ${AddToCartColors.colorAddToCartQtyBg};
      }
      /* .quantitySelect {
        height: 30px;
        width: 80px;
        border-radius: 5px;
      } */
    }

    .addToCart {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      height: 40px;
      background-color: ${AddToCartColors.colorAddToCartAddBtnBg};
      border: ${AddToCartColors.colorAddToCartAddBtnBorder};
      /* margin-left: 20px; */
      border-radius: 40px;
      padding: 0px 40px;
      color: ${AddToCartColors.colorAddToCartAddBtnText};
      font-size: 1.1em;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 2px;
      cursor: pointer;
      &:hover {
        background-color: ${AddToCartColors.colorAddToCartAddBtnBgHover};
        color: ${AddToCartColors.colorAddToCartAddBtnTextHover};
      }
      /* ${mobile`
      margin-left: 50px`} */
    }
  }
`
