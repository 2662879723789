import React, { useState, useEffect } from 'react'

import {
  AddToCartListProductsStyled,
  AddToCartSingleStyled,
} from './AddToCartLocalStorageStyled'
import SuccesAlert from '../alert/SuccesAlert'

function AddToCartLocalStorageWithOptions({
  id,
  eanCode,
  productName,
  productWeight,
  productPrice,
  productCurrency,
  productCoverImage,
  addToCartStyle,
  inCartProducts,
  productOptions,
}) {
  const TemplateStyle =
    addToCartStyle === 'multipleProducts'
      ? AddToCartListProductsStyled
      : AddToCartSingleStyled

  const [quantity, setQuantity] = useState(1)
  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem('cart')
    return savedCart ? JSON.parse(savedCart) : []
  })
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')

  // Persist cart to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart))
  }, [cart])

  const handleQuantity = (e) => {
    const newValue = parseInt(e.target.value, 10)
    setQuantity(isNaN(newValue) || newValue < 1 ? 1 : newValue)
  }

  const decreaseValue = (e) => {
    e.preventDefault()
    setQuantity((prevQuantity) => (prevQuantity > 1 ? prevQuantity - 1 : 1))
  }

  const increaseValue = (e) => {
    e.preventDefault()
    setQuantity((prevValue) => prevValue + 1)
  }

  const handleShowAlert = (message) => {
    setAlertMessage(message)
    setShowAlert(true)
    setTimeout(() => setShowAlert(false), 1000)
  }

  // const handleSubmit = (e) => {
  //   e.preventDefault()

  //   // Retrieve the current cart from localStorage
  //   const storedCart = JSON.parse(localStorage.getItem('cart')) || []

  //   // If product doesn't exist, add it to the cart
  //   let updatedCart = [
  //     ...storedCart, // Spread the previous cart to keep existing products
  //     {
  //       id,
  //       ean_code: eanCode,
  //       name: productName,
  //       quantity,
  //       productOptions: productOptions,
  //     }, // Add the new product
  //   ]

  //   // Update localStorage with the new cart
  //   localStorage.setItem('cart', JSON.stringify(updatedCart))

  //   //  Update the cart state
  //   setCart(updatedCart)

  //   // Show success alert
  //   handleShowAlert(
  //     `Produsul "${productName}" a fost adaugat in cos. Cantitate: ${quantity}`
  //   )

  //   inCartProducts()
  //   // Reset quantity after adding
  //   setQuantity(1)
  // }

  //  ------

  // const handleSubmit = (e) => {
  //   e.preventDefault()

  //   // Retrieve the current cart from localStorage
  //   const storedCart = JSON.parse(localStorage.getItem('cart')) || []
  //   //  ------------------------
  //   const existingItemNoOption = storedCart.find(
  //     (item) =>
  //       item.ean_code === eanCode &&
  //       Array.isArray(item.productOptions) &&
  //       item.productOptions.length === 0
  //   )

  //   console.log(existingItemNoOption)

  //   let updatedCart
  //   // If product exists, update its quantity
  //   if (existingItemNoOption && productOptions.length === 0) {
  //     updatedCart = storedCart.map((item) =>
  //       item.ean_code === eanCode
  //         ? { ...item, quantity: item.quantity + quantity }
  //         : item
  //     )
  //   } else {
  //     const tempId = Date.now() + Math.floor(Math.random() * 1000)

  //     const newProduct = {
  //       id,
  //       ean_code: eanCode,
  //       name: productName,
  //       productWeight: productWeight,
  //       productPrice: productPrice,
  //       productCoverImage: productCoverImage,
  //       quantity,
  //       productOptions: productOptions,
  //       tempId, // Add the unique numeric tempId
  //     }

  //     // If product doesn't exist, add it to the cart
  //     updatedCart = [
  //       ...storedCart, // Spread the previous cart to keep existing products
  //       newProduct, // Add the new product
  //     ]
  //   }
  //   //  ---------------------------
  //   // Generate a unique numeric tempId
  //   // const tempId = Date.now() + Math.floor(Math.random() * 1000)

  //   // Create the new product object with the unique tempId
  //   // const newProduct = {
  //   //   id,
  //   //   ean_code: eanCode,
  //   //   name: productName,
  //   //   productWeight: productWeight,
  //   //   productPrice: productPrice,
  //   //   productCoverImage: productCoverImage,
  //   //   quantity,
  //   //   productOptions: productOptions,
  //   //   tempId, // Add the unique numeric tempId
  //   // }

  //   // Update the cart with the new product
  //   // const updatedCart = [...storedCart, newProduct]

  //   // Update localStorage with the new cart
  //   localStorage.setItem('cart', JSON.stringify(updatedCart))

  //   // Update the cart state
  //   setCart(updatedCart)

  //   // Show success alert
  //   handleShowAlert(
  //     `Produsul "${productName}" a fost adaugat in cos. Cantitate: ${quantity}`
  //   )

  //   inCartProducts()

  //   // Reset quantity after adding
  //   setQuantity(1)
  // }

  const handleSubmit = (e) => {
    e.preventDefault()

    // Retrieve the current cart from localStorage
    const storedCart = JSON.parse(localStorage.getItem('cart')) || []

    // Find the product with the same ean_code and no productOptions
    const existingItemNoOption = storedCart.find(
      (item) =>
        item.ean_code === eanCode &&
        Array.isArray(item.productOptions) &&
        item.productOptions.length === 0
    )

    let updatedCart

    // If product exists and has no options, update its quantity
    if (existingItemNoOption && productOptions.length === 0) {
      updatedCart = storedCart.map((item) =>
        item.ean_code === eanCode &&
        Array.isArray(item.productOptions) &&
        item.productOptions.length === 0
          ? { ...item, quantity: item.quantity + quantity }
          : item
      )
    } else {
      // Generate a unique numeric tempId
      const tempId = Date.now() + Math.floor(Math.random() * 1000)

      // Create the new product object with the unique tempId
      const newProduct = {
        id,
        ean_code: eanCode,
        name: productName,
        productWeight: productWeight,
        productPrice: productPrice,
        productCurrency: productCurrency,
        productCoverImage: productCoverImage,
        quantity,
        productOptions: productOptions,
        tempId, // Add the unique numeric tempId
      }

      // Add the new product to the cart
      updatedCart = [...storedCart, newProduct]
    }

    // Update localStorage with the new cart
    localStorage.setItem('cart', JSON.stringify(updatedCart))

    // Update the cart state
    setCart(updatedCart)

    // Show success alert
    handleShowAlert(
      `Produsul "${productName}" a fost adaugat in cos. Cantitate: ${quantity}`
    )

    // Perform additional updates
    inCartProducts()

    // Reset quantity after adding
    setQuantity(1)
  }

  // console.log(productOptions)

  return (
    <>
      {showAlert && <SuccesAlert message={alertMessage} />}

      <TemplateStyle>
        <form id={eanCode} onSubmit={handleSubmit}>
          <div className='inputData'>
            <button type='button' className='decrease' onClick={decreaseValue}>
              -
            </button>
            <input
              type='number'
              name='quantity'
              id='quantity'
              className='quantityInput'
              value={quantity}
              onChange={handleQuantity}
              min='1'
            />
            <button type='button' className='increase' onClick={increaseValue}>
              +
            </button>
          </div>
          <button type='submit' className='addToCart'>
            Pune in cos
          </button>
        </form>
      </TemplateStyle>
    </>
  )
}

export default AddToCartLocalStorageWithOptions

// in parent component

// <AddToCartLocalStorage
//   id={product.id}
//   eanCode={product.ean_code}
//   productName={product.name}
//   addToCartStyle='singleProduct'
//   inCartProducts={inCartProducts}
//   productOptions={selectedProductOptions}
// />
