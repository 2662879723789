import React from 'react'
import { CustomerLayoutStyled } from './CustomerLayoutStyled'
import { Outlet } from 'react-router-dom'
import Header from '../../components/common/header/Header'
import Footer from '../../components/common/footer/Footer'

function CustomerLayout({ isLoggedIn, toggleLogin, inCartQuantity }) {
  return (
    <>
      <Header
        isLoggedIn={isLoggedIn}
        toggleLogin={toggleLogin}
        inCartQuantity={inCartQuantity}
      />
      <CustomerLayoutStyled>
        <div className='container'>
          <div className='row mainContent'>
            <div className='col-8 content'>
              <Outlet />
            </div>
          </div>
        </div>
      </CustomerLayoutStyled>
      <Footer />
    </>
  )
}

export default CustomerLayout
