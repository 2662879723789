import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import {
  getProductCategorysByID,
  getProductsByCategory,
} from '../../../api/shopAPI'
import { useNavigate } from 'react-router'
// import { CategoriesStyled } from './CategoriesStyled'
// import AddToCartLocalStorage from '../../components/addToCartLocalStorage/AddToCartLocalStorage'
import AddToCartLocalStorageWithOptions from '../../../components/common/addToCartLocalStorage/AddToCartLocalStorageWithOptions'
import { getCurrentTime } from '../../../functions/formatDate'
import { ProductsListStyled } from '../../../styles/elements/products/ProductsListStyled'
import capitalizeFirstLetter from '../../../functions/capinalizeFirstLetter'
import toUpperCase from '../../../functions/toUpperCase'
import { NoDataStyled } from '../../../styles/elements/NoDataStyled'
import RedirectHome from '../../../components/common/redirectHome/RedirectHome'

function CategoriesProducts({ inCartProducts, dayOpen }) {
  const { id } = useParams()
  const navigate = useNavigate()
  const [products, setProducts] = useState([])
  const [categories, setCategories] = useState(null)
  const addToCartStyle = 'multipleProducts'
  const isOpen = dayOpen.length > 0 ? dayOpen[0].open : null

  useEffect(() => {
    getProductsByCategory(id, (response) => {
      setProducts(response)
      getProductCategorysByID(id, (response) => {
        setCategories(response)
      })
    })
  }, [id])

  return (
    <>
      {categories ? (
        <ProductsListStyled id='top'>
          <div className='catTitle'>
            <h2>{categories.name}</h2>
          </div>
          {products.length > 0 ? (
            <div className='listContainer'>
              {products.map((el, index) => (
                <div className='productContainer' key={index}>
                  {/* <a
              href='#'
              onClick={(e) => {
                e.preventDefault()
                navigate(`/produs/${el.product_link}`)
              }}
            > */}
                  <div
                    className='productDataContainer'
                    onClick={(e) => {
                      e.preventDefault()
                      navigate(`/produs/${el.product_link}`)
                    }}
                  >
                    <div className='productImage'>
                      {el.cover_image !== null ? (
                        <img
                          src={el.cover_image.image_link}
                          alt={el.name}
                          className='coverImg'
                        />
                      ) : (
                        <img
                          src={
                            'https://www.aroma-pasiunii.intorsura.ro/media/aroma-pasiunii-no-photo.png'
                          }
                          alt={el.name}
                          className='coverImg'
                        />
                      )}
                    </div>
                    <div className='productData'>
                      <div className='productName'>
                        <h2>
                          {toUpperCase(el.name)} {el.weight}
                          {el.weight_classes.name_short}
                        </h2>
                      </div>
                      <div className='productDetails'>
                        {capitalizeFirstLetter(el.ingredients)}
                      </div>
                      <div className='price'>
                        {el.price}
                        <span className='currency'>
                          {el.currency.currency_short}
                        </span>
                      </div>
                      {/* </a> */}
                      {/* {el.frozen_products === 1 ? (
                        <div className='frozenProduct'>
                          Contine produse congelate
                        </div>
                      ) : (
                        <div className='frozenProduct'></div>
                      )} */}

                      {/* {getCurrentTime() > el.product_category.delivery_from_time &&
              getCurrentTime() < el.product_category.delivery_to_time ? (
                <div className='addToCart'>
                  <AddToCartLocalStorage
                    index={index}
                    id={el.id}
                    eanCode={el.ean_code}
                    productName={el.name}
                    addToCartStyle={addToCartStyle}
                    inCartProducts={inCartProducts}
                  />
                </div>
              ) : (
                <div className='noDeliveryTime'>
                  <span>Puteti comanda intre orele:</span>
                  <span>
                    {el.product_category.delivery_from_time} -{' '}
                    {el.product_category.delivery_to_time}
                  </span>
                </div>
              )} */}
                      {/* <div className='addToCart'>
                <AddToCartLocalStorage
                  index={index}
                  id={el.id}
                  eanCode={el.ean_code}
                  productName={el.name}
                  addToCartStyle={addToCartStyle}
                  inCartProducts={inCartProducts}
                />
              </div> */}
                    </div>
                  </div>

                  <AddToCartLocalStorageWithOptions
                    // index={index}
                    id={el.id}
                    eanCode={el.ean_code}
                    productName={el.name}
                    productWeight={el.weight + el.weight_classes.name_short}
                    productPrice={parseFloat(el.price)}
                    productCurrency={el.currency.currency_short}
                    productCoverImage={
                      el.cover_image !== null ? el.cover_image.image_link : ''
                    }
                    addToCartStyle='singleProduct'
                    inCartProducts={inCartProducts}
                    productOptions={[]}
                  />
                </div>
              ))}
            </div>
          ) : (
            <NoDataStyled>
              <div className='noData'>
                <h1>Nu exista produse!</h1>
              </div>
              <RedirectHome toPage={'meniu'} />
            </NoDataStyled>
          )}
        </ProductsListStyled>
      ) : (
        <>
          <NoDataStyled>
            <div className='noData'>
              <h1>Nu am gasit categoria!</h1>
            </div>
            <RedirectHome toPage={'meniu'} />
          </NoDataStyled>
        </>
      )}
    </>
  )
}

export default CategoriesProducts
