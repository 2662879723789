import React, { useEffect, useState } from 'react'
import { AllOrdersHistoryStyled } from './AllOrdersHistoryStyled'
import { getAllOrders, getAllPaginatedOrders } from '../../../api/orders'
import OrderdProducts from '../orderdProducts/OrderdProducts'
import {
  formatDate,
  formatHours,
  storedTimeDifference,
} from '../../../functions/formatDate'
import TimeDifference from '../../../components/common/timeDifrence/TimeDifference'
import Pagination from '../../../components/common/pagination/Pagination'

function AllOrdersHistory() {
  const [customerData, setCustomerData] = useState({}) // Store customer data retrieved from localStorage
  const [orderList, setOrderList] = useState({ orders: [] }) // Store the list of orders
  const [visibleOrderId, setVisibleOrderId] = useState(null) // Track which order's details are currently visible
  const [currentPage, setCurrentPage] = useState(1) // Track the current page for pagination
  const [fetchAllOrders, setFetchAllOrders] = useState(false) // Track whether to fetch all orders or paginated orders
  const [orderBy, setOrderBy] = useState(1)

  // Fetch orders when the component mounts and whenever currentPage or fetchAllOrders changes
  useEffect(() => {
    const storedCustomerData = JSON.parse(localStorage.getItem('adminData')) // Retrieve customer data from localStorage
    if (storedCustomerData) {
      setCustomerData(storedCustomerData)
      fetchOrders() // Fetch orders based on the current state (all or paginated)
    }
  }, [currentPage, fetchAllOrders, orderBy]) // Run the effect whenever currentPage or fetchAllOrders changes

  // Function to fetch orders based on whether all orders or paginated orders are needed
  const fetchOrders = () => {
    if (fetchAllOrders) {
      // Fetch all orders (unpaginated)
      getAllOrders(
        orderBy,
        (response) => {
          setOrderList(response) // Update the order list state with the response
        },
        (error) => {
          console.error('Failed to retrieve orders:', error)
        }
      )
    } else {
      // Fetch paginated orders
      getAllPaginatedOrders(
        currentPage,
        orderBy,
        (response) => {
          setOrderList(response) // Update the order list state with the response
        },
        (error) => {
          console.error('Failed to retrieve orders:', error)
        }
      )
    }
  }

  // Function to toggle between fetching all orders and paginated orders
  const toggleFetchAllOrders = () => {
    setFetchAllOrders((prev) => !prev) // Toggle the fetchAllOrders state
    setCurrentPage(1) // Reset to the first page when switching modes
  }

  // Function to handle pagination page changes
  const handlePageChange = (page) => {
    setCurrentPage(page) // Update the current page state
  }

  return (
    <>
      {orderList.orders.length > 0 ? ( // Check if there are orders to display
        <AllOrdersHistoryStyled>
          <div className='col-12 artTitle'>
            <h2>Istoric comezi</h2>
            <p> Total comenzi: {orderList.total}</p>
          </div>
          {/* Show pagination only if we're in paginated mode and there are multiple pages */}
          {!fetchAllOrders && orderList.last_page > 1 && (
            <Pagination
              currentPage={orderList.current_page}
              totalPages={orderList.last_page}
              onPageChange={handlePageChange}
            />
          )}

          <div className='optionsview'>
            <div className='pagesView'>
              {/* Button to toggle between "All" and "Paginated" views */}
              Vezi{' '}
              <button onClick={toggleFetchAllOrders}>
                {fetchAllOrders ? '10 comenzi / pag' : 'toate comenzile'}{' '}
                {/* Change button text based on the state */}
              </button>
            </div>
            <div className='orderByView'>
              Ordoneaza dupa{' '}
              <button onClick={() => setOrderBy(1)}>Status</button>
              <button onClick={() => setOrderBy(2)}>Data</button>
              <button onClick={() => setOrderBy(3)}>Valoare</button>
            </div>
          </div>

          <div className='container' id='orders'>
            <div className='row orderList'>
              {orderList.orders.map((el, index) => (
                <React.Fragment key={index}>
                  <div
                    className={
                      el.active === 1
                        ? 'col-4 order recived'
                        : el.active === 2
                        ? 'col-4 order inPreparation'
                        : el.active === 5
                        ? 'col-4 order canceld'
                        : el.active === 3
                        ? 'col-4 order finished'
                        : ''
                    }
                    onClick={() => {
                      setVisibleOrderId(
                        visibleOrderId === el.order_id ? null : el.order_id
                      )
                    }}
                  >
                    <div className='orderNumber'>
                      <span>
                        <h4>{el.order_id}</h4>
                      </span>
                    </div>
                    <div className='orderStatus'>
                      {el.active === 1
                        ? 'Comanda noua'
                        : el.active === 2
                        ? 'In preparare'
                        : el.active === 5
                        ? 'Anulata'
                        : el.active === 3
                        ? 'Finalizata'
                        : ''}
                    </div>
                    <div className='orderPrice'>
                      <div className='orderValue'>
                        <span>Total: </span>
                        <h3>{Number(el.total_price).toFixed(2)}</h3>{' '}
                        <span>{el.currency}</span>
                      </div>
                    </div>
                    <div className='orderTime'>
                      Data comanda: {formatDate(el.created_at)}
                    </div>
                    {el.active === 3 ? (
                      <div className='finishedTimeDiffrence'>
                        <div>Finalizata in:</div>
                        <span>
                          {storedTimeDifference(
                            formatHours(el.created_at),
                            formatHours(el.updated_at)
                          )}
                        </span>
                      </div>
                    ) : (
                      ''
                    )}
                    {(el.active === 1 || el.active === 2) && (
                      <div className='timeDiffrence'>
                        <div>Au trecut:</div>
                        <TimeDifference
                          storedTime={formatHours(el.created_at)}
                        />
                      </div>
                    )}
                  </div>

                  {visibleOrderId === el.order_id && (
                    <OrderdProducts
                      cancel={() => setVisibleOrderId(null)}
                      orderId={el.order_id}
                      totalOrderPrice={el.total_price}
                      currency={el.currency}
                      orderStatus={el.active}
                      customerID={el.user_id}
                    />
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
          {!fetchAllOrders && orderList.last_page > 1 && (
            <Pagination
              currentPage={orderList.current_page}
              totalPages={orderList.last_page}
              onPageChange={handlePageChange}
            />
          )}
        </AllOrdersHistoryStyled>
      ) : (
        <h2>Nu exista comenzi</h2>
      )}
    </>
  )
}

export default AllOrdersHistory
