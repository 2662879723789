import styled from 'styled-components'
import {
  COLORS,
  addUserColors,
  formColors,
} from '../../../../styles/setings/colors'
import {
  desktop,
  tablet,
  mobile,
} from '../../../../styles/setings/mediaScreens'

export const AboutUnitFormStyled = styled.section`
  // margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin: 30px auto; */
  /* padding-bottom: 20px; */
  // padding: 20px 0 40px 0;
  background-color: ${COLORS.colorAboutSectionBg};
  /* background-color: grey; */
  ${desktop`
    margin-top: 20px;
  `}
  ${tablet`
    margin-top: 20px;
  `}
  ${mobile`
    margin-top: 10px;
  `}
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% - 30px);
  background-color: grey; */

.title {
    margin-bottom: 15px;
    h2 {
      display: inline-flex;
      text-decoration: underline;
      text-decoration-thickness: 4px;
      text-decoration-color: ${COLORS.colorTextUnderlineDefault};
      /* background-color: grey; */

      ${desktop`
        align-items: center;
        justify-content: center;    
        width: 100%;
        font-size: 2rem;
        font-weight: bold;
      `}
      ${tablet`
        align-items: center;
        justify-content: center;    
        width: 100%;
        font-size: 2rem;
        font-weight: bold;
      `}
      ${mobile`
          align-items: center;
          justify-content: center;    
          width: 100%;
          font-size: 2rem;
          font-weight: bold;
      `}
    }
  }

  .changeStatusFormContainer {
    margin-bottom: 25px;
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 0 15px 0;
    border-radius: 15px;
    /* border-bottom: solid #fff 3px; */
    /* background-color: #420000; */
    color: #fff;
    ${mobile`
         padding: 15px 0 10px 0;
      `}
  }

  .aboutImage {
    max-width: 800px;
    img {
      width: 100%;
    }
  }

  .changeImageButtonFormContainer {
    margin-bottom: 25px;
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 0 40px 0;
    border-radius: 15px;
    /* border-bottom: solid #fff 3px; */
    background-color: #420000;
    color: #fff;
    ${mobile`
         padding: 15px 0 10px 0;
      `}
  }
`
