import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { CategoriesMenuStyled } from './CategoriesMenuStyled'
import { getCategories } from '../../../api/shopAPI'
import HorizontalScrollingMenu from '../../../components/common/horizontalScrollingMenu/HorizontalScrollingMenu'

function CategoriesMenu() {
  const [categories, setCategories] = useState([])
  const [selectedElement, setSelectedElement] = useState(0) // Default to the first category

  const navigate = useNavigate()

  useEffect(() => {
    getCategories((response) => {
      setCategories(response)
    })
  }, [])

  const handleElementClick = (index, categoryLink) => {
    setSelectedElement(index)
    navigate(`/meniu/${categoryLink}`)
  }

  return (
    <>
      <CategoriesMenuStyled>
        {/* <div className='catTitle'>
          <h2>Categories</h2>
        </div> */}
        <HorizontalScrollingMenu
          items={categories}
          onElementClick={handleElementClick}
        />
        {/* <div className='container'>
          <div className='row categoryList'>
            {categories.map((el, index) => {
              return (
                <div className='col-3 tumbnailCategory' key={index}>
                  <a
                    href='#'
                    onClick={(e) => {
                      e.preventDefault()
                      navigate(`/categories/${el.category_link}`)
                    }}
                  >
                    <div className='tumbnailImg'>
                      <img src={el.image} alt={el.name} />
                    </div>
                    <div className='categoryName'>
                      <h2>{el.name}</h2>
                    </div>
                  </a>
                </div>
              )
            })}
          </div>
        </div> */}
      </CategoriesMenuStyled>
    </>
  )
}
export default CategoriesMenu
