import React, { useState, useEffect } from 'react'
import {
  deleteSpecialOffer,
  getAllSpecOffer,
  updateFacilityActive,
  updateSpecOffersActive,
} from '../../../api/unitDataAPI'
import { SpecialOffersListStyled } from './SpecialOffersListStyled'
import BackToSetings from '../../../components/common/buttons/backToSetings/BackToSetings'
import {
  SubmitButtonSmall,
  RedSubmitButtonSmall,
  SubmitButton,
} from '../../../styles/elements/buttons'
import SpecialOfferForm from '../../../components/admin/forms/specialOfferForm/SpecialOfferForm'
import SuccesAlert from '../../../components/common/alert/SuccesAlert'
import ErrorAlert from '../../../components/common/alert/ErrorAlert'
import TogleSwitch from '../../../components/common/buttons/togleSwitch/TogleSwitch'

function SpecialOffers() {
  const [specialOffers, setSpecialOffers] = useState([])
  const [showSpecialOfferFormId, setShowSpecialOfferFormId] = useState(false)
  const [responseErrors, setResponseErrors] = useState([])
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    getAllSpecOffer((response) => {
      setSpecialOffers(response)
    })
  }, [showSpecialOfferFormId])

  // Function to calculate expiry date
  function calculateExpiryDate(valability, updatedAt) {
    const updatedDate = new Date(updatedAt) // Parse the updated_at date
    updatedDate.setDate(updatedDate.getDate() + valability) // Add valability days
    return updatedDate.toLocaleDateString() // Format the expiration date
  }

  function handleAddNew() {
    setShowSpecialOfferFormId(true)
  }
  function handleEdit(offerId) {
    setShowSpecialOfferFormId(offerId)
  }

  const handleActivate = (id, active) => {
    // event.preventDefault()
    const newActive = active === 1 ? { active: 0 } : { active: 1 }

    // Set loading state to true
    setIsLoading(true)

    updateSpecOffersActive(id, newActive, handleActivateSuccess, handleError)
  }

  // ------------ pentru checkbox
  function handleActivateSuccess(response) {
    // Update the facility's active state directly in the unitFacilitys array
    setSpecialOffers((prevSpecialOffer) =>
      prevSpecialOffer.map((specialOffers) =>
        specialOffers.id === response.id
          ? { ...specialOffers, active: response.active } // Update the active status
          : specialOffers
      )
    )
    // Reset loading state
    setIsLoading(false)
  }

  function handleDelete(offerId) {
    setIsLoading(true)
    deleteSpecialOffer(offerId, handleSuccess, handleError)
  }

  function handleError(error) {
    console.error(error)

    // Parse the error response
    const errorResponse = error.response.data
    const parsedErrors = Object.values(errorResponse).flat()

    // Update the state with the parsed errors
    setResponseErrors(parsedErrors)

    // Reset loading state
    setIsLoading(false)
  }

  function handleSuccess(response) {
    // console.log(response)
    // alert('Contul a fost creat cu succes!')
    setAlertMessage('Oferta a fost stearsa cu succes!')
    setShowAlert(true)
    // setFormData(cleanFormData)
    // navigate('/login')

    // Reset loading state
    setIsLoading(false)

    const timer = setTimeout(() => {
      window.location.reload()
      // window.location.href = '/setari'
    }, 2100)
    return () => clearTimeout(timer)
  }

  // Function to remove an error message from the state
  const removeError = (index) => {
    setResponseErrors(responseErrors.filter((_, i) => i !== index))
  }

  return (
    <>
      {showAlert && <SuccesAlert message={alertMessage} />}

      <div>
        {responseErrors.map((message, index) => (
          <ErrorAlert
            key={index}
            message={message}
            onRemove={() => removeError(index)}
          />
        ))}
      </div>
      <BackToSetings />
      <SpecialOffersListStyled>
        <div className='title'>
          <h2>Oferte speciale</h2>
        </div>
        <div className='changeStatusFormContainer'>
          <SubmitButton onClick={() => handleAddNew()}>Adauga</SubmitButton>
        </div>
        <div className='sectionSpecOfferContainer'>
          {specialOffers.map((el, index) => (
            <React.Fragment key={index}>
              <div className='specOfferContainer'>
                <div className='offerTitle'>
                  <h3>{el.title}</h3>
                  <span className='valability'>
                    Valabilă până la:{' '}
                    {calculateExpiryDate(el.valability, el.updated_at)}
                  </span>
                </div>
                <div className='offerRow'>
                  <div className='offerDescription'>
                    {el.image && <img src={el.image} alt='Aroma Pasiunii' />}
                    {el.description}
                  </div>
                  <div className='offerActionButtons'>
                    <SubmitButtonSmall onClick={() => handleEdit(el.id)}>
                      editează
                    </SubmitButtonSmall>
                    <RedSubmitButtonSmall
                      onClick={() => handleDelete(el.id)}
                      disabled={isLoading}
                    >
                      Sterge
                    </RedSubmitButtonSmall>
                    <TogleSwitch
                      checked={el.active === 1}
                      onChange={() => handleActivate(el.id, el.active)}
                      label={el.active === 1 ? 'Dezactiveaza' : 'Activeaza'}
                    />
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>

        {showSpecialOfferFormId !== false && (
          <SpecialOfferForm
            specialOfferId={showSpecialOfferFormId}
            cancel={() => setShowSpecialOfferFormId(false)}
          />
        )}
      </SpecialOffersListStyled>
    </>
  )
}

export default SpecialOffers
