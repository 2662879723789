import React, { useState, useEffect } from 'react'
import { CurrentDateStyled } from './CurrentDateStyled'
import { formatDate } from '../../../functions/formatDate'

function CurrentDate() {
  const [currentDate, setCurrentDate] = useState('')

  useEffect(() => {
    // Get current date
    const date = new Date()

    // Format date to a readable string (e.g., August 27, 2024)
    const options = { year: 'numeric', month: 'long', day: 'numeric' }
    const formattedDate = date.toLocaleDateString(undefined, options)

    // Set the formatted date to state
    setCurrentDate(formattedDate)
  }, [])

  return <CurrentDateStyled> {formatDate(currentDate)} </CurrentDateStyled>
}

export default CurrentDate
