import styled from 'styled-components'
import {
  COLORS,
  addUserColors,
  formColors,
  popUpFomsColors,
} from '../../../../../styles/setings/colors'
import {
  desktop,
  tablet,
  mobile,
} from '../../../../../styles/setings/mediaScreens'

export const ProductAddFormStyled = styled.div`
  // margin: auto;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: calc(100% - 30px); */
  /* background-color: grey; */
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
  /* margin: 30px auto; */
  /* padding-bottom: 20px; */
  // padding: 20px 0 40px 0;
  background-color: ${popUpFomsColors.popUpFormContainerBgColor};
  /* background-color: grey; */
  /* ${desktop`
    margin-top: 20px;
  `}
  ${tablet`
    margin-top: 20px;
  `}
  ${mobile`
    margin-top: 10px;
  `} */

  .formContainer {
    width: 100%;
    max-width: 800px;
    /* margin: 180px 0 15px 0; */
    /* background-color: grey; */
    padding: 15px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none; /* For Chrome, Safari, and other WebKit-based browsers */
    }

    .formTitle {
      width: 100%;
      display: flex;
      /* border: solid #fff 1px; */
      padding: 10px 0;
      background-color: #2c2c2c;
      border-radius: 15px 15px 0 0;
      h3 {
        display: inline-flex;
        text-decoration: underline;
        text-decoration-thickness: 4px;
        text-decoration-color: ${COLORS.colorTextUnderlineDefault};
        /* background-color: grey; */

        ${desktop`
        align-items: center;
        justify-content: center;    
        width: 100%;
        font-size: 2rem;
        font-weight: bold;
      `}
        ${tablet`
        align-items: center;
        justify-content: center;    
        width: 100%;
        font-size: 2rem;
        font-weight: bold;
      `}
      ${mobile`
          align-items: center;
          justify-content: center;    
          width: 100%;
          font-size: 2rem;
          font-weight: bold;
      `}
      }
    }

    .cancelButtonContainer {
      margin-bottom: 25px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 5px 0 5px 0;

      .closeButton {
        width: 100%;
        max-width: 800px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5em;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
        margin-top: 25px;
        padding: 10px 25px 10px 25px;
        border-radius: 50px;
        border: solid #ccc 2px;
        background-color: #6c6c6c;
        color: #fff;
        cursor: pointer;

        &:hover {
          background-color: #474747;
          border: solid #474747 2px;
        }

        ${mobile`
          margin: 10px 0;
          width: 80%;
      `}
      }
    }

    .changeStatusFormContainer {
      margin-bottom: 25px;
      width: 100%;
      max-width: 800px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 15px 0 15px 0;
      border-radius: 15px;
      /* border-bottom: solid #fff 3px; */
      /* background-color: #420000; */
      color: #fff;
      ${mobile`
         padding: 15px 0 10px 0;
      `}
    }

    .image {
      max-width: 800px;
      img {
        width: 100%;
      }
    }

    .changeImageButtonFormContainer {
      margin-bottom: 25px;
      width: 100%;
      max-width: 800px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      /* padding: 15px 0; */
      border-radius: 15px;
      /* border-bottom: solid #fff 3px; */
      /* background-color: #420000; */
      color: #fff;
      ${mobile`
         padding: 15px 0 10px 0;
         
      `}
    }

    .imagesContainer {
      margin-bottom: 25px;
      width: auto;
      max-width: 630px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      padding: 15px 0;
      border-radius: 15px;
      /* border-bottom: solid #fff 3px; */
      /* background-color: #420000; */
      color: #fff;
      ${mobile`
        padding: 15px 0 10px 0;
        // flex-direction: column;
        justify-content: center;
        width: 100%;
         
      `}

      .imageContainer {
        position: relative;
        margin: 5px;
        width: 200px;

        height: 150px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        background-color: #2d0000;
        border: solid #ff2727 3px;
        border-radius: 10px;
        ${mobile`
    
        `}

        .imageDeleteButtonContainer {
          position: absolute;
          top: 5px;
          left: 5px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background-color: #f00;
          border-radius: 50%;
        }

        .thumbnailImg {
          width: 100%;
          min-height: 140px;
          /* padding: 5px 5px; */
          border-radius: 10px;
        }

        .imageCoverButtonContainer {
          position: absolute;
          bottom: 0px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          background-color: #000;
          opacity: 1;
          padding: 5px 0;
          border-radius: 0px 0 10px 10px;
        }
      }
    }
  }
`
