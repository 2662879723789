import React from 'react'
import Header from '../../components/common/header/Header'
import { MainLayoutStyled } from '../../layouts/mainLayout/MainLayoutStyled'
import Footer from '../../components/common/footer/Footer'
import { NotFoundStyled } from './NotFoundStyled'
import RedirectHome from '../../components/common/redirectHome/RedirectHome'

function NotFound() {
  return (
    <>
      <Header />
      <MainLayoutStyled>
        <NotFoundStyled>
          <div className='container'>
            <div className='row mainContent'>
              <div className='col-12'>
                <h2>Nu am gasit pagina pe care o cauti</h2>
              </div>
            </div>
          </div>
          <RedirectHome />
        </NotFoundStyled>
      </MainLayoutStyled>
      <Footer />
    </>
  )
}

export default NotFound
