import React, { useEffect, useState } from 'react'
import { OrderHistoryStyled } from './OrderHistoryStyled'
import { getOrdersByCustomerID } from '../../../../api/orders'
import OrderdProducts from '../orderdProducts/OrderdProducts'
import { formatDate } from '../../../../functions/formatDate'

function OrderHistory({ elID }) {
  const [customerData, setCustomerData] = useState({})
  const [orderList, setOrderList] = useState([])
  const [visibleOrderId, setVisibleOrderId] = useState(null)

  console.log(orderList)
  useEffect(() => {
    // const storedCustomerData = JSON.parse(localStorage.getItem('userData'))
    // if (storedCustomerData) {
    //   setCustomerData(storedCustomerData)
    //   getOrdersByCustomerID(
    //     elID,
    //     (response) => {
    //       setOrderList(response)
    //     },
    //     (error) => {
    //       console.error('Failed to retrieve orders:', error)
    //     }
    //   )
    // }
    getOrdersByCustomerID(
      elID,
      (response) => {
        setOrderList(response)
      },
      (error) => {
        console.error('Failed to retrieve orders:', error)
      }
    )
  }, [])

  return (
    <>
      {orderList.length > 0 ? (
        <OrderHistoryStyled>
          <div className='col-12 artTitle'>
            <h2>Istoric comezi</h2>
          </div>

          <div className='orders' id='orders'>
            {orderList.map((el, index) => (
              <React.Fragment key={index}>
                <div className='customerOrdersList'>
                  <div className='orderNumber'>
                    <span>
                      Comanda: <h4>#{el.order_id}</h4>
                    </span>
                    <span className='orderStatus'>
                      Status: {el.active === 1 ? 'Transmisa' : 'Finalizata'}
                    </span>
                    <span className='orderDate'>
                      Data: {formatDate(el.created_at)}
                    </span>
                  </div>
                  <div className='orderPrice'>
                    <div className='orderValue'>
                      <span>Valoare: </span>
                      <h3>{el.total_price}</h3> <span>{el.currency}</span>
                    </div>
                  </div>
                  <div className='buttons'>
                    <button
                      className='viewOrder'
                      onClick={() => {
                        setVisibleOrderId(
                          visibleOrderId === el.order_id ? null : el.order_id
                        )
                      }}
                    >
                      Vezi comanda
                    </button>
                  </div>
                </div>
                {visibleOrderId === el.order_id && (
                  <OrderdProducts
                    cancel={() => setVisibleOrderId(null)}
                    orderId={el.order_id}
                    totalOrderPrice={el.total_price}
                    currency={el.currency}
                    elID={elID}
                  />
                )}
              </React.Fragment>
            ))}
          </div>
        </OrderHistoryStyled>
      ) : (
        // <h2>Nu exista comenzi</h2>
        ''
      )}
    </>
  )
}

export default OrderHistory
