import React, { useState, useEffect } from 'react'
import { searchProducts } from '../../../api/shopAPI'
import { SearchStyled } from './SearchStyled'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

const cleanFormData = ''

function Search({ country, onitemselect }) {
  const [query, setQuery] = useState(cleanFormData) // State for input query
  const [results, setResults] = useState([]) // Filtered search results
  const [items, setItems] = useState([]) // All items fetched
  const [isLoading, setIsLoading] = useState(false) // Loading state
  const [debounceTimeout, setDebounceTimeout] = useState(null) // Timeout for debouncing
  const navigate = useNavigate()
  const [message, setMessage] = useState('')

  // const fetchitems = async (searchQuery) => {
  //   setIsLoading(true)
  //   searchProducts(searchQuery, (response) => {
  //     setItems(response) // Store all items fetched
  //     setResults(response) // Update search results
  //     setIsLoading(false)
  //   })
  // }
  //  -----------------------
  // const fetchitems = async (searchQuery) => {
  //   setIsLoading(true)
  //   searchProducts(searchQuery, (response) => {
  //     if (response.message) {
  //       setMessage(response.message)
  //       setResults([])
  //     } else {
  //       setItems(response) // Store all items fetched
  //       setResults(response) // Update search results
  //       setIsLoading(false)
  //       setMessage('')
  //     }
  //   })
  // }
  // ---------------

  const fetchitems = async (searchQuery) => {
    setIsLoading(true)
    searchProducts(searchQuery, (response) => {
      if (response?.message) {
        setMessage(response.message)
        setResults([])
      } else if (Array.isArray(response) && response.length > 0) {
        setItems(response) // Store all items fetched
        setResults(response) // Update search results
        setMessage('') // Clear any previous message
      } else {
        setMessage('A aparut o eroare.')
        setResults([])
      }
      setIsLoading(false)
    })
  }

  //  ----------------------
  // const fetchitems = async (searchQuery) => {
  //   setIsLoading(true)
  //   searchProducts(searchQuery, (response) => {
  //     if (response.success && response.data.length === 0) {
  //       setResults([]) // Clear results
  //       setMessage(response.message) // Set message from API
  //     } else {
  //       setItems(response.data) // Store all items fetched
  //       setResults(response.data) // Update search results
  //       setMessage('') // Clear any previous message
  //     }
  //     setIsLoading(false)
  //   })
  // }
  // console.log(message)

  useEffect(() => {
    // Start search only if query is not empty
    if (query.length >= 3) {
      fetchitems(query)
    } else {
      setResults([]) // Clear results if query is too short
      setMessage('')
    }
  }, [query, country])

  const handleInputChange = (e) => {
    const { value } = e.target
    setQuery(value)

    // Clear previous debounce timeout
    if (debounceTimeout) {
      clearTimeout(debounceTimeout)
    }

    // Trigger search with debounce
    if (value.length >= 3) {
      const timeoutId = setTimeout(() => {
        fetchitems(value)
      }, 500)
      setDebounceTimeout(timeoutId)
    } else {
      setResults([]) // Clear results if query is too short
    }
  }

  const handleClearSearch = () => {
    setQuery('')
    setResults([]) // Clear the search results
  }

  return (
    <>
      {/* <FieldLabel>Cauta produse</FieldLabel> */}
      <SearchStyled>
        <div className='searchContainer'>
          <input
            type='text'
            name='search'
            value={query}
            onChange={handleInputChange}
            placeholder={`Cauta produse `}
          />
          <button className='clearSearch' onClick={handleClearSearch}>
            X
          </button>
          {/* <button className='searchBtn'>
            <FontAwesomeIcon icon={faSearch} />
          </button> */}
        </div>
        {isLoading ? (
          <p>Caut ...</p>
        ) : message ? (
          <p>{message}</p>
        ) : (
          <ul>
            {results.map((result, index) => (
              <li
                key={index}
                onClick={(e) => {
                  e.preventDefault()
                  navigate(`/produs/${result.product_link}`)
                  handleClearSearch()
                }}
              >
                {result.name}
              </li>
            ))}
          </ul>
        )}
        {/* {isLoading ? (
          <>
            <p>Caut ...</p>
            <p>{message}</p>
          </>
        ) : (
          <ul>
            {results.map((result, index) => (
              <li
                key={index}
                onClick={(e) => {
                  e.preventDefault()
                  navigate(`/produs/${result.product_link}`)
                  handleClearSearch()
                }}
              >
                {result.name}
              </li>
            ))}
          </ul>
        )} */}
      </SearchStyled>
    </>
  )
}

export default Search
