import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { HeaderStyled } from './HeaderStyled'
import Navigation from './navigation/Navigation'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BurgerMenu from './burgerMenu/BurgerMenu'
import { getUnitDataHeader } from '../../../api/unitDataAPI'
import displayPhoneNumber from '../../../functions/displayPhoneNumber'
import FadeLoading from '../loaders/fadeLoading/FadeLoading'
import FillWithColorLoading from '../loaders/fillWithColorLoading/FillWithColorLoading'
import CoffeLoading from '../loaders/CoffeLoading/CoffeLoading'
import Search from '../search/Search'

function Header({ isLoggedIn, toggleLogin, inCartQuantity }) {
  const [unitData, setUnitData] = useState([])
  // const [storedCart, setStoredCart] = useState([])
  // console.log(inCartProducts)

  // const updateTotalQuantity = () => {
  //   const cartItems = JSON.parse(localStorage.getItem('cart')) || []
  //   console.log('Cart Items:', cartItems) // Debugging line
  //   setStoredCart(cartItems)
  // }

  useEffect(() => {
    getUnitDataHeader((response) => {
      setUnitData(response)
    })

    // Initial load
    // updateTotalQuantity()

    // Listen for storage changes (localStorage updates from other components)
    // const handleStorageChange = () => {
    //   updateTotalQuantity()
    // }

    // window.addEventListener('storage', handleStorageChange)

    // return () => {
    //   window.removeEventListener('storage', handleStorageChange)
    // }

    // const cartItems = JSON.parse(localStorage.getItem('cart'))
    // if (cartItems) {
    //   setStoredCart(cartItems)
    // }
  }, [])

  // const totalQuantity = storedCart.reduce((sum, el) => sum + el.quantity, 0)

  return (
    <>
      <HeaderStyled>
        {unitData && Object.keys(unitData).length > 0 ? (
          <>
            <div className='container'>
              <div className='row header'>
                <div className='col-6 identity'>
                  <div className='logo'>
                    <div className='logoImg'>
                      <Link to={'/'}>
                        <img
                          src={unitData.logo}
                          alt={unitData.unit_type + ' ' + unitData.unit_name1}
                          className='logoImg'
                        />
                      </Link>
                    </div>
                    <div className='identityTitles'>
                      <h2>
                        {unitData.unit_type} <span>{unitData.unit_name1}</span>
                      </h2>
                      <span className='logoText'>{unitData.slogan}</span>
                    </div>
                    <BurgerMenu />
                  </div>
                </div>
                <div className='col-6 acountButons'>
                  {unitData.phone1.length < 10 ? (
                    ''
                  ) : (
                    <Link to={`tel:${unitData.phone1}`} className='phone'>
                      &#9742;
                    </Link>
                  )}

                  {!isLoggedIn ? (
                    // <Link to='/login'>&#129333;</Link>
                    <Link to='/login'>
                      {' '}
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='0.88em'
                        height='0.88em'
                        viewBox='0 0 348 512'
                      >
                        <path
                          fill='currentColor'
                          d='M96 128a128 128 0 1 0 256 0a128 128 0 1 0-256 0m94.5 200.2l18.6 31l-33.3 123.9l-36-146.9c-2-8.1-9.8-13.4-17.9-11.3C51.9 342.4 0 405.8 0 481.3c0 17 13.8 30.7 30.7 30.7h386.6c17 0 30.7-13.8 30.7-30.7c0-75.5-51.9-138.9-121.9-156.4c-8.1-2-15.9 3.3-17.9 11.3l-36 146.9l-33.3-123.9l18.6-31c6.4-10.7-1.3-24.2-13.7-24.2h-39.5c-12.4 0-20.1 13.6-13.7 24.2z'
                        ></path>
                      </svg>
                    </Link>
                  ) : (
                    <Link to='/' onClick={toggleLogin}>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='50 -100 1200 1200'
                        width='1.2em'
                        height='1em'
                      >
                        <path
                          fill='currentColor'
                          d='m912 236l276 266l-276 264V589H499V413h413zM746 748l106 107q-156 146-338 146q-217 0-365.5-143.5T0 499q0-135 68-250T251.5 67.5T502 1q184 0 349 148L746 255Q632 151 503 151q-149 0-251.5 104T149 509q0 140 105.5 241T502 851q131 0 244-103'
                        ></path>
                      </svg>
                    </Link>
                  )}

                  <Link to='/cos-de-cumparaturi/' className='cart'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='1em'
                      height='1em'
                      viewBox='0 -2 24 24'
                    >
                      <path
                        fill='currentColor'
                        d='M17 18c-1.11 0-2 .89-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2M1 2v2h2l3.6 7.59l-1.36 2.45c-.15.28-.24.61-.24.96a2 2 0 0 0 2 2h12v-2H7.42a.25.25 0 0 1-.25-.25q0-.075.03-.12L8.1 13h7.45c.75 0 1.41-.42 1.75-1.03l3.58-6.47c.07-.16.12-.33.12-.5a1 1 0 0 0-1-1H5.21l-.94-2M7 18c-1.11 0-2 .89-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2'
                      ></path>
                    </svg>
                    {/* {totalQuantity} */}
                    {inCartQuantity}
                  </Link>
                </div>
                <div className='col-6 userActions'>
                  <div className='row'>
                    <div className='col-12 userMenu'>
                      <ul>
                        {unitData.phone1.length < 10 ? (
                          ''
                        ) : (
                          <li className='phoneNr'>
                            &#9742; {displayPhoneNumber(unitData.phone1)}
                          </li>
                        )}

                        <li>
                          {!isLoggedIn ? (
                            <Link to='/login'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='0.88em'
                                height='0.88em'
                                viewBox='0 0 348 512'
                              >
                                <path
                                  fill='currentColor'
                                  d='M96 128a128 128 0 1 0 256 0a128 128 0 1 0-256 0m94.5 200.2l18.6 31l-33.3 123.9l-36-146.9c-2-8.1-9.8-13.4-17.9-11.3C51.9 342.4 0 405.8 0 481.3c0 17 13.8 30.7 30.7 30.7h386.6c17 0 30.7-13.8 30.7-30.7c0-75.5-51.9-138.9-121.9-156.4c-8.1-2-15.9 3.3-17.9 11.3l-36 146.9l-33.3-123.9l18.6-31c6.4-10.7-1.3-24.2-13.7-24.2h-39.5c-12.4 0-20.1 13.6-13.7 24.2z'
                                ></path>
                              </svg>
                            </Link>
                          ) : (
                            // <Link to='/login'>&#129333;</Link>
                            <Link to='/' onClick={toggleLogin}>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                viewBox='50 -100 1000 1200'
                                width='1.2em'
                                height='1em'
                              >
                                <path
                                  fill='currentColor'
                                  d='m912 236l276 266l-276 264V589H499V413h413zM746 748l106 107q-156 146-338 146q-217 0-365.5-143.5T0 499q0-135 68-250T251.5 67.5T502 1q184 0 349 148L746 255Q632 151 503 151q-149 0-251.5 104T149 509q0 140 105.5 241T502 851q131 0 244-103'
                                ></path>
                              </svg>
                            </Link>
                          )}
                        </li>
                        <li>
                          {/* <Link to='/cos-de-cumparaturi/'>&#128722;</Link> */}
                          <Link to='/cos-de-cumparaturi/' className='cart'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='1em'
                              height='1em'
                              viewBox='0 -2 24 24'
                            >
                              <path
                                fill='currentColor'
                                d='M17 18c-1.11 0-2 .89-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2M1 2v2h2l3.6 7.59l-1.36 2.45c-.15.28-.24.61-.24.96a2 2 0 0 0 2 2h12v-2H7.42a.25.25 0 0 1-.25-.25q0-.075.03-.12L8.1 13h7.45c.75 0 1.41-.42 1.75-1.03l3.58-6.47c.07-.16.12-.33.12-.5a1 1 0 0 0-1-1H5.21l-.94-2M7 18c-1.11 0-2 .89-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2'
                              ></path>
                            </svg>
                            {/* {totalQuantity} */}
                            {inCartQuantity}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Search />
          </>
        ) : (
          // <p>Loading...</p>
          // <FadeLoading />
          <FillWithColorLoading />
          // <CoffeLoading />
        )}
        <Navigation />
      </HeaderStyled>
    </>
  )
}
export default Header
