import styled from 'styled-components'
import {
  desktop,
  tablet,
  mobile,
} from '../../../../styles/setings/mediaScreens'
import { COLORS, popUpFomsColors } from '../../../../styles/setings/colors'

export const ImagesUploaderFormStyled = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${popUpFomsColors.popUpFormContainerBgColor};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
  overflow-y: auto;

  h2 {
    display: flex;
    text-decoration: underline;
    text-decoration-thickness: 4px;
    text-decoration-color: ${COLORS.colorTextUnderlineDefault};
    text-align: center;
    padding: 5px 0;

    ${desktop`
    align-items: flex-end;
    justify-content: flex-start;
    font-size: 1.5rem;
    font-weight: bold;
  `}
    ${tablet`
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 1.5rem;
    font-weight: bold;
  `}
  ${mobile`
      align-items: center;
      justify-content: center;
      width: 100%;
      font-size: 1.5rem;
      font-weight: bold;
  `}
  }
  .divSelect {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    border: dashed ${COLORS.colorBaseFont} 2px;
    border-radius: 15px;
  }
`
export const Container = styled.div`
  padding: 16px;
  background-color: ${popUpFomsColors.popUpFormBackgroundColor};
  border-radius: 8px;
  max-width: 500px;
  margin: auto;
`

export const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
`

export const CancelButton = styled.button`
  /* background-color: transparent;
  border: none;
  color: #888;
  cursor: pointer;
  font-size: 16px; */
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  margin-top: 25px;
  padding: 10px 25px 10px 25px;
  border-radius: 50px;
  border: solid #ccc 2px;
  background-color: #6c6c6c;
  color: #fff;
  cursor: pointer;

  &:hover {
    background-color: #474747;
    border: solid #474747 2px;
  }

  ${mobile`
        margin: 10px 0;
        width: 80%;
      `}
`

export const UploadInfo = styled.div`
  font-size: 1rem;
  /* color: #666; */
  margin: 10px 0;
`

// export const SelectButton = styled.label`
//   width: 400px;
//   display: inline-block;
//   padding: 8px 16px;
//   background-color: #007bff;
//   color: white;
//   border-radius: 4px;
//   cursor: pointer;
//   font-size: 16px;
//   text-align: center;
//   /* margin-top: 16px; */
//   ${mobile`
//         margin: 10px 0;
//         width: 100%;
//       `}
// `

export const SelectButton = styled.label`
  width: 400px;
  display: inline-block;
  padding: 8px 16px;
  background-color: ${({ disabled }) => (disabled ? 'red' : '#007bff')};
  color: white;
  border-radius: 4px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  font-size: 16px;
  text-align: center;
  ${mobile`
        margin: 10px 0;
        width: 100%;
      `}
`

export const PreviewContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 16px;
  padding: 20px 0;
  max-height: 300px;
  overflow-y: auto;
  background-color: ${popUpFomsColors.imageUploadBgColor};

  /* Custom Scrollbar for WebKit browsers */
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }

  /* Custom Scrollbar for Firefox */
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;

  &:hover {
    scrollbar-color: #555 #f1f1f1;
  }
`

export const PreviewItem = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid #ddd;
`

export const PreviewImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const DeleteButton = styled.button`
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: #f00;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
`

export const UploadButton = styled.button`
  padding: 8px 16px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 16px;
  width: 100%;
`

export const ErrorMessage = styled.div`
  color: #d9534f;
  margin-top: 10px;
  font-size: 14px;
`

// import styled from 'styled-components'
// import { desktop, tablet, mobile } from '../../styles/setings/mediaScreens'
// import { COLORS, popUpFomsColors } from '../../styles/setings/colors'

// export const ImagesUploaderStyled = styled.div`
//   position: fixed;
//   width: 100%;
//   height: 100%;
//   top: 0;
//   left: 0;

//   background-color: ${popUpFomsColors.popUpFormContainerBgColor};
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;

//   .formContainer {
//     max-width: 800px;
//     opacity: 1;
//     padding: 15px 10px;
//     border-radius: 15px;
//     box-shadow: 5px 5px 5px ${popUpFomsColors.popUpFormShadowColor};

//     background-color: ${popUpFomsColors.popUpFormBackgroundColor};
//     .infoContainer {
//       display: flex;
//       flex-direction: column;
//       justify-content: center;
//       align-items: center;
//       h2 {
//         display: flex;
//         text-decoration: underline;
//         text-decoration-thickness: 4px;
//         text-decoration-color: ${COLORS.colorTextUnderlineDefault};
//         text-align: center;
//         padding: 5px 0;

//         ${desktop`
//         align-items: flex-end;
//         justify-content: flex-start;
//         font-size: 1.5rem;
//         font-weight: bold;
//       `}
//         ${tablet`
//         align-items: center;
//         justify-content: center;
//         width: 100%;
//         font-size: 1.5rem;
//         font-weight: bold;
//       `}
//       ${mobile`
//           align-items: center;
//           justify-content: center;
//           width: 100%;
//           font-size: 1.5rem;
//           font-weight: bold;
//       `}
//       }

//       .cancelButtonContainer {
//         margin-bottom: 25px;
//         width: 100%;
//         display: flex;
//         flex-direction: row;
//         justify-content: center;
//         align-items: center;
//         padding: 5px 0 5px 0;

//         .closeButton {
//           width: 600px;
//           display: flex;
//           justify-content: center;
//           align-items: center;
//           font-size: 1.5em;
//           font-weight: bold;
//           text-align: center;
//           text-transform: uppercase;
//           margin-top: 25px;
//           padding: 10px 25px 10px 25px;
//           border-radius: 50px;
//           border: solid #ccc 2px;
//           background-color: #6c6c6c;
//           color: #fff;
//           cursor: pointer;

//           &:hover {
//             background-color: #474747;
//             border: solid #474747 2px;
//           }

//           ${mobile`
//           margin: 10px 0;
//           width: 80%;
//       `}
//         }
//       }
//       .uploadInfo {
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         flex-direction: column;
//         text-align: center;

//         span {
//           min-width: 300px;
//         }
//       }
//     }
//     .imageUploadFormContainer {
//       display: flex;
//       justify-content: center;
//       align-items: center;

//       ${desktop`
//         // flex-direction: column;
//         `}
//       ${tablet`
//         // flex-direction: column;
//         `}
//         ${mobile`
//         flex-direction: column;
//         `}

//     div {
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         flex-direction: column;
//       }
//       .previewContainer {
//         display: flex;
//         flex-direction: column;
//         align-items: center;
//         background-color: #5c1914;
//         width: 220px;
//         height: 220px;
//         margin: 10px;
//       }
//       .imagePreview {
//         width: 100%;
//         max-height: 90px;
//         object-fit: contain;
//         margin: 10px 0;
//       }

//       input {
//         padding: 10px 15px;
//         border: transparent;
//         margin: 10px;
//         background-color: #fff;
//         font-size: 1.2em;
//         font-weight: bold;
//       }

//       .previewText {
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         font-size: 1.2em;
//         font-weight: bold;
//         background-color: #b93329;
//         color: #fff;
//         width: 100%;
//         padding: 10px 20px;
//         margin-bottom: 10px;
//       }
//       .selectButton {
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         color: #fff;
//         border: transparent;
//         margin: 10px;
//         border-radius: 5px;
//         font-size: 1.2em;
//         font-weight: bold;
//         width: 200px;
//         padding: 10px 20px;
//         background-color: #235425;
//         cursor: pointer;
//         transition: background-color 0.4s;
//         &:hover {
//           background-color: #4caf50;
//         }
//       }

//       .uploadButton {
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         color: #fff;
//         border: transparent;
//         margin: 10px;
//         border-radius: 5px;
//         font-size: 1.2em;
//         font-weight: bold;
//         width: 220px;
//         height: 220px;
//         padding: 10px 20px;
//         background-color: #235425;
//         cursor: pointer;
//         transition: background-color 0.4s;
//         ${mobile`
//       height: auto;
//     `}
//         &:hover {
//           background-color: #4caf50;
//         }
//       }

//       .deleteButton {
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         color: #fff;
//         border: transparent;
//         margin: 10px;
//         border-radius: 5px;
//         font-size: 1.2em;
//         font-weight: bold;
//         width: 200px;
//         padding: 10px 20px;
//         background-color: #a12820;
//         border: solid #f44336 2px;
//         cursor: pointer;
//         transition: background-color 0.4s;
//         &:hover {
//           background-color: #f44336;
//         }
//       }
//       .replaceButton {
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         color: #fff;
//         border: transparent;
//         margin: 10px;
//         border-radius: 5px;
//         font-size: 1.2em;
//         font-weight: bold;
//         width: 220px;
//         height: 220px;
//         padding: 10px 20px;
//         background-color: #0c3f68;
//         cursor: pointer;
//         transition: background-color 0.4s;
//         ${mobile`
//       height: auto;
//     `}
//         &:hover {
//           background-color: #2196f3;
//         }
//       }
//       .hiddenInput {
//         display: none;
//       }

//       .error {
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         font-size: 1.2em;
//         font-weight: bold;
//         background-color: #b93329;
//         color: #fff;
//         width: 100%;
//         padding: 10px 20px;
//         margin-bottom: 10px;
//       }
//     }
//   }
// `
