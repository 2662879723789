import React, { useEffect, useState } from 'react'
import { PageDeliveryPricesStyled } from './PageDeliveryPricesStyled'
import { getDeliveryPrices } from '../../../../api/unitDataAPI'

function PageDeliveryPrices() {
  const [deliveryPrices, setDeliveryPrices] = useState([])

  useEffect(() => {
    getDeliveryPrices((response) => {
      setDeliveryPrices(response)
    })
  }, [])

  return (
    <>
      {deliveryPrices.length > 0 ? (
        <PageDeliveryPricesStyled id='deliveryPrices'>
          {deliveryPrices.map((el, index) => {
            return (
              <li key={index}>
                {el.distance1}
                {el.distance2 !== null ? ' - ' + el.distance2 : ''} {el.um}
                {': '} {el.price} {el.currency_short}
              </li>
            )
          })}
        </PageDeliveryPricesStyled>
      ) : (
        ''
      )}
    </>
  )
}
export default PageDeliveryPrices
