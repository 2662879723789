import React from 'react'
import Header from '../../components/common/header/Header'
import Aside from './a-side/Aside'
import Footer from '../../components/common/footer/Footer'
import { RestMenuLayoutStyled } from './RestMenuLayoutStyled'
import CategoriesMenu from '../../views/shop/categoriesMenu/CategoriesMenu'
import { Outlet } from 'react-router-dom'
import Content from '../content/Content'

function RestMenuLayout({ isLoggedIn, toggleLogin, inCartQuantity }) {
  return (
    <>
      <Header
        isLoggedIn={isLoggedIn}
        toggleLogin={toggleLogin}
        inCartQuantity={inCartQuantity}
      />
      {/* <RestMenuLayoutStyled>
        <div className='container'>
          <div className='row mainContent'>
            <div className='col-4 categorys'><Aside /></div>
            <CategoriesMenu />
            <div className='col-8 content'>
              <Outlet />
            </div>
          </div>
        </div>
      </RestMenuLayoutStyled> */}

      <RestMenuLayoutStyled>
        <div className='container'>
          <div className='row mainContent'>
            {/* <div className='col-4 categorys'>
              <Aside />
            </div> */}
            <CategoriesMenu />
            <div className='col-12 content'>
              <Content />
              {/* <Outlet /> */}
            </div>
          </div>
        </div>
      </RestMenuLayoutStyled>
      <Footer />
    </>
  )
}

export default RestMenuLayout
