import styled from 'styled-components'
import { desktop, tablet, mobile } from '../../../styles/setings/mediaScreens'

const colors = {
  bgColor: '#020403',
  borderColor: '#fff1e6',
  activeBorderColor: '#e16d07',
  fontColor: '#fff1e6',
  fontHoverColor: '#fff1e6',
  scrollbarColor: '#121212',
  scrollbarTrack: '#121212',
  scrollbarThumb: '#474747',
  scrollbarThumbHover: '#121212',
  itemBgColor: '#2b1e1e',
  itemFontColor: '#fff1e6',
  itemFontHoverColor: '#a2a2a2',
  itemFontShadowColor: '#00000080',
  itemHoverBgColor: '#0a0606',
  itemBorderColor: '#a2a2a2',
  itemBorderHoverColor: '#fff1e6',
  itemActiveBgColor: '#0a0606',
  itemActiveBorderColor: '#e16d07',
  scrollButtonBgColor: '#666',
  scrollButtonHoverBgColor: '#444',
  scrollButtonFontColor: '#fff1e6',
}

export const HorizontalScrollingMenuStyled = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: ${colors.bgColor};
  /* height: 70px; */

  .scrolling-menu-with-scrollBar {
    display: flex;
    overflow-x: scroll;
    scroll-behavior: smooth;
    gap: 15px;
    padding: 10px 0px; /* Ensures spacing around items */
    margin: 0 75px;
    scrollbar-width: thin; /* Custom scrollbar for Firefox */
    scrollbar-color: ${colors.scrollbarColor}; /* Thumb and track colors */

    &::-webkit-scrollbar {
      height: 3px; /* Horizontal scrollbar height */
    }

    &::-webkit-scrollbar-track {
      background: ${colors.scrollbarTrack}; /* Track background */
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${colors.scrollbarThumb}; /* Thumb color */
      border-radius: 3px;
    }

    &:hover::-webkit-scrollbar-thumb {
      background-color: ${colors.scrollbarThumbHover}; /* Darker thumb on hover */
    }
  }

  .scrolling-menu {
    display: flex;
    /* overflow-x: scroll auto; */
    overflow-x: auto;
    scroll-behavior: smooth;
    gap: 15px;
    padding: 10px 0 0 0; /* Ensures spacing around items */
    margin: 10px 15px;
    width: 100%;
    /* height: 90px; */

    /* Hide scrollbar for all browsers */
    /* scrollbar-width: none; */
    /* Firefox */
    /* -ms-overflow-style: none;  */
    /* Internet Explorer 10+ */

    /* &::-webkit-scrollbar { */
    /* display: none; */
    /* Chrome, Safari, and Edge */
    /* } */

    &::-webkit-scrollbar {
      background-color: ${colors.scrollbarColor};
      height: 8px;
      width: 8px;
      display: none;
    }
    &::-moz-scrollbar {
      background-color: ${colors.scrollbarColor};
      height: 8px;
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background-color: ${colors.scrollbarTrack};
    }
    &::-moz-scrollbar-track {
      background-color: ${colors.scrollbarTrack};
    }
    &::-webkit-scrollbar-thumb {
      background: ${colors.scrollbarThumb};
      width: 30px;
      border-radius: 10px; /* Optional: Make the thumb rounded */
      height: 30px; /* Max height for horizontal scrollbar thumb */
      width: 30px; /* Max width for vertical scrollbar thumb */
    }
    &::-moz-scrollbar-thumb {
      background: ${colors.scrollbarThumb};
      border-radius: 10px; /* Optional: Make the thumb rounded */
      height: 30px; /* Max height for horizontal scrollbar thumb */
      width: 30px; /* Max width for vertical scrollbar thumb */
    }
    &::-webkit-scrollbar-thumb:hover {
      background: ${colors.scrollbarThumbHover};
    }
    &::-moz-scrollbar-thumb:hover {
      background: ${colors.scrollbarThumbHover};
    }
    &::-webkit-scrollbar-button {
      display: none;
    }
    &::-moz-scrollbar {
    }
  }

  .menu-item {
    position: relative;
    flex: 0 0 auto;
    min-width: 120px;
    height: 50px;
    background-color: ${colors.itemBgColor};
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${colors.itemBorderColor};
    border-radius: 4px;
    overflow: hidden;
    transition: background-color 0.3s ease, transform 0.2s ease;
    padding: 0 15px;
    ${mobile`
        height: 25px; 
        // border: none;
        // border-radius: 0;
        background-color: ${colors.itemBgColor};
      `}

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0.5;
      position: absolute;
      ${mobile`
      display: none;
      `}
    }

    .itemName {
      /* position: absolute; */
      /* top: 50%;
    left: 50%; */
      /* transform: translate(-50%, -50%); */
      text-align: center;
      z-index: 1;

      h2 {
        font-size: 0.9rem;
        font-weight: bold;
        color: ${colors.itemFontColor};
        letter-spacing: 2px;
        text-shadow: 1px 1px 2px ${colors.itemFontShadowColor};
        /* &:hover {
          color: ${colors.itemFontHoverColor};
        } */
      }
    }

    &:hover {
      background-color: ${colors.itemHoverBgColor};
      border: 1px solid ${colors.itemBorderHoverColor};
      /* transform: scale(1.05); Slight zoom effect */
      img {
        opacity: 0.2; /* Changes the h2 color on hover of the second .menu-item */
      }
      .itemName h2 {
        color: ${colors.itemFontHoverColor}; /* Changes the h2 color on hover of the second .menu-item */
      }
    }
  }

  .menu-item.active {
    background-color: ${colors.itemActiveBgColor};
    border: 2px solid ${colors.itemActiveBorderColor};
    /* border-radius: 8px; */
  }

  .scroll-button {
    position: absolute;
    background-color: ${colors.scrollButtonBgColor};
    color: ${colors.scrollButtonFontColor};
    border: none;
    width: 30px;
    height: 50px;
    top: 50%;
    cursor: pointer;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    transform: translateY(-50%);
    display: none;
  }

  .scroll-button.left {
    left: 10px;
  }

  .scroll-button.right {
    right: 0;
  }

  .scroll-button:hover {
    background-color: ${colors.scrollButtonHoverBgColor};
  }

  .scroll-button.left {
    left: 10px;
  }

  .scroll-button.right {
    right: 10px;
  }
`
