import styled from 'styled-components'
import { mobile, tablet, desktop } from '../../../styles/setings/mediaScreens'
import {
  COLORS,
  addUserColors,
  formColors,
} from '../../../styles/setings/colors'

export const CustomerListStyled = styled.div`
  // margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin: 30px auto; */
  /* padding-bottom: 20px; */
  // padding: 20px 0 40px 0;
  background-color: ${COLORS.colorAboutSectionBg};
  /* background-color: grey; */
  ${desktop`
    margin-top: 20px;
  `}
  ${tablet`
    margin-top: 20px;
  `}
  ${mobile`
    margin-top: 10px;
  `}

    .optionsview {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 1.1em;
    color: #fff;
    width: 100%;
    max-width: 1300px;
    ${mobile`
      flex-direction: column;
      justify-content: none;
      align-items: center;
    `}
    .pagesView {
      /* display: flex; */
      flex-direction: row;
      button {
        background-color: transparent;
        border: none;
        margin: 0 5px;
        font-size: 1.1em;
        color: #fff;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .orderByView {
      /* display: flex; */
      flex-direction: row;
      button {
        background-color: transparent;
        border: none;
        margin: 0 5px;
        font-size: 1.1em;
        color: #fff;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .title {
    margin: 15px 0;

    h2 {
      display: inline-flex;
      text-decoration: underline;
      text-decoration-thickness: 4px;
      text-decoration-color: ${COLORS.colorTextUnderlineDefault};

      ${desktop`
        align-items: center;
        justify-content: center;    
        width: 100%;
        font-size: 1.5em;
      `}
      ${tablet`
        align-items: center;
        justify-content: center;    
        width: 100%;
        font-size: 1.5em;
      `}
    ${mobile`
        align-items: center;
        justify-content: center;    
        width: 100%;
      font-size: 1.3em;
    `}
    }
  }

  .container .row {
    justify-content: center;
    .article {
      height: auto;
      justify-self: center;
      align-items: center;
      padding: 10px;
      margin: 0px 0px 10px 10px;
      background-color: #34345c;
      border: solid #61618d 2px;
      border-radius: 10px;
      ${desktop` 
        // width: calc(33% - 15px);
        width: 300px;
        `}

      ${tablet`
        // width: calc(33% - 15px);
        width: 300px;
        `}

      ${mobile` 
        // width: calc(100% - 20px);
        width: calc(100% - 20px);
        margin: 5px 0;
      `}
    }
  }

  .readMeList {
    width: 100%;
    max-width: 1300px;
    margin: 10px 0;
    padding: 10px;
    background-color: #2b2b4b;
    .infoTitle {
      margin: 5px 0;
      h4 {
        font-size: 1.2rem;
        font-weight: bold;
      }
    }
    li {
      margin: 5px 0;
      line-height: 1.2;
    }
    span {
      font-size: 0.9rem;
      font-weight: bold;
      text-decoration: underline;
      text-decoration-thickness: 2px;
    }
    .listButtons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 150px;
      margin-top: 10px;
    }
  }

  .divElement {
    /* text-align: center; */
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: center; */
    /* align-items: flex-start; */
    /* text-align: left; */

    ${desktop`
        font-size: 1.2em;
        // text-align: left;
        `}
    ${tablet`
          font-size: 1.2em;
          // text-align: left;
        `}
        ${mobile`
          font-size: 1.2em;
          flex-direction: column;
          justify-content: space-between;
          text-align: center;
        `}
  }

  .elementData {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: grey;
    padding: 3px 0;
    height: 43px;
    img {
      width: 35px;
      margin-right: 5px;
    }
  }

  .actionButtons {
    display: inline-flex;
    align-items: center;
    width: 100%;
    margin: 5px 0 10px 0;
    padding: 10px 5px;
    background-color: #20203a;
    border-radius: 10px;

    ${tablet`

    `}
    ${mobile`
        flex-direction: row;

    `} div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 5px;
    }
  }

  .tumbnailImg {
    width: 100%;
    display: flex;
    justify-content: center;

    .coverImg {
      width: 85%;
      max-height: 150px;
      border-radius: 15px;
      margin: 10px 0;
    }
  }

  .details {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 1em;
    margin: 5px 0 2px 0;
    padding-left: 5px;
    /* background-color: grey; */
    h3 {
      font-size: 0.9rem;
      font-weight: bold;
      color: #fff;
    }

    /* ${mobile`
      width: 100%;
      `} */
  }

  .customerName {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.8em;
    margin: 5px 0;
    background-color: grey;
    h2 {
      font-size: 1em;
      font-weight: bold;
      color: #fff;
    }

    /* ${mobile`
      width: 100%;
      `} */
  }

  .username {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: auto;
    color: white;
    font-size: 1em;
    font-weight: bold;

    ${mobile`
          justify-content: center;
          padding: 10px 0;
          `}
  }

  .addNewButtonContainer {
    margin-bottom: 25px;
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 0 15px 0;
    border-radius: 15px;
    /* border-bottom: solid #fff 3px; */
    /* background-color: #420000; */
    color: #fff;
    ${mobile`
         padding: 15px 0 10px 0;
      `}
  }
`

export const UserTableContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #0f0e0e;
  padding: 15px 0;
  color: white;
  margin-top: 0px;
  h2 {
    font-size: 2em;
    text-decoration: underline;
    text-decoration-thickness: 4px;
    text-decoration-color: ${addUserColors.textDecorationrColor};
  }

  .optionsview {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 1.1em;
    color: #fff;
    width: 100%;
    max-width: 1300px;
    ${mobile`
      flex-direction: column;
      justify-content: none;
      align-items: center;
    `}
    .pagesView {
      /* display: flex; */
      flex-direction: row;
      button {
        background-color: transparent;
        border: none;
        margin: 0 5px;
        font-size: 1.1em;
        color: #fff;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .orderByView {
      /* display: flex; */
      flex-direction: row;
      margin-bottom: 15px;
      button {
        background-color: transparent;
        border: none;
        margin: 0 5px;
        font-size: 1.1em;
        color: #fff;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
`
// export const UserTableDesign = styled.table`
//   width: calc(100% - 30px);
//   border: solid rgb(255, 204, 2) 1px;
//   tr {
//     ${mobile`
//     display: flex;
//     flex-wrap:wrap;

//     `}
//   }
//   th {
//     background-color: white;
//   }
//   td {
//     width: calc(100% / 7);
//     /* border: solid #ff0000 1px; */
//     color: white;
//     text-align: center;
//   }
// `;

export const UserTableHeadRow = styled.div`
  width: calc(100% - 30px);
  border: solid #2c2828 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 5px 0;
  font-weight: bold;
  /* background-color: #1d1d1d; */
  ${tablet`
 display: none;
  `}
`
export const UserTableRow = styled.div`
  width: calc(100% - 30px);
  /* border: solid rgb(255, 204, 2) 1px; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 5px 0;
  /* background-color: #1d1d1d; */

  &:nth-child(2n) {
    background-color: #2c2828;
  }
  ${tablet`
 flex-direction: column;
 margin: 5px 0;
  `}
`

export const UserTableCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% / 6);
  font-size: 1em;
  color: #fff;
  padding: 5px 0;

  ${tablet`
  width: calc(50% - 15px);
  justify-content: left;
   border: solid #242424 1px; 
  font-size: 1em;
  background-color: #2c2828;
    &:nth-child(2n) {
    background-color: #000;
  }
  `};
`
export const UserTableLabel = styled.span`
  display: none;
  font-size: 1em;
  color: #fff;
  text-decoration: underline;

  ${tablet`
display: block;
margin-right: 15px

  `};
`

export const AddUserButtonContainer = styled.div`
  width: calc(100% - 30px);
  border: solid #2c2828 1px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  padding: 5px 0;
  font-weight: bold;
  /* background-color: #1d1d1d; */
  ${tablet`
//  display: none;
  `}
`
export const AddUsersButton = styled.button`
  border-radius: 0.2em;
  outline: none;
  border: none;
  margin: 1em 0.2em;
  /* background-color: ${addUserColors.butonColor}; */
  background-color: #7b7b7b;
  font-size: 1em;
  font-weight: bold;
  width: 150px;
  padding: 0.1em 0.5em;
  border: solid transparent 1px;
  ${tablet`
  width: calc(100% - 15px);
    margin: 1em 2em;

  `};
  &:hover {
    background-color: ${addUserColors.butonHoverColor};
    border: solid ${addUserColors.borderColor} 1px;
    color: ${addUserColors.textHoverColor};
  }
`

export const UsersTableModifyButton = styled.button`
  border-radius: 0.2em;
  outline: none;
  border: none;
  margin: 1em 0.2em;
  /* background-color: ${addUserColors.butonColor}; */
  background-color: #7b7b7b;
  font-size: 1em;
  font-weight: bold;
  width: 90px;
  padding: 0.1em 0.5em;
  border: solid transparent 1px;
  ${tablet`
  // display: none;
  width: calc(100% - 15px);
    margin: 1em 2em;

  `};
  &:hover {
    background-color: ${addUserColors.butonHoverColor};
    border: solid ${addUserColors.borderColor} 1px;
    color: ${addUserColors.textHoverColor};
  }
`
export const UsersTableDeleteButton = styled.button`
  border-radius: 0.2em;
  outline: none;
  border: none;
  margin: 1em 0.5em;
  background-color: ${addUserColors.butonColor};
  font-size: 1em;
  font-weight: bold;
  width: 100px;
  padding: 0.1em 0.2em;
  border: solid transparent 1px;
  ${tablet`
  width: calc(100% - 15px);
    margin: 1em 2em;

  `};
  &:hover {
    background-color: ${addUserColors.butonHoverColor};
    border: solid ${addUserColors.borderColor} 1px;
    color: ${addUserColors.textHoverColor};
  }
`
