import React, { useEffect, useState } from 'react'
import {
  // getProducts,
  getAllProducts,
  getAllPaginatedProducts,
} from '../../../api/shopAPI'
import { useNavigate } from 'react-router'
// import { ProductsStyled } from './ProductsStyled'
// import { ProductsCardsStyled } from '../../styles/elements/products/ProductsCardsStyled'
import { ProductsListStyled } from '../../../styles/elements/products/ProductsListStyled'
// import AddToCartLocalStorage from '../../components/addToCartLocalStorage/AddToCartLocalStorage'
import AddToCartLocalStorageWithOptions from '../../../components/common/addToCartLocalStorage/AddToCartLocalStorageWithOptions'
import { getCurrentTime } from '../../../functions/formatDate'
import capitalizeFirstLetter from '../../../functions/capinalizeFirstLetter'
import toUpperCase from '../../../functions/toUpperCase'
import { messages } from '../../../language/romanian/messages'
import Pagination from '../../../components/common/pagination/Pagination'
import { NoDataStyled } from '../../../styles/elements/NoDataStyled'
import RedirectHome from '../../../components/common/redirectHome/RedirectHome'

function Products({ inCartProducts, dayOpen }) {
  // const [products, setProducts] = useState([])
  const [products, setProducts] = useState({ products: [] })
  const addToCartStyle = 'multipleProducts'
  const navigate = useNavigate()
  // const isOpen = dayOpen.length > 0 ? dayOpen[0].open : null
  const [currentPage, setCurrentPage] = useState(1)
  const [orderBy, setOrderBy] = useState(1)
  const [fetchAllItems, setFetchAllItems] = useState(false) // Track whether to fetch all orders or paginated orders

  // useEffect(() => {
  //   getProducts((response) => {
  //     setProducts(response)
  //   })
  // }, [])

  // -----------------

  useEffect(() => {
    getAllPaginatedProducts(currentPage, orderBy, (response) => {
      setProducts(response)
      fetchItems()
    })
  }, [currentPage, fetchAllItems, orderBy])

  const fetchItems = () => {
    if (fetchAllItems) {
      // Fetch all products (unpaginated)
      getAllProducts(
        orderBy,
        (response) => setProducts(response),
        (error) => console.error('Failed to retrieve all products:', error)
      )
    } else {
      // Fetch paginated products
      getAllPaginatedProducts(
        currentPage,
        orderBy,
        (response) => setProducts(response),
        (error) =>
          console.error('Failed to retrieve paginated products:', error)
      )
    }
  }

  // Function to toggle between fetching all orders and paginated orders
  const toggleFetchAllItems = () => {
    setFetchAllItems((prev) => !prev) // Toggle the fetchAllItems state
    setCurrentPage(1) // Reset to the first page when switching modes
  }

  // Function to handle pagination page changes
  const handlePageChange = (page) => {
    setCurrentPage(page) // Update the current page state
  }

  // -----------------

  // console.log(isOpen)
  // console.log(products.products)

  return (
    <>
      {products.products ? (
        <ProductsListStyled id='top'>
          <div className='catTitle'>
            <h2>Produse din meniu</h2>
          </div>
          <div className='optionsview'>
            {/* <div>{products.total} de produse.</div> */}
            <div className='pagesView'>
              {/* Button to toggle between "All" and "Paginated" views */}
              Vezi{' '}
              <button onClick={toggleFetchAllItems}>
                {fetchAllItems ? '10 produse / pag' : 'Toate produsele'}{' '}
                {/* Change button text based on the state */}
              </button>
            </div>

            <div className='orderByView'>
              Ordonare dupa
              <select
                name='orderBy'
                id='orderBy'
                className='orderBySelect'
                value={orderBy}
                onChange={(e) => setOrderBy(Number(e.target.value))}
              >
                <option value=''>Standard</option>
                <option value='1'>Cod Produs</option>
                <option value='2'>Nume Produs</option>
                <option value='3'>Pret</option>
              </select>
            </div>
          </div>
          {!fetchAllItems && products.last_page > 1 && (
            <Pagination
              currentPage={products.current_page}
              totalPages={products.last_page}
              onPageChange={handlePageChange}
            />
          )}
          <div className='listContainer'>
            {products.products.map((el, index) => (
              <div className='productContainer' key={index}>
                {/* <a
              href='#'
              onClick={(e) => {
                e.preventDefault()
                navigate(`/produs/${el.product_link}`)
              }}
            > */}
                <div
                  className='productDataContainer'
                  onClick={(e) => {
                    e.preventDefault()
                    navigate(`/produs/${el.product_link}`)
                  }}
                >
                  <div className='productImage'>
                    {el.cover_image !== null ? (
                      <img
                        src={el.cover_image.image_link}
                        alt={el.name}
                        className='coverImg'
                      />
                    ) : (
                      <img
                        src={
                          'https://www.aroma-pasiunii.intorsura.ro/media/aroma-pasiunii-no-photo.png'
                        }
                        alt={el.name}
                        className='coverImg'
                      />
                    )}
                  </div>
                  <div className='productData'>
                    <div className='productName'>
                      <h2>
                        {toUpperCase(el.name)} {el.weight}
                        {el.weight_classes.name_short}
                      </h2>
                    </div>
                    <div className='productDetails'>
                      {capitalizeFirstLetter(el.ingredients)}
                    </div>
                    <div className='price'>
                      {el.price}
                      <span className='currency'>
                        {el.currency.currency_short}
                      </span>
                    </div>
                    {/* </a> */}
                    {/* {el.frozen_products === 1 ? (
                        <div className='frozenProduct'>
                          Contine produse congelate
                        </div>
                      ) : (
                        <div className='frozenProduct'></div>
                      )} */}

                    {/* {getCurrentTime() > el.product_category.delivery_from_time &&
              getCurrentTime() < el.product_category.delivery_to_time ? (
                <div className='addToCart'>
                  <AddToCartLocalStorage
                    index={index}
                    id={el.id}
                    eanCode={el.ean_code}
                    productName={el.name}
                    addToCartStyle={addToCartStyle}
                    inCartProducts={inCartProducts}
                  />
                </div>
              ) : (
                <div className='noDeliveryTime'>
                  <span>Puteti comanda intre orele:</span>
                  <span>
                    {el.product_category.delivery_from_time} -{' '}
                    {el.product_category.delivery_to_time}
                  </span>
                </div>
              )} */}
                    {/* <div className='addToCart'>
                <AddToCartLocalStorage
                  index={index}
                  id={el.id}
                  eanCode={el.ean_code}
                  productName={el.name}
                  addToCartStyle={addToCartStyle}
                  inCartProducts={inCartProducts}
                />
              </div> */}
                  </div>
                </div>
                <AddToCartLocalStorageWithOptions
                  id={el.id}
                  eanCode={el.ean_code}
                  productName={el.name}
                  productWeight={el.weight + el.weight_classes.name_short}
                  productPrice={parseFloat(el.price)}
                  productCurrency={el.currency.currency_short}
                  productCoverImage={
                    el.cover_image !== null ? el.cover_image.image_link : ''
                  }
                  addToCartStyle='singleProduct'
                  inCartProducts={inCartProducts}
                  productOptions={[]}
                />
              </div>
            ))}
          </div>
          {!fetchAllItems && products.last_page > 1 && (
            <Pagination
              currentPage={products.current_page}
              totalPages={products.last_page}
              onPageChange={handlePageChange}
            />
          )}
        </ProductsListStyled>
      ) : (
        <>
          <NoDataStyled>
            <div className='noData'>
              <h1>Nu am gasit produse!</h1>
            </div>
            {/* <RedirectHome toPage={'meniu'} /> */}
            <RedirectHome toPage={''} />
          </NoDataStyled>
        </>
      )}
    </>
  )
}

export default Products
