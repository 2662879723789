import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const RedirectHome = ({ toPage }) => {
  const navigate = useNavigate()

  useEffect(() => {
    const timer = setTimeout(() => {
      toPage ? navigate('/' + toPage) : navigate('/')
      // navigate('/' + toPage) // Redirect to the home page
    }, 3000)

    return () => clearTimeout(timer) // Cleanup the timeout
  }, [navigate])

  return null // No UI is needed
}

export default RedirectHome

// usage example
// <RedirectHome toPage={'meniu'} /> for redirect to a specific page
{
  /* <RedirectHome /> //for redirect to home */
}
