import React, { useEffect, useState } from 'react'
import { OrderdProductsStyled } from './OrderdProductsStyled'
import { getCustomerOderedProducts } from '../../../../api/orderedProducts'
import FadeLoading from '../../../../components/common/loaders/fadeLoading/FadeLoading'
import { PopUpLevel2Styled } from '../../../../components/common/popUpWindows/PopUpLevel2Styled'
import { getCustomerByID } from '../../../../api/customers'
import { getSCustomerById } from '../../../../api/unitDataAPI'

function OrderdProducts({ cancel, orderId, totalOrderPrice, currency, elID }) {
  const [customerData, setCustomerData] = useState({})
  const [productList, setProductList] = useState([])
  const [isOpen, setIsOpen] = useState(false)

  const formattedPrice = Number(totalOrderPrice).toFixed(2)

  useEffect(() => {
    getSCustomerById(elID, (response) => {
      setCustomerData(response)
    })
    // const customerData = JSON.parse(localStorage.getItem('userData'))
    // if (customerData) {
    //   setCustomerData(customerData)
    // }
  }, [])

  useEffect(() => {
    const storedCustomerData = JSON.parse(localStorage.getItem('userData'))
    if (customerData) {
      // setCustomerData(storedCustomerData)
      getCustomerOderedProducts(
        orderId,
        (response) => {
          setProductList(response)
          setIsOpen(true) // Show component after data is loaded
          document.body.style.overflow = 'hidden' // Disable body scroll
        },
        (error) => {
          console.error('Nu am reusit sa incarc produsele:', error)
        }
      )
    }

    return () => {
      setIsOpen(false) // Hide component when unmounting
      document.body.style.overflow = '' // Re-enable body scroll when component is unmounted
    }
  }, [orderId])

  return (
    <PopUpLevel2Styled>
      <div className='popUpLevel2Container'>
        <OrderdProductsStyled className={isOpen ? 'isOpen' : ''}>
          <div className='cancelButton'>
            <button
              className='closeOrder'
              onClick={() => {
                setIsOpen(false)
                setTimeout(cancel, 300) // Delay cancel to allow fade-out
                document.body.style.overflow = '' // Re-enable body scroll
              }}
            >
              Inchide
            </button>
          </div>
          <div className='col-12'>
            <h2>Produsele din comanda #{orderId}</h2>
          </div>
          <div className='totalOrderPrice'>
            Valoare totala: {formattedPrice} {currency}
          </div>
          {productList.length > 0 ? (
            <div className='productsContainer' id='orders'>
              {productList.map((el, index) => (
                <React.Fragment key={index}>
                  <div className='orderProductsList'>
                    <div className='productImage'>
                      <img
                        src={
                          el.product.cover_image
                            ? el.product.cover_image.image_link
                            : 'https://www.aroma-pasiunii.intorsura.ro/media/icons/pizza.png'
                        }
                        alt='Aroma Pasiunii'
                      />
                    </div>
                    <div className='productData'>
                      <div className='productName'>
                        <h3>{el.product.name}</h3>
                      </div>
                      <div className='productDetails'>
                        <span>Portie:</span> {el.product.weight}{' '}
                        {el.product.units}
                      </div>
                      <div className='productDetails'>
                        <span>Pret:</span> {el.product.price} {el.currency}
                      </div>
                      <div className='productDetails'>
                        <span>Cantitate comandata:</span>
                        <span className='quantity'>{el.quantity}</span>
                      </div>
                    </div>
                    <div className='orderPrice'>
                      <span>Subtotal: </span>
                      <h3>
                        {(el.quantity * el.product.price).toFixed(2)}
                        <span> {el.currency}</span>
                      </h3>
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
          ) : (
            <h2>Nu exista produse in comanda!</h2>
          )}
        </OrderdProductsStyled>
      </div>
    </PopUpLevel2Styled>
  )
}

export default OrderdProducts
