import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { getProducts, getProductsByEanCodes } from '../../../api/shopAPI'
import { CartStyled } from './CartStyled'
import CheckOutForm from '../checkOutForm/CheckOutForm'
import { messages } from '../../../language/romanian/messages'
import { getCurrentTime } from '../../../functions/formatDate'
import toUpperCase from '../../../functions/toUpperCase'
import RedirectHome from '../../../components/common/redirectHome/RedirectHome'

function CartWithProductOptions({ cart, inCartProducts, isLoggedIn }) {
  const [prodInCart, setProdInCart] = useState([])
  const [orderTotal, setOrderTotal] = useState(0)
  const [currency, setCurrency] = useState('')
  const [showCheckOutForm, setShowCheckOutForm] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem('cart')) || []

    if (storedCart.length > 0) {
      const eanCodes = storedCart.map((item) => item.ean_code)
      // console.log('Fetching products for EAN codes:', eanCodes)

      getProductsByEanCodes(eanCodes, (response) => {
        // Map over storedCart to attach productCoverImage from the response
        const productsWithImages = storedCart.map((product) => {
          const productData = response.find(
            (resItem) => resItem.ean_code === product.ean_code
          )

          // Get the first image or set a default value if not available
          // const productCoverImage = productData?.images?.[0] || null
          const productCoverImage =
            productData?.cover_image !== null
              ? productData?.cover_image.image_link
              : null

          const productLink =
            productData?.product_link !== null
              ? productData?.product_link
              : null

          return { ...product, productCoverImage, productLink }
        })

        const productsWithSubtotals = productsWithImages.map((product) => {
          const optionsPrice =
            product.productOptions?.reduce(
              (sum, option) => sum + option.price,
              0
            ) || 0

          // const subtotal =
          //   (product.productPrice + optionsPrice) * product.quantity

          const subtotal =
            (parseFloat(product.productPrice) + parseFloat(optionsPrice)) *
            product.quantity

          return { ...product, subtotal } // Add subtotal to each product
        })

        setProdInCart(productsWithSubtotals)
        calculateTotal(productsWithSubtotals)
      })
    }
  }, [cart])

  const calculateTotal = (productsInCart) => {
    const total = productsInCart.reduce(
      (sum, product) => sum + product.subtotal,
      0
    )

    // setOrderTotal(total)

    setOrderTotal(parseFloat(total.toFixed(2))) // Ensure two decimal places

    // Ensure the currency is consistent across the cart
    if (productsInCart.length > 0) {
      setCurrency(productsInCart[0].productCurrency)
    }
  }

  const updateQuantity = (productId, newQuantity) => {
    // Ensure newQuantity is at least 1
    const adjustedQuantity = newQuantity < 1 ? 1 : newQuantity

    // Update the quantity and recalculate the subtotal for the affected product
    const updatedCart = prodInCart.map((item) => {
      if (item.tempId === productId) {
        const optionsPrice = item.productOptions?.reduce(
          (sum, option) => sum + option.price,
          0
        ) // Sum of all product options' prices

        const subtotal = (item.productPrice + optionsPrice) * adjustedQuantity // Calculate new subtotal

        return { ...item, quantity: adjustedQuantity, subtotal } // Update item with new quantity and subtotal
      }
      return item // Return other items unchanged
    })

    // Update state and localStorage
    setProdInCart(updatedCart)
    localStorage.setItem('cart', JSON.stringify(updatedCart))
    calculateTotal(updatedCart)
    inCartProducts()
  }

  const removeProduct = (tempId) => {
    // Filter out the product with the matching tempId
    const updatedCart = prodInCart.filter((item) => item.tempId !== tempId)
    // console.log(tempId)

    // Update localStorage with the new cart
    localStorage.setItem('cart', JSON.stringify(updatedCart))

    // Update the state with the new cart
    setProdInCart(updatedCart)

    // Recalculate the total after removing the product
    calculateTotal(updatedCart)

    // Perform any additional updates if necessary
    inCartProducts()
  }

  // console.log(unitIsOpen )
  // console.log(unitIsClosed)

  return (
    <>
      <CartStyled>
        <div className='catTitle'>
          <h2>Cos de cumparaturi</h2>
        </div>
        {prodInCart.length > 0 ? (
          <React.Fragment>
            <div className='listContainer'>
              {prodInCart.map((product, index) => (
                <div className='productContainer' key={index}>
                  <div className='productDataContainer'>
                    <div
                      className='productImage'
                      onClick={(e) => {
                        e.preventDefault()
                        navigate(`/produs/${product.productLink}`)
                      }}
                    >
                      {product.productCoverImage !== null ? (
                        <img
                          src={product.productCoverImage}
                          alt={product.name}
                          className='coverImg'
                        />
                      ) : (
                        <img
                          src={
                            'https://www.aroma-pasiunii.intorsura.ro/media/aroma-pasiunii-no-photo.png'
                          }
                          alt={product.name}
                          className='coverImg'
                        />
                      )}
                    </div>
                    <div className='productData'>
                      <div
                        className='productName'
                        onClick={(e) => {
                          e.preventDefault()
                          navigate(`/produs/${product.productLink}`)
                        }}
                      >
                        <h2>
                          {toUpperCase(product.name)} {product.productWeight}{' '}
                          {' - '}
                          {product.productPrice.toFixed(2)} {currency}
                        </h2>
                      </div>

                      {product.productOptions?.length > 0 && (
                        <div className='productOptions'>
                          <div className='optionsList'>
                            {product.productOptions.map((option, index) => (
                              <div key={index}>
                                {option.name} - {option.price.toFixed(2)}{' '}
                                {currency}
                              </div>
                            ))}
                          </div>
                        </div>
                      )}

                      <div className='totalProdPrice'>
                        {' Subtotal: '}
                        <span className='price'>
                          {product.subtotal.toFixed(2)}
                        </span>
                        <span className='currency'>{currency}</span>
                      </div>
                      <div className='quantity'>
                        <button
                          className='decrease'
                          onClick={() =>
                            updateQuantity(product.tempId, product.quantity - 1)
                          }
                        >
                          -
                        </button>
                        <input
                          type='number'
                          value={product.quantity}
                          min='1'
                          className='quantityInput'
                          readOnly
                        />
                        <button
                          className='increase'
                          onClick={() =>
                            updateQuantity(product.tempId, product.quantity + 1)
                          }
                        >
                          +
                        </button>
                      </div>
                    </div>
                    <div className='prodRemove'>
                      <button
                        className='remove'
                        onClick={() => removeProduct(product.tempId)}
                      >
                        Sterge produsul
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className='orderTotal'>
              <span className='totalOrderTitle'>Total comanda: </span>
              <div className='totalOrderPrice'>
                <span className='price'>{orderTotal.toFixed(2)} </span>
                <span className='currency'> {currency}</span>
              </div>
            </div>
            {orderTotal !== 0 && !showCheckOutForm && (
              <div className='deliveryDataB'>
                <button
                  className='deliveryDataButton'
                  onClick={() => setShowCheckOutForm(true)}
                >
                  Date livrare
                </button>
              </div>
            )}
          </React.Fragment>
        ) : (
          <>
            <div className='emptyCart'>
              <p>Nu ai produse in cos!</p>
            </div>
            <RedirectHome toPage={'meniu'} />
          </>
        )}
      </CartStyled>
      {showCheckOutForm && (
        <CheckOutForm
          cart={prodInCart}
          isLoggedIn={isLoggedIn}
          orderTotal={orderTotal}
          currency={currency}
        />
      )}
    </>
  )
}

export default CartWithProductOptions
