import React, { useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import GlobalStyle from './styles/GlobalStyle'
import MainLayout from './layouts/mainLayout/MainLayout'
import RestMenuLayout from './layouts/restMenuLayout/RestMenuLayout'
import CustomerLayout from './layouts/customerLayout/CustomerLayout'

// ------- view -------
// import Cart from './views/shop/cart/Cart'
import CartWithProductOptions from './views/shop/cart/CartWithProductOptions'
// import Categories from './views/shop/categories/Categories'
import CategoriesProducts from './views/shop/categoriesProducts/CategoriesProducts'
import Product from './views/shop/product/Product'
import Products from './views/shop/products/Products'
import Start from './views/common/start/Start'
import AdminDashboard from './views/admin/adminDashboard/AdminDashboard'
import TodayOrdersHistory from './views/admin/orderHistory/todayOrders/TodayOrdersHistory'
import AllOrdersHistory from './views/admin/orderHistory/AllOrdersHistory'
import FacilitysList from './views/admin/facilitysList/FacilitysList'
import ProductsList from './views/admin/productList/ProductsList'
import CategoriesList from './views/admin/categoriesList/CategoriesList'
import CustomerList from './views/admin/customerList/CustomerList'
import Page from './views/common/page/page'
import SpecialOffersList from './views/admin/specialOffersList/SpecialOffersList'

// ------- components forms -------
import RegisterAdmin from './views/admin/registerAdmin/RegisterAdmin'
import RegisterCustomer from './views/customer/registerCustomer/RegisterCustomer'

import ForgotPassword from './views/admin/forgotPassword/ForgotPassword'
import ResetPassword from './views/admin/resetPassword/ResetPassword'

import UnitDataForm from './components/admin/forms/unitDataForm/UnitDataForm'
import ChangeLogoForm from './components/admin/forms/changeLogoForm/ChangeLogoForm'
import AboutUnitForm from './components/admin/forms/aboutUnitForm/AboutUnitForm'
import LocalizationForm from './components/admin/forms/localizationForm/LocalizationForm'
import DeliveryForm from './components/admin/forms/deliveryForm/DeliveryForm'
import WorkingTimesForm from './components/admin/forms/workingTimesForm/WorkingTimesForm'

// import SpecialOffers from './components/SpecialOffers/SpecialOffers'

import SuccesAlert from './components/common/alert/SuccesAlert'

import NotFound from './views/404/NotFound'

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [adminData, setadminData] = useState([])
  const [inCart, setInCart] = useState([])

  useEffect(() => {
    const adminData = JSON.parse(localStorage.getItem('adminData'))
    if (adminData) {
      setadminData(adminData)
      setIsLoggedIn(true)
    }
    const cartItems = JSON.parse(localStorage.getItem('cart'))
    if (cartItems) {
      setInCart(cartItems)
    }
  }, [])

  const toggleLogin = () => {
    if (isLoggedIn) {
      localStorage.removeItem('adminData')
      setIsLoggedIn(false)
    }
  }
  const prodInCart = () => {
    const cartItems = JSON.parse(localStorage.getItem('cart'))
    if (cartItems) {
      setInCart(cartItems)
    }
  }

  const totalQuantity = inCart.reduce((sum, el) => sum + el.quantity, 0)

  return (
    <>
      <GlobalStyle />
      {/* {showAlert && <SuccesAlert message={alertMessage} />} */}
      {!isLoggedIn ? (
        <BrowserRouter>
          <Routes>
            <Route
              path='/'
              element={
                <MainLayout
                  isLoggedIn={isLoggedIn}
                  toggleLogin={toggleLogin}
                  inCartQuantity={totalQuantity}
                />
              }
            >
              <Route
                index
                element={
                  <Start
                    onLogin={(acces) => {
                      setIsLoggedIn(acces)
                    }}
                  />
                }
              />
              {/* <Route
                path={'/login'}
                element={
                  <Login
                    onLogin={(acces) => {
                      setIsLoggedIn(acces)
                    }}
                  />
                }
              /> */}
              {/* <Route path={'/register'} element={<RegisterCustomer />} /> */}
              <Route path={'/forgot-password'} element={<ForgotPassword />} />
              <Route
                path='/reseteaza-parola/:token'
                element={<ResetPassword />}
              />
            </Route>

            <Route path='*' element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      ) : (
        <BrowserRouter>
          <Routes>
            {/* ----------- MainLayout ----------- */}
            <Route
              path={'/'}
              element={
                <MainLayout
                  isLoggedIn={isLoggedIn}
                  toggleLogin={toggleLogin}
                  inCartQuantity={totalQuantity}
                />
              }
            >
              <Route
                path={'/cos-de-cumparaturi'}
                element={
                  <CartWithProductOptions
                    isLoggedIn={isLoggedIn}
                    inCartProducts={prodInCart}
                    dayOpen={''}
                  />
                }
              />
              <Route path={'/pagina'} element={<Page />} />
            </Route>
            {/* ----------- CustomerLayout ----------- */}
            <Route>
              <Route
                path='/'
                element={
                  <CustomerLayout
                    isLoggedIn={isLoggedIn}
                    toggleLogin={toggleLogin}
                    inCartQuantity={totalQuantity}
                  />
                }
              >
                <Route index element={<TodayOrdersHistory />} />
                {/* <Route index element={<Dashboard />} /> */}
                <Route path={'/comenzi'} element={<AllOrdersHistory />} />
                <Route path={'/setari'} element={<AdminDashboard />} />
                <Route path={'/add-admin'} element={<RegisterAdmin />} />
                <Route path={'/date-unitate'} element={<UnitDataForm />} />
                <Route
                  path={'/schimba-logo'}
                  element={
                    <ChangeLogoForm
                      unitID={'unitData.id'}
                      uploadFolder={'baseImg'}
                    />
                  }
                />
                <Route path={'/despre-unitate'} element={<AboutUnitForm />} />
                {/* <Route
                  path={'/oferte-speciale'}
                  element={<SpecialOfferForm />}
                /> */}
                {/* <Route path={'/oferte-speciale'} element={<SpecialOffers />} /> */}
                <Route
                  path={'/oferte-speciale'}
                  element={<SpecialOffersList />}
                />

                <Route path={'/facilitati'} element={<FacilitysList />} />

                <Route
                  path={'/categorii-produse'}
                  element={<CategoriesList />}
                />
                <Route path={'/produse'} element={<ProductsList />} />

                <Route path={'/clienti'} element={<CustomerList />} />
                <Route path={'/localizare'} element={<LocalizationForm />} />
                <Route path={'/livrari'} element={<DeliveryForm />} />
                <Route path={'/orar'} element={<WorkingTimesForm />} />
              </Route>
            </Route>
            {/* ----------- RestMenuLayout ----------- */}
            <Route
              element={
                <RestMenuLayout
                  isLoggedIn={isLoggedIn}
                  toggleLogin={toggleLogin}
                  inCartQuantity={totalQuantity}
                />
              }
            >
              <Route
                path={'/meniu/:id'}
                // path={'/categorii-produse/:id'}
                element={
                  // <Categories onBuy={addToCart} onAlert={handleShowAlert} />
                  // <Categories inCartProducts={prodInCart} />
                  <CategoriesProducts
                    inCartProducts={prodInCart}
                    dayOpen={''}
                  />
                }
              />
              {/* <Route
                path={'/categories'}
                element={
                  // <Categories onBuy={addToCart} onAlert={handleShowAlert} />
                  // <Categories inCartProducts={prodInCart} />
                  <CategoriesProducts
                    inCartProducts={prodInCart}
                    dayOpen={''}
                  />
                }
              /> */}
              <Route
                path={'/meniu'}
                element={
                  // <Products onBuy={addToCart} onAlert={handleShowAlert} />
                  <Products inCartProducts={prodInCart} />
                }
              />
              {/* <Route
                path={'/products'}
                element={
                  // <Products onBuy={addToCart} onAlert={handleShowAlert} />
                  <Products inCartProducts={prodInCart} />
                }
              /> */}
              <Route
                path={'/produs/:id'}
                element={
                  // <Product onBuy={addToCart} onAlert={handleShowAlert} />
                  <Product inCartProducts={prodInCart} />
                }
              />
            </Route>

            {/* ------ page not found ---------- */}
            <Route path='*' element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      )}
    </>
  )
}

export default App
