import styled from 'styled-components'
import {
  COLORS,
  addUserColors,
  formColors,
} from '../../../../styles/setings/colors'
import {
  desktop,
  tablet,
  mobile,
} from '../../../../styles/setings/mediaScreens'

export const ChangeLogoFormStyled = styled.section`
  // margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin: 30px auto; */
  /* padding-bottom: 20px; */
  // padding: 20px 0 40px 0;
  background-color: ${COLORS.colorAboutSectionBg};
  /* background-color: grey; */
  ${desktop`
    margin-top: 20px;
  `}
  ${tablet`
    margin-top: 20px;
  `}
${mobile`
    margin-top: 10px;
  `}

.title {
    margin-bottom: 15px;
    h2 {
      display: inline-flex;
      text-decoration: underline;
      text-decoration-thickness: 4px;
      text-decoration-color: ${COLORS.colorTextUnderlineDefault};
      /* background-color: grey; */

      ${desktop`
        align-items: center;
        justify-content: center;    
        width: 100%;
        font-size: 2rem;
        font-weight: bold;
      `}
      ${tablet`
        align-items: center;
        justify-content: center;    
        width: 100%;
        font-size: 2rem;
        font-weight: bold;
      `}
    ${mobile`
        align-items: center;
        justify-content: center;    
        width: 100%;
        font-size: 2rem;
        font-weight: bold;
        text-align: center;
    `}
    }
  }

  .info {
    font-size: 1.2em;
    text-align: center;
    max-width: 1300px;
    padding: 10px 15px;
    margin: 15px 0;
    color: #fff;
    background-color: #8b1717;
    border-radius: 5px;
    ${mobile`
        font-size: 1em;
    `}
  }

  .oldLogo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 140px;
    margin: 15px 0;
    padding: 10px;
    border: solid #959595 2px;
    border-radius: 15px;
    font-weight: bold;
    /* background-color: grey; */
    ${mobile`
        align-items:center; 
        flex-direction: column;   
        text-align: center;
        width: 220px;
        height: 220px;
    `}
    img {
      max-width: 100px;
      ${mobile`    
        max-width: 180px;
        max-height: auto;
    `}
    }

    .imageUploadFields {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 15px 0;
      /* background-color: grey; */
      ${mobile`
        align-items:center; 
        flex-direction: column;   
        text-align: center;
    `}

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        /* background-color: grey; */
        ${mobile`
        align-items: center;
        justify-content: center;    
        width: 100%;
        text-align: center;
    `}
      }
    }
  }
`
