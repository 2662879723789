import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import {
  getSpecOfferById,
  updateSpecOffersActive,
  updateSpecOffer,
} from '../../../../api/unitDataAPI'
import { SpecialOfferFormStyled } from './SpecialOfferFormStyled'
import {
  GeneralFormStyled,
  FieldLabel,
  InfoLabel,
  SuccesLabel,
  ErrorLabel,
} from '../../../../styles/elements/formsStyle'
import {
  SubmitButton,
  RedSubmitButton,
  GreenSubmitButton,
} from '../../../../styles/elements/buttons'

import SuccesAlert from '../../../common/alert/SuccesAlert'
import ErrorAlert from '../../../common/alert/ErrorAlert'

import BackToSetings from '../../../common/buttons/backToSetings/BackToSetings'
import SingleImageUploaderForm from '../singleImageUploaderForm/SingleImageUploaderForm'
import FadeLoading from '../../../common/loaders/fadeLoading/FadeLoading'
import { PopUpLevel1Styled } from '../../../common/popUpWindows/PopUpLevel1Styled'

const cleanFormData = {
  valability: '',
  title: '',
  description: '',
  // image: '',
}

const validation = {
  valability: (data) => data.valability.length < 1 || isNaN(data.valability),
  title: (data) => data.title.length < 10,
  description: (data) => data.description.length < 50,
}

function SpecialOfferForm({ specialOfferId, cancel }) {
  const [formData, setFormData] = useState(cleanFormData)
  const [specialOffer, setSpecialOffer] = useState({})
  const [offerId, setOfferId] = useState(specialOfferId)
  const [showChangeImage, setShowChangeImage] = useState(null)
  const [errors, setErrors] = useState([])
  const [responseErrors, setResponseErrors] = useState([])
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const ActivateButtonComponent =
    specialOffer.active === 1 ? RedSubmitButton : GreenSubmitButton

  // Fetch the special offer by ID whenever `specialOfferId` changes
  useEffect(() => {
    if (offerId) {
      getSpecOfferById(offerId, (response) => {
        setSpecialOffer(response)
      })
    }
  }, [offerId]) // Trigger this effect when `specialOfferId` changes

  useEffect(() => {
    if (specialOffer && Object.keys(specialOffer).length > 0) {
      setFormData({
        valability: specialOffer.valability || '',
        title: specialOffer.title || '',
        description: specialOffer.description || '',
        // image: specialOffer.image || '',
      })
    }
    document.body.style.overflow = 'hidden'
  }, [specialOffer])

  const handleChange = (e) => {
    const { name, value } = e.target

    // Handle other fields
    if (!validation[name]({ ...formData, [name]: value })) {
      setErrors(errors.filter((el) => el !== name))
    } else {
      if (!errors.includes(name)) setErrors([...errors, name])
    }

    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleActivateSection = (event) => {
    event.preventDefault()
    const newActive = specialOffer.active === 1 ? { active: 0 } : { active: 1 }

    // Set loading state to true
    setIsLoading(true)

    updateSpecOffersActive(
      specialOffer.id,
      newActive,
      handleActivateSuccess,
      handleError
    )
  }

  function handleActivateSuccess(response) {
    setAlertMessage(
      'Oferta a fost ' +
        (specialOffer.active === 1 ? 'dezactivata' : 'activata') +
        ' cu succes!'
    )
    setShowAlert(true)

    // Reset loading state
    setIsLoading(false)
    // set new unit data
    setSpecialOffer(response)
    const timer = setTimeout(() => {
      // reload page
      window.location.reload()
    }, 2100)
    return () => clearTimeout(timer)
  }

  // console.log(formData.image);
  const handleSubmit = (event) => {
    event.preventDefault()

    // Set loading state to true
    // setIsLoading(true)

    const validationErrors = Object.keys(validation).filter((el) =>
      validation[el](formData)
    )

    setErrors(validationErrors)
    // setIsLoading(false)

    if (validationErrors.length === 0) {
      setIsLoading(true)
      updateSpecOffer(specialOffer.id, formData, handleSuccess, handleError)
    }
  }

  function handleError(error) {
    console.error(error)

    // Parse the error response
    const errorResponse = error.response.data
    const parsedErrors = Object.values(errorResponse).flat()

    // Update the state with the parsed errors
    setResponseErrors(parsedErrors)

    // Reset loading state
    setIsLoading(false)
  }

  console.log('offerId ' + offerId)
  console.log('specialOffer.id ' + specialOffer.id)

  function handleSuccess(response) {
    // console.log(response)
    // alert('Contul a fost creat cu succes!')
    setAlertMessage('Datele au fost salvate cu succes!')
    setShowAlert(true)
    // setFormData(cleanFormData)

    // Update the facility's active state directly in the unitFacilitys array
    setSpecialOffer(
      { ...specialOffer, id: response.id } // Update the active status
    )
    setOfferId(response.id)
    // navigate('/login')

    // Reset loading state
    setIsLoading(false)

    const timer = setTimeout(() => {
      // window.location.reload()
      // window.location.href = '/setari'
      // setShowChangeImage(
      //   showChangeImage === specialOffer.id ? null : specialOffer.id
      // )
      // alert(response.id)
      setShowChangeImage(response.id)
    }, 2100)
    return () => clearTimeout(timer)
  }

  // Function to remove an error message from the state
  const removeError = (index) => {
    setResponseErrors(responseErrors.filter((_, i) => i !== index))
  }

  return (
    <>
      {showAlert && <SuccesAlert message={alertMessage} />}

      <div>
        {responseErrors.map((message, index) => (
          <ErrorAlert
            key={index}
            message={message}
            onRemove={() => removeError(index)}
          />
        ))}
      </div>
      {/* <BackToSetings /> */}
      {specialOffer ? (
        <>
          <PopUpLevel1Styled>
            <div className='popUpLevel1Container'>
              <SpecialOfferFormStyled>
                <div className='specOffFormContainer'>
                  <div className='title'>
                    <h2>Oferte speciale</h2>
                  </div>
                  <div className='cancelButtonContainer'>
                    <button
                      className='closeButton'
                      onClick={() => {
                        // setIsOpen(false)
                        // window.location.reload()
                        setTimeout(cancel, 300) // Delay cancel to allow fade-out
                        document.body.style.overflow = '' // Re-enable body scroll
                      }}
                    >
                      Renunta
                    </button>
                  </div>
                  {specialOffer.id && (
                    <div className='changeStatusFormContainer'>
                      <ActivateButtonComponent
                        onClick={handleActivateSection}
                        disabled={isLoading}
                      >
                        {specialOffer.active === 1
                          ? isLoading
                            ? 'Asteapta! Se incarca...'
                            : 'Dezactiveaza oferta'
                          : isLoading
                          ? 'Asteapta! Se incarca...'
                          : 'Activeaza oferta'}
                      </ActivateButtonComponent>
                    </div>
                  )}
                  {specialOffer.id && (
                    <div className='changeImageButtonFormContainer'>
                      <RedSubmitButton
                        onClick={() => {
                          setShowChangeImage(
                            showChangeImage === specialOffer.id
                              ? null
                              : specialOffer.id
                          )
                        }}
                      >
                        {specialOffer.image
                          ? 'Schimba imaginea'
                          : 'Adauga imagine'}
                      </RedSubmitButton>
                      {showChangeImage === specialOffer.id && (
                        <SingleImageUploaderForm
                          apiName={'updateSpecialOfferPhoto'}
                          id={specialOffer.id}
                          formTitle={
                            specialOffer.image
                              ? 'Schimba imaginea'
                              : 'Adauga imagine'
                          }
                          oldImage={specialOffer.image}
                          uploadFolder={'special-offers'}
                          fileType={''}
                          cancel={() => setShowChangeImage(null)}
                          backUrl={'oferte-speciale'}
                        />
                      )}
                      <span>
                        Nu este obilgatoriu sa incarcati o imagine, dar poate
                        avea un impact placut pentru utilizatori.
                      </span>
                    </div>
                  )}
                  <GeneralFormStyled>
                    <form onSubmit={handleSubmit}>
                      <div className='oneField'>
                        <div>
                          <FieldLabel>
                            Valabilitate
                            {!isNaN(formData.valability) &&
                            errors.includes('phone') === true ? (
                              <ErrorLabel>Valabilitate prea scura!</ErrorLabel>
                            ) : errors.includes('valability') ? (
                              <ErrorLabel>Numar incorect</ErrorLabel>
                            ) : formData.valability.length >= 1 &&
                              !isNaN(formData.valability) ? (
                              <SuccesLabel>&#10004;</SuccesLabel>
                            ) : (
                              <InfoLabel>Minim 1 cifra</InfoLabel>
                            )}
                          </FieldLabel>
                          <input
                            type='text'
                            name='valability'
                            value={formData.valability}
                            onChange={handleChange}
                            placeholder='Valabilitate in zile'
                          />
                        </div>
                      </div>
                      <div className='oneField'>
                        <div>
                          <FieldLabel>
                            Titlu
                            {errors.includes('title') === true ? (
                              <ErrorLabel>Titlu prea scurt!</ErrorLabel>
                            ) : formData.title.length >= 10 ? (
                              <SuccesLabel>&#10004;</SuccesLabel>
                            ) : (
                              <InfoLabel>Minim 10 caractere</InfoLabel>
                            )}
                          </FieldLabel>
                          <input
                            type='text'
                            name='title'
                            value={formData.title}
                            onChange={handleChange}
                            placeholder='Titlu'
                          />
                        </div>
                      </div>
                      <div className='oneField'>
                        <div>
                          <FieldLabel>
                            Descriere oferta
                            {errors.includes('description') === true ? (
                              <ErrorLabel>
                                Descrierea este prea scurta!
                              </ErrorLabel>
                            ) : formData.description.length >= 50 ? (
                              <SuccesLabel>&#10004;</SuccesLabel>
                            ) : (
                              <InfoLabel>Minim 50 caractere</InfoLabel>
                            )}
                          </FieldLabel>
                          <textarea
                            type='text'
                            name='description'
                            value={formData.description}
                            onChange={handleChange}
                            placeholder='Descriere unitate'
                          />
                        </div>
                      </div>
                      <div className='image'>
                        <img src={specialOffer.image} alt='' />
                      </div>
                      <div className='submitContainer'>
                        <SubmitButton disabled={isLoading}>
                          {isLoading
                            ? 'Asteapta! Se incarca...'
                            : !specialOffer.title
                            ? 'Salveaza'
                            : 'Modifica datele'}
                        </SubmitButton>
                      </div>
                    </form>
                  </GeneralFormStyled>
                </div>
              </SpecialOfferFormStyled>
            </div>
          </PopUpLevel1Styled>
        </>
      ) : (
        // <p>Incarc datele</p>
        <FadeLoading />
      )}
    </>
  )
}

export default SpecialOfferForm
