import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import {
  getAllCustomers,
  getAllPaginatedCustomers,
  getCategories,
  getCategoriesByID,
  getProductsByCategory,
  getProductById,
  updateCustomerActive,
  updateProductPrincipal,
  deleteCustomer,
} from '../../../api/unitDataAPI'
import { CustomerListStyled } from './CustomerListStyled'
import {
  GeneralFormStyled,
  FieldLabel,
  InfoLabel,
  SuccesLabel,
  ErrorLabel,
} from '../../../styles/elements/formsStyle'
import {
  SubmitButton,
  RedSubmitButtonSmall,
  GreenSubmitButtonSmall,
  SubmitButtonSmall,
} from '../../../styles/elements/buttons'

import SuccesAlert from '../../../components/common/alert/SuccesAlert'
import ErrorAlert from '../../../components/common/alert/ErrorAlert'

import BackToSetings from '../../../components/common/buttons/backToSetings/BackToSetings'
import SingleImageUploaderForm from '../../../components/admin/forms/singleImageUploaderForm/SingleImageUploaderForm'
import ProductAddForm from '../../../components/admin/forms/productsForm/productAddForm/ProductAddForm'
import TogleSwitch from '../../../components/common/buttons/togleSwitch/TogleSwitch'
import EditButton from '../../../components/common/buttons/edit/EditButton'
import DeleteButton from '../../../components/common/buttons/delete/DeleteButton'
import Pagination from '../../../components/common/pagination/Pagination'
import ViewButton from '../../../components/common/buttons/viewButton/ViewButton'
import CustomerDashboard from '../../customer/customerDashboard/CustomerDashboard'
import RegisterCustomer from '../../customer/registerCustomer/RegisterCustomer'

function CustomerList() {
  const [itemsList, setItemsList] = useState({ customers: [] })
  const [showChangeAboutImage, setShowChangeAboutImage] = useState(null)
  const [showAddForm, setShowAddForm] = useState(null)
  const [showViewCusotmer, setShowViewCusotmer] = useState(null)
  const [errors, setErrors] = useState([])
  const [responseErrors, setResponseErrors] = useState([])
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1) // Track the current page for pagination
  const [orderBy, setOrderBy] = useState(1)
  const [fetchAllItems, setFetchAllItems] = useState(false) // Track whether to fetch all orders or paginated orders
  const navigate = useNavigate()
  // const ActivateButtonComponent =
  //   itemsList.active === 1 ? RedSubmitButtonSmall : GreenSubmitButtonSmall

  useEffect(() => {
    getAllPaginatedCustomers(currentPage, orderBy, (response) => {
      setItemsList(response)
      fetchItems()
    })
  }, [currentPage, fetchAllItems, orderBy, showViewCusotmer, showAddForm])

  const location = useLocation()
  const elID = location.state?.elID // Retrieve elID from the navigation state

  useEffect(() => {
    if (elID) {
      // Set the new state with elID when available
      setShowAddForm(elID)
      // ----------
      setShowViewCusotmer(elID)
    }
  }, [elID, setShowAddForm, setShowViewCusotmer])

  function handleAddNew() {
    setShowAddForm(true)
  }

  function handleViewCustomer(elID) {
    setShowViewCusotmer(elID)
  }

  function handleEdit(elID) {
    setShowAddForm(elID)
  }

  function handleDelete(elID) {
    // setShowAddForm(elID)
    setIsLoading(true)
    deleteCustomer(elID, handleDeletedSuccess, handleError)
  }

  function handleDeletedSuccess(response) {
    // console.log(response)
    // alert('Contul a fost creat cu succes!')
    setAlertMessage('Produsul a fost stears!')
    setShowAlert(true)
    // setFormData(cleanFormData)
    // navigate('/login')

    // Reset loading state
    setIsLoading(false)

    const timer = setTimeout(() => {
      window.location.reload()
      // window.location.href = '/setari'
    }, 2100)
    return () => clearTimeout(timer)
  }

  const handleActivateItem = (id, active) => {
    // event.preventDefault()
    const newActive = active === 1 ? { active: 0 } : { active: 1 }

    // Set loading state to true
    setIsLoading(true)

    updateCustomerActive(id, newActive, handleActivateSuccess, handleError)
  }

  function handleActivateSuccess(response) {
    // Update the active status locally
    setItemsList((prevActiveItem) => ({
      customers: prevActiveItem.customers.map((product) =>
        product.id === response.id
          ? { ...product, active: response.active }
          : product
      ),
    }))

    // Refetch paginated data
    fetchItems()

    // Reset loading state
    setIsLoading(false)
  }

  function handleError(error) {
    console.error(error)

    // Parse the error response
    const errorResponse = error.response.data
    const parsedErrors = Object.values(errorResponse).flat()

    // Update the state with the parsed errors
    setResponseErrors(parsedErrors)

    // Reset loading state
    setIsLoading(false)
  }

  // Function to remove an error message from the state
  const removeError = (index) => {
    setResponseErrors(responseErrors.filter((_, i) => i !== index))
  }

  const fetchItems = () => {
    if (fetchAllItems) {
      // Fetch all customers (unpaginated)
      getAllCustomers(
        orderBy,
        (response) => setItemsList(response),
        (error) => console.error('Failed to retrieve all customers:', error)
      )
    } else {
      // Fetch paginated customers
      getAllPaginatedCustomers(
        currentPage,
        orderBy,
        (response) => setItemsList(response),
        (error) =>
          console.error('Failed to retrieve paginated customers:', error)
      )
    }
  }

  // Function to toggle between fetching all orders and paginated orders
  const toggleFetchAllItems = () => {
    setFetchAllItems((prev) => !prev) // Toggle the fetchAllItems state
    setCurrentPage(1) // Reset to the first page when switching modes
  }

  // Function to handle pagination page changes
  const handlePageChange = (page) => {
    setCurrentPage(page) // Update the current page state
  }

  console.log('show add form ' + showAddForm)
  console.log('show customer ' + showViewCusotmer)

  return (
    <>
      {showAlert && <SuccesAlert message={alertMessage} />}

      <div>
        {responseErrors.map((message, index) => (
          <ErrorAlert
            key={index}
            message={message}
            onRemove={() => removeError(index)}
          />
        ))}
      </div>
      <BackToSetings />
      {itemsList ? (
        <>
          <CustomerListStyled>
            <div className='title'>
              <h2>Lista clienti</h2>
            </div>
            <div className='readMeList'>
              <div className='infoTitle'>
                <h4>Cum lucram cu formularul.</h4>
              </div>
              <ul>
                <li>
                  <span>EDITEAZA</span> - deschide formular pentru editearae
                  facilitatii.
                </li>
                <li>
                  <span>STERGE</span> - Sterge definitiv facilitatea.
                </li>
                <li>
                  <span>ACTIVEAZA - DEZACTIVEAZA</span> - Seteaza facilitatea sa
                  fie vizibila sau nu in pagina la sectiunea facilitati.
                </li>
                <li>
                  <span>PRINCIPAL</span> - Seteaza facilitatea sa fie afisata in
                  partea de sus a pagini. Este recomandat sa <span>NU</span>{' '}
                  afisati mai mult de 4 facilitati principale. Activati cele mai
                  importane 4 facilitati ca principale.
                </li>
                <li>
                  - Daca butonul este <span>VERDE</span> facilitatea{' '}
                  <span>ESTE</span> activa pe site. <span>APASA</span> butonul
                  pentru activare.
                </li>
                <li>
                  - Daca butonul este <span>ROSU</span> facilitatea{' '}
                  <span>NU este activa</span> pe site. <span>APASA</span>{' '}
                  butonul pentru activare.
                </li>
                <li>
                  <span>
                    Mai jos aveti reprezentarea pe culori a butoanelor
                  </span>
                  <div className='listButtons'>
                    <TogleSwitch
                      checked={true}
                      label={'Activat'}
                      onChange={() => {}}
                      // readOnly
                    />
                    <TogleSwitch
                      checked={false}
                      label={'Dezactivat'}
                      onChange={() => {}}
                      // readOnly
                    />
                  </div>
                </li>
              </ul>
            </div>
            <div className='addNewButtonContainer'>
              <SubmitButton onClick={() => handleAddNew()}>Adauga</SubmitButton>
            </div>
            {!fetchAllItems && itemsList.last_page > 1 && (
              <Pagination
                currentPage={itemsList.current_page}
                totalPages={itemsList.last_page}
                onPageChange={handlePageChange}
              />
            )}

            <div className='optionsview'>
              <div>Aveti {itemsList.total} clienti.</div>
              <div className='pagesView'>
                {/* Button to toggle between "All" and "Paginated" views */}
                Vezi{' '}
                <button onClick={toggleFetchAllItems}>
                  {fetchAllItems ? '10 clienti / pag' : 'Toti clientii'}{' '}
                  {/* Change button text based on the state */}
                </button>
              </div>

              <div className='orderByView'>
                Ordoneaza dupa{' '}
                <button onClick={() => setOrderBy(1)}>Nume</button>
                <button onClick={() => setOrderBy(2)}>Activi</button>
                <button onClick={() => setOrderBy(3)}>Utilizator</button>
              </div>
            </div>
            <div>search</div>
            <div className='container'>
              <div className='row'>
                {itemsList.customers.map((el, index) => {
                  return (
                    <div className='col-6 article' key={index}>
                      <div className='divElement'>
                        <div className='actionButtons'>
                          <ViewButton
                            onClick={() => handleViewCustomer(el.id)}
                          />
                          {/* <EditButton onClick={() => handleEdit(el.id)} />
                          <DeleteButton
                            onClick={() => handleDelete(el.id)}
                            disabled={isLoading}
                          /> */}
                          <div>
                            <TogleSwitch
                              checked={el.active === 1}
                              onChange={() =>
                                handleActivateItem(el.id, el.active)
                              }
                              label={
                                el.active === 1 ? 'Dezactiveaza' : 'Activeaza'
                              }
                            />
                          </div>
                        </div>

                        <div className='customerName'>
                          <h2>
                            {el.first_name} {el.last_name}
                          </h2>
                        </div>
                        <div className='username'>
                          {el.username
                            ? 'Utilizator: ' + el.username
                            : 'FARA CONT'}
                        </div>
                        <div className='tumbnailImg'>
                          <img
                            src={
                              'https://www.aroma-pasiunii.intorsura.ro/media/icons/userAvatar.png'
                            }
                            alt={el.first_name + el.last_name}
                            className='coverImg'
                          />
                        </div>

                        <div className='details'>
                          <h3>E-Mail: {el.email ? el.email : ''}</h3>
                        </div>
                        <div className='details'>
                          <h3>Telefon: {el.phone ? el.phone : ''}</h3>
                        </div>
                        <div className='details'>
                          <h3>Localitate: {el.city ? el.city : ''}</h3>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
            {showAddForm !== null && (
              <RegisterCustomer
                elID={showAddForm !== true ? showAddForm : null}
                cancel={() => setShowAddForm(null)}
              />
            )}
            {showViewCusotmer !== null && (
              <CustomerDashboard
                elID={showViewCusotmer !== true ? showViewCusotmer : null}
                cancel={() => setShowViewCusotmer(null)}
                currentPage={currentPage}
              />
            )}

            {!fetchAllItems && itemsList.last_page > 1 && (
              <Pagination
                currentPage={itemsList.current_page}
                totalPages={itemsList.last_page}
                onPageChange={handlePageChange}
              />
            )}
          </CustomerListStyled>
        </>
      ) : (
        <p>Incarc date ...</p>
      )}
    </>
  )
}

export default CustomerList
