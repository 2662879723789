import React, { useEffect, useState } from 'react'
import { PageAboutStyled } from './PageAboutStyled'
import { getAboutUnit } from '../../../../api/unitDataAPI'

function PageAbout() {
  const [about, setAbout] = useState([])

  useEffect(() => {
    getAboutUnit((response) => {
      setAbout(response)
    })
  }, [])

  return (
    <>
      {about ? (
        <PageAboutStyled id='about'>
          <article>
            <div className='container'>
              <div className='row'>
                <div className='col-12 artTitle'>
                  <h2>{about.title}</h2>
                </div>
              </div>
              <div className='row'>
                <div className='col-12 artText'>
                  <img src={about.image} alt='Aroma Pasiunii' />
                  {about.description}
                </div>
              </div>
            </div>
          </article>
        </PageAboutStyled>
      ) : (
        ''
      )}
    </>
  )
}
export default PageAbout
