import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  faSearch,
  faShoppingCart,
  faUser,
  faHome,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BurgerMenuStyled } from './BurgerMenuStyled'
// import { getPageMenus } from '../../../api/header'
import { getAdminPrincipalMenus } from '../../../../api/header'
import Search from '../../search/Search'

function BurgerMenu() {
  const [isOpen, setIsOpen] = useState(false)
  const [menus, setMenus] = useState([])

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  const handleLinkClick = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    getAdminPrincipalMenus((response) => {
      setMenus(response)
    })
  }, [])

  return (
    <>
      <BurgerMenuStyled>
        <div className='burger-menu'>
          <div
            className={`burger-icon ${isOpen ? 'open' : ''}`}
            onClick={toggleMenu}
          >
            <div className='bar'></div>
            <div className='bar'></div>
            <div className='bar'></div>
          </div>
          <div className={`menu ${isOpen ? 'open' : ''}`}>
            <ul className='mobile-menu'>
              {menus.map((el, index) => {
                return (
                  <li key={index}>
                    <Link to={el.link} onClick={handleLinkClick}>
                      {/* <FontAwesomeIcon icon={el.icon} /> */}
                      <span>{el.name}</span>
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </BurgerMenuStyled>
    </>
  )
}
export default BurgerMenu
