import React, { useEffect, useState } from 'react'
// import { logoUpload } from '../../../../api/imageHandler'
import { IconUploaderFormStyled } from './IconUploaderFormStyled'
import { ErrorDysplayStyled } from '../../../common/alert/AlertStyled'
import SuccesAlert from '../../../common/alert/SuccesAlert'
import ErrorAlert from '../../../common/alert/ErrorAlert'
import { updateFacilityIcon } from '../../../../api/unitDataAPI'

const apiFunctions = {
  updateFacilityIcon,
}

const IconUploaderForm = ({
  apiName,
  id,
  formTitle,
  oldImage,
  uploadFolder,
  fileType,
  cancel,
  backUrl,
}) => {
  const [image, setImage] = useState(null)
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null)
  const [error, setError] = useState(null)
  const [responseErrors, setResponseErrors] = useState([])
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (oldImage && Object.keys(oldImage).length > 0) {
      setImagePreviewUrl(
        // image: oldImage || '',
        oldImage || ''
      )
    }
    document.body.style.overflow = 'hidden' // Disable body scroll
  }, [oldImage])

  // Handle image change and preview
  const handleImageChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      // Validate file type
      if (fileType === 'png' && file.type !== 'image/png') {
        setError('Accept doar fisiere png.')
        return
      } else if (
        fileType !== 'png' &&
        !['image/png', 'image/jpeg'].includes(file.type)
      ) {
        setError('Accept doar fisiere png sau jpg.')
        return
      }

      const reader = new FileReader()
      reader.onloadend = () => {
        const img = new Image()
        img.src = reader.result

        img.onload = () => {
          // Validate resolution based on file type
          if (fileType === 'png' && (img.width > 600 || img.height > 600)) {
            setError('Imaginea este mai mare de 600x600 pixeli.')
            return
          } else if (
            fileType !== 'png' &&
            (img.width < 800 || img.height < 800)
          ) {
            setError('Imaginea este mai mica de 800x800 pixeli.')
            return
          }

          setImage(file) // Store the file object
          setImagePreviewUrl(reader.result) // Display preview
          setError(null) // Clear error if everything is valid
        }
      }

      reader.readAsDataURL(file) // Convert file to base64 string for preview
    }
  }

  // Delete image preview and reset
  const handleDeleteImage = () => {
    setImage(null)
    setImagePreviewUrl(null)
    setError(null) // Clear error when resetting
  }

  // Replace image by selecting a new one
  const handleReplaceImage = (e) => {
    handleImageChange(e)
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    if (!image) {
      setError('Te rog selecteaza o imagine')
      return
    }
    // Get the actual API function from the lookup object
    const apiFunction = apiFunctions[apiName]

    if (!apiFunction) {
      setError(`Invalid API function: ${apiName}`)
      return
    }

    // Set loading state to true
    setIsLoading(true)

    // Extract the file name with extension
    const imageName = image.name

    // Create a FormData object to append the file and the image name
    const formData = new FormData()
    formData.append('icon', image) // Ensure the file is sent under 'icon'
    formData.append('imageName', imageName) // Optionally include the image name

    // Use the `iconUpload` function from the API file
    apiFunction(
      id, // Unit ID for the API request
      uploadFolder, // Upload folder specified in the request
      formData, // Pass form data including the image and its name

      handleSuccess,
      handleError
    )
  }

  function handleError(error) {
    console.error(error)

    // Parse the error response
    const errorResponse = error.response.data
    const parsedErrors = Object.values(errorResponse).flat()

    // Update the state with the parsed errors
    setResponseErrors(parsedErrors)

    // Reset loading state
    setIsLoading(false)
  }

  function handleSuccess(response) {
    // console.log(response)
    // alert('Contul a fost creat cu succes!')
    setAlertMessage('Iconita a fost incarcata cu succes!')
    setShowAlert(true)
    setImage(null)
    // navigate('/login')
    // Reset loading state
    setIsLoading(false)
    const timer = setTimeout(() => {
      // window.location.reload()
      window.location.href = `/${backUrl}`
      document.body.style.overflow = ''
    }, 2100)
    return () => clearTimeout(timer)
  }

  // Function to remove an error message from the state
  const removeError = (index) => {
    setResponseErrors(responseErrors.filter((_, i) => i !== index))
  }

  return (
    <>
      {showAlert && <SuccesAlert message={alertMessage} />}

      <div>
        {responseErrors.map((message, index) => (
          <ErrorAlert
            key={index}
            message={message}
            onRemove={() => removeError(index)}
          />
        ))}
      </div>
      <IconUploaderFormStyled>
        <div className='formContainer'>
          <div className='infoContainer'>
            <h2>{formTitle}</h2>
            <div className='cancelButtonContainer'>
              <button
                className='closeButton'
                onClick={() => {
                  // setIsOpen(false)

                  setTimeout(cancel, 300) // Delay cancel to allow fade-out

                  // document.body.style.overflow = '' // Re-enable body scroll
                }}
              >
                Renunta
              </button>
            </div>
            <div className='uploadInfo'>
              <span>Suport fisiere in format png</span>
              <span>Rezolutie maxima 600px</span>
            </div>
          </div>
          <div className='imageUploadFormContainer'>
            {imagePreviewUrl ? (
              <>
                <div className='previewContainer'>
                  <span className='previewText'>Previzualizare</span>
                  <img
                    src={imagePreviewUrl}
                    alt='Uploaded Preview'
                    className='imagePreview'
                  />
                  <button className='deleteButton' onClick={handleDeleteImage}>
                    Sterge
                  </button>
                </div>
                <div>
                  <label className='replaceButton'>
                    Schimba
                    <input
                      className='hiddenInput'
                      type='file'
                      accept='image/*'
                      name='image'
                      onChange={handleReplaceImage}
                    />
                  </label>
                </div>
              </>
            ) : (
              <div>
                <label className='selectButton'>
                  Alege imaginea
                  <input
                    className='hiddenInput'
                    type='file'
                    accept='image/*'
                    name='image'
                    onChange={handleImageChange}
                  />
                </label>
              </div>
            )}
            {image && !error && !isLoading && (
              <div>
                <button
                  className='uploadButton'
                  onClick={handleSubmit}
                  disabled={!image && error && isLoading}
                >
                  {!image || error
                    ? ''
                    : isLoading
                    ? 'Asteapta! Se incarca...'
                    : 'Incarca imaginea!'}
                </button>
              </div>
            )}
          </div>
          {error && (
            <ErrorDysplayStyled>
              <span className='errorMsg'>{error}</span>
            </ErrorDysplayStyled>
          )}
        </div>
      </IconUploaderFormStyled>
    </>
  )
}

export default IconUploaderForm
