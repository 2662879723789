function formatDate(dateString) {
  const date = new Date(dateString)
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-indexed
  const year = date.getFullYear()
  return `${day}.${month}.${year}`
}

function formatDateHours(dateString) {
  const date = new Date(dateString)
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-indexed
  const year = date.getFullYear()
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')
  return `${day}.${month}.${year} - ${hours}:${minutes}`
}

function formatHours(dateString) {
  const date = new Date(dateString)
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')
  return `${hours}:${minutes}`
}

// calculate difrence between stored hours and minutes
// use this to calculate the difrence between recived order and finished order
function storedTimeDifference(timeStart, timeEnd) {
  // Split the time strings into hours and minutes
  const [startHours, startMinutes] = timeStart.split(':').map(Number)
  const [endHours, endMinutes] = timeEnd.split(':').map(Number)

  // Calculate the difference in hours and minutes
  let diffHours = endHours - startHours
  let diffMinutes = endMinutes - startMinutes

  // Adjust for negative minutes
  if (diffMinutes < 0) {
    diffMinutes += 60
    diffHours -= 1
  }

  // Format hours
  const formattedHours = String(Math.abs(diffHours))
  const hourPart =
    diffHours > 1
      ? `${formattedHours} ore si`
      : diffHours === 1
      ? `${formattedHours} ora si`
      : ''

  // Format minutes
  const formattedMinutes = String(Math.abs(diffMinutes))
  const minutePart =
    formattedMinutes < 2
      ? `${formattedMinutes} minut`
      : `${formattedMinutes} minute`

  // Combine results
  return `${hourPart} ${minutePart}`.trim()
}

// get current time like 10:00
const getCurrentTime = () => {
  const now = new Date()
  const hours = String(now.getHours()).padStart(2, '0') // Ensure 2 digits
  const minutes = String(now.getMinutes()).padStart(2, '0') // Ensure 2 digits
  return `${hours}:${minutes}`

  // one way to use
  // {
  //   el.open && el.close
  //     ? getCurrentTime() > el.open && getCurrentTime() < el.close
  //       ? `${el.open} - ${el.close}`
  //       : ''
  //     : 'Inchis'
  // }
}

// get current day like 01
const getCurrentDay = () => {
  const now = new Date()
  const day = String(now.getDate()).padStart(2, '0') // Ensure 2 digits
  return `${day}`
}

// Example output: "Friday"
const getCurrentDayNameByLocale = () => {
  const now = new Date()
  return now.toLocaleDateString('ro-RO', { weekday: 'long' }) // Returns full day name (e.g., "Monday")
}

const getCurrentDayNameCusotm = () => {
  const now = new Date()
  const days = [
    'Duminica',
    'Luni',
    'Marti',
    'Miercuri',
    'Joi',
    'Vineri',
    'Sambata',
  ]
  return days[now.getDay()] // Map the day index (0-6) to the array
}
// Example output: "Friday"

// get current day like 01
const getCurrentMonth = () => {
  const now = new Date()
  const month = String(now.getMonth() + 1).padStart(2, '0') // Months are zero-indexed
  return `${month}`
}

export {
  formatDate,
  formatDateHours,
  formatHours,
  storedTimeDifference,
  getCurrentTime,
  getCurrentDay,
  getCurrentDayNameByLocale,
  getCurrentDayNameCusotm,
  getCurrentMonth,
}
