import React, { useEffect, useState } from 'react'
import { getLocalitatiNamesByJudet } from '../../../api/jutete-localitati'

function Localitati({ selectedLocalitate, onChange, selectedJudet }) {
  const [localitati, setLocalitati] = useState([])
  const [selectedValue, setSelectedValue] = useState(
    selectedLocalitate || 'default'
  )

  // Fetch judete data
  useEffect(() => {
    getLocalitatiNamesByJudet(selectedJudet, (response) => {
      setLocalitati(response)
    })
  }, [selectedJudet])

  // Update selectedValue if selectedLocalitate changes
  useEffect(() => {
    setSelectedValue(selectedLocalitate || 'default')
  }, [selectedLocalitate])

  const handleSelect = (e) => {
    const value = e.target.value
    setSelectedValue(value)
    if (onChange && typeof onChange === 'function') {
      // Pass the event object to handleChange if needed
      onChange({ target: { name: 'localitate', value } })
    } else {
      console.error('onChange is not a function or is undefined')
    }
  }

  return (
    <>
      <select
        name='localitate' // Ensure name is present
        id='localitate'
        value={selectedValue}
        onChange={handleSelect} // Use handleSelect for onChange
      >
        <option value='default'>Localitatea</option>
        {localitati.map((el, index) => (
          <option key={index} value={el.localitate}>
            {el.localitate}
          </option>
        ))}
      </select>
    </>
  )
}

export default Localitati
