import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useParams, useNavigate } from 'react-router'
import { getProductByLink } from '../../../api/shopAPI'
import { ProductStyled } from '../../../styles/elements/products/ProductStyled'
import AddToCartLocalStorageWithOptions from '../../../components/common/addToCartLocalStorage/AddToCartLocalStorageWithOptions'
import CarouselAutoSliderFullScreen from '../../../components/common/carouselAutoSliderFullScreen/CarouselAutoSliderFullScreen'
import HorizontalScrollingImages from '../../../components/common/horizontalScrollingImages/HorizontalScrollingImages'
import { getCurrentTime } from '../../../functions/formatDate'
import { messages } from '../../../language/romanian/messages'
import ExtraProductOptions from '../extraProductOptions/ExtraProductOptions'
import capitalizeFirstLetter from '../../../functions/capinalizeFirstLetter'
import RedirectHome from '../../../components/common/redirectHome/RedirectHome'
import { NoDataStyled } from '../../../styles/elements/NoDataStyled'

function Product({ inCartProducts }) {
  const navigate = useNavigate()
  const { id } = useParams()
  const [product, setProduct] = useState(null)
  const [photo, setPhoto] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  // const [selectedElement, setSelectedElement] = useState(0) // Default to the first category

  const [selectedProductOptions, setSelectedProductOptions] = useState([])

  useEffect(() => {
    getProductByLink(id, (response) => {
      setProduct(response)
      response.product_images[0] &&
        setPhoto(response.product_images[0].image_link)
    })
  }, [id])

  // Open modal and set current index for the image clicked
  const openModal = (index) => {
    setCurrentIndex(index)
    setIsModalOpen(true)
  }

  // Close modal
  const closeModal = () => {
    setIsModalOpen(false)
  }

  const handleElementClick = (index, elementLink) => {
    setPhoto(elementLink)
    setCurrentIndex(index)
  }

  // const handleExtraProductClick = (id, price) => {
  //   console.log('Selected Product:', { id, price })
  //   setSelectedProduct({ id, price })
  // }

  const handleExtraProductOptions = (id, name, price, currency, isChecked) => {
    if (isChecked) {
      setSelectedProductOptions((prev) => [
        ...prev,
        { id, name, price, currency },
      ])
    } else {
      setSelectedProductOptions((prev) =>
        prev.filter((extraProd) => extraProd.id !== id)
      )
    }
  }

  // Calculate total price
  const subTotalPrice = selectedProductOptions.reduce(
    (sum, option) => sum + parseFloat(option.price),
    0
  )

  const finalPrice = product ? parseFloat(product.price) + subTotalPrice : ''

  // console.log(product)

  return (
    <>
      {product ? (
        <>
          <ProductStyled>
            <div className='productContainer'>
              <div className='productName'>
                <h1>
                  {product.name} {product.weight}
                  {product.weight_classes.name_short}
                </h1>
              </div>
              <div className='shortNavContainer'>
                <Link to='/' className='shortNav'>
                  HOME
                </Link>{' '}
                /{' '}
                <Link to='/meniu' className='shortNav'>
                  Meniu
                </Link>{' '}
                /{' '}
                <Link
                  to={`/meniu/${product.product_category_link}`}
                  className='shortNav'
                >
                  {product.product_category_link}
                </Link>
              </div>
              <div className='product'>
                {product.product_images.length === 0 ? (
                  <div className='foto'>
                    <img
                      src='https://www.aroma-pasiunii.intorsura.ro/media/aroma-pasiunii-no-photo.png'
                      alt=''
                      className='bigImg'
                    />
                  </div>
                ) : (
                  <div className='foto'>
                    <img
                      src={photo}
                      alt={product.name}
                      className='bigImg'
                      onClick={() => openModal(currentIndex)}
                    />

                    {product.product_images.length > 1 ? (
                      <HorizontalScrollingImages
                        items={product.product_images}
                        onElementClick={handleElementClick}
                      />
                    ) : (
                      ''
                    )}
                    {/* {product.product_images.map((el, index) => (
                            <img
                              key={index}
                              src={el.image_link}
                              alt='Thumbnail'
                              className='thumbnailImg'
                              onClick={() => {
                                setPhoto(el.image_link)
                                setCurrentIndex(index)
                              }}
                              style={{
                                transform: `translateX(+100%)`,
                              }}
                            />
                          ))} */}
                  </div>
                )}

                <div className='productData'>
                  <div className='price'>
                    {product.price}
                    <span className='currency'>
                      {product.currency.currency_short}
                    </span>
                  </div>
                  <div className='eanCode'>
                    Cod produs:
                    <span className='currency'>{product.ean_code}</span>
                  </div>
                  <div className='productDetails'>
                    {product.ingredients && (
                      <div className='ingredients'>
                        <h4 className='title'>Ingrediente:</h4>{' '}
                        {capitalizeFirstLetter(product.ingredients)}
                      </div>
                    )}
                    {product.allergens && (
                      <div className='allergens'>
                        <h4 className='title'>Alergeni:</h4>{' '}
                        {capitalizeFirstLetter(product.allergens)}
                      </div>
                    )}
                    {product.nutritional_declaration && (
                      <div className='nutritionalDeclaration'>
                        <h4 className='title'>Declaratie nutritionala:</h4>{' '}
                        {capitalizeFirstLetter(product.nutritional_declaration)}
                      </div>
                    )}
                    {product.energetic_value && (
                      <div className='energeticValue'>
                        <span className='title'>Valoare energetica:</span>{' '}
                        {product.energetic_value} kcal / 100
                        {product.weight_classes.name_short}
                      </div>
                    )}
                    {product.frozen_products === 1 && (
                      <div className='frozenProduct'>
                        Contine produse congelata
                      </div>
                    )}
                    {product.aditivi === 1 && (
                      <div className='frozenProduct'>Contine aditivi</div>
                    )}
                  </div>
                  {/* {selectedProductOptions.length > 0 && (
                    <div className='selectedOptions'>
                      <h4>Extra toping:</h4>
                      {selectedProductOptions.map((extraOption) => (
                        <div key={extraOption.id}>
                          {extraOption.name} - {extraOption.price.toFixed(2)}{' '}
                          {extraOption.currency}
                        </div>
                      ))}
                    </div>
                  )} */}
                </div>
              </div>

              {/* {selectedProductOptions.map((product) => (
                <div key={product.id}>
                  id: {product.id}, price: {product.price}
                </div>
              ))} */}

              <div>
                {/* <h2>Optiuni selectate:</h2>
                {selectedProductOptions.map((product) => (
                  <div key={product.id}>
                    ID: {product.id}, {product.name} - {product.price}{' '}
                    {product.currency}
                  </div>
                ))} */}

                <ExtraProductOptions
                  prodCat={product.product_category.id}
                  onExtraOptions={handleExtraProductOptions}
                />
              </div>
              {selectedProductOptions.length > 0 ? (
                <>
                  <div className='price'>
                    <span>Total optiuni:</span>
                    {subTotalPrice.toFixed(2)}
                    <span className='currency'>
                      {product.currency.currency_short}
                    </span>
                  </div>
                  <div className='price'>
                    <span>Pret total:</span>
                    {finalPrice.toFixed(2)}
                    <span className='currency'>
                      {product.currency.currency_short}
                    </span>
                  </div>
                </>
              ) : (
                ''
              )}
              <div>
                <AddToCartLocalStorageWithOptions
                  id={product.id}
                  eanCode={product.ean_code}
                  productName={product.name}
                  productWeight={
                    product.weight + product.weight_classes.name_short
                  }
                  productPrice={parseFloat(product.price)}
                  productCurrency={product.currency.currency_short}
                  // productCoverImage={product.product_images[0].image_link}
                  productCoverImage={
                    product.product_images.length > 0
                      ? product.product_images[0].image_link
                      : ''
                  }
                  addToCartStyle='singleProduct'
                  inCartProducts={inCartProducts}
                  productOptions={selectedProductOptions}
                />
              </div>

              {/* <ExtraProducts
                extraProdCat={product.product_category.id}
                // checked={el.active === 1}
                // onChange={() => handleActivateProduct(el.id, el.active)}
              /> */}
            </div>

            {/* Modal for Fullscreen Image Carousel */}
            {/* {isModalOpen && (
              <div className='carouselModal'>
                <button className='closeButton' onClick={closeModal}>
                  ×
                </button>
                <button className='prevButton' onClick={showPrevImage}>
                  ‹
                </button>
                <img
                  src={product.product_images[currentIndex].image_link}
                  alt='Fullscreen'
                  className='bigImgFullScreen'
                />
                <button className='nextButton' onClick={showNextImage}>
                  ›
                </button>
              </div>
            )} */}
            {isModalOpen && (
              <div className='carouselModal'>
                <button className='closeButton' onClick={closeModal}>
                  ×
                </button>
                <CarouselAutoSliderFullScreen elIndex={currentIndex}>
                  {product.product_images.map((image, index) => {
                    return (
                      <img
                        key={index}
                        src={image.image_link}
                        alt={product.name}
                        onClick={() => openModal(currentIndex)}
                      />
                    )
                  })}
                </CarouselAutoSliderFullScreen>
              </div>
            )}
          </ProductStyled>
        </>
      ) : (
        <>
          <NoDataStyled>
            <div className='noData'>
              <h1>Nu am gasit produsul!</h1>
            </div>
            <RedirectHome toPage={'meniu'} />
          </NoDataStyled>
        </>
      )}
    </>
  )
}

export default Product
