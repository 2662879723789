import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { getCustomerByID, updateCustomerData } from '../../../../api/customers'
import { ModifyCustomerDataStyled } from './ModifyCustomerDataStyled'
import {
  GeneralFormStyled,
  FieldLabel,
  InfoLabel,
  SuccesLabel,
  ErrorLabel,
  CancelButton,
} from '../../../../styles/elements/formsStyle'
import SuccesAlert from '../../../../components/common/alert/SuccesAlert'
import ErrorAlert from '../../../../components/common/alert/ErrorAlert'
import { PopUpLevel2Styled } from '../../../../components/common/popUpWindows/PopUpLevel2Styled'

function validateComplexEmail(email) {
  const complexEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  return complexEmailRegex.test(email)
}

const cleanFormData = {
  username: '',
  first_name: '',
  last_name: '',
  phone: '',
  email: '',
  city: '',
  street: '',
  street_number: '',
  building: '',
  staircase: '',
  apartment: '',
  intercom: '',
}

const validation = {
  username: (data) => data.username.length < 4,
  first_name: (data) => data.first_name.length < 3,
  last_name: (data) => data.last_name.length < 3,
  phone: (data) => data.phone.length < 10,
  email: (data) => data.email.length < 5 || !validateComplexEmail(data.email),
  city: (data) => data.city.length < 3,
  street: (data) => data.street.length < 3,
  street_number: (data) => data.street_number.length < 0,
  building: (data) => data.building.length < 0,
  staircase: (data) => data.staircase.length < 0,
  apartment: (data) => data.apartment.length < 0,
  intercom: (data) => data.intercom.length < 0,
}

function ModifyCustomerData({ cancel, elID }) {
  const [formData, setFormData] = useState(cleanFormData)
  const [errors, setErrors] = useState([])
  const [responseErrors, setResponseErrors] = useState([])
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const navigate = useNavigate()

  // useEffect(() => {
  //   const formData = JSON.parse(localStorage.getItem('userData'))
  //   if (formData) {
  //     setFormData(formData)
  //   }
  // }, [])
  useEffect(() => {
    getCustomerByID(elID, (response) => {
      setFormData(response)
    })
  }, [])

  const handleChange = (e) => {
    const { name, value } = e.target

    if (!validation[name]({ ...formData, [name]: value })) {
      setErrors(errors.filter((el) => el !== name))
    } else {
      if (!errors.includes(name)) setErrors([...errors, name])
    }

    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    const validationErrors = Object.keys(validation).filter((el) =>
      validation[el](formData)
    )

    setErrors(validationErrors)

    if (validationErrors.length === 0) {
      updateCustomerData(formData.id, formData, handleSuccess, handleError)
    }
  }

  function handleError(error) {
    console.error(error)

    // Parse the error response
    const errorResponse = error.response.data
    const parsedErrors = Object.values(errorResponse).flat()

    // Update the state with the parsed errors
    setResponseErrors(parsedErrors)
  }

  function handleSuccess(response) {
    // console.log(response);
    setAlertMessage('Datele au fost modificate cu succes!')
    setShowAlert(true)
    setFormData(cleanFormData)
    localStorage.removeItem('userData')
    const timer = setTimeout(() => {
      // window.location.reload()
      // window.location.href = '/login'

      // navigate(`/${customersUrl}`, { state: { elID } })
      document.body.style.overflow = ''
      cancel(false)
    }, 2100)
    return () => clearTimeout(timer)
  }

  // const timer = setTimeout(() => {
  //   navigate(`/${backUrl}`, { state: { id } })
  //   // window.location.href = `/${backUrl}`
  //   cancel()
  //   document.body.style.overflow = ''
  // }, 2100)
  // return () => clearTimeout(timer)

  // Function to remove an error message from the state
  const removeError = (index) => {
    setResponseErrors(responseErrors.filter((_, i) => i !== index))
  }

  return (
    <>
      {showAlert && <SuccesAlert message={alertMessage} />}

      <div>
        {responseErrors.map((message, index) => (
          <ErrorAlert
            key={index}
            message={message}
            onRemove={() => removeError(index)}
          />
        ))}
      </div>

      <ModifyCustomerDataStyled>
        <PopUpLevel2Styled>
          <div className='popUpLevel2Container'>
            <div className='title'>
              <h2>Modifica datele de client</h2>
            </div>
            <CancelButton>
              <button className='cancel' onClick={() => cancel(false)}>
                Anuleaza
              </button>
            </CancelButton>
            <GeneralFormStyled>
              <form onSubmit={handleSubmit}>
                <div className='multipleFields'>
                  <div>
                    <FieldLabel>
                      Utilizator
                      {errors.includes('username') === true ? (
                        <ErrorLabel>Utilizator prea scurt!</ErrorLabel>
                      ) : formData.username.length >= 4 ? (
                        <SuccesLabel>&#10004;</SuccesLabel>
                      ) : (
                        <InfoLabel>Minim 4 caractere</InfoLabel>
                      )}
                    </FieldLabel>
                    <input
                      type='text'
                      name='username'
                      value={formData.username}
                      onChange={handleChange}
                      placeholder='Utilizator'
                      disabled
                    />
                  </div>
                  <div>
                    <FieldLabel>
                      Nume
                      {errors.includes('first_name') === true ? (
                        <ErrorLabel>Nume prea scurt!</ErrorLabel>
                      ) : formData.first_name.length >= 3 ? (
                        <SuccesLabel>&#10004;</SuccesLabel>
                      ) : (
                        <InfoLabel>Minim 3 caractere</InfoLabel>
                      )}
                    </FieldLabel>
                    <input
                      type='text'
                      name='first_name'
                      value={formData.first_name}
                      onChange={handleChange}
                      placeholder='Nume'
                    />
                  </div>
                  <div>
                    <FieldLabel>
                      Prenume
                      {errors.includes('last_name') === true ? (
                        <ErrorLabel>Prenume prea scurt!</ErrorLabel>
                      ) : formData.last_name.length >= 3 ? (
                        <SuccesLabel>&#10004;</SuccesLabel>
                      ) : (
                        <InfoLabel>Minim 3 caractere</InfoLabel>
                      )}
                    </FieldLabel>
                    <input
                      type='text'
                      name='last_name'
                      value={formData.last_name}
                      onChange={handleChange}
                      placeholder='Prenume'
                    />
                  </div>
                </div>
                <div className='multipleFields'>
                  <div>
                    <FieldLabel>
                      Telefon
                      {!isNaN(formData.phone) &&
                      errors.includes('phone') === true ? (
                        <ErrorLabel>Telefon prea scurt!</ErrorLabel>
                      ) : errors.includes('phone') ? (
                        <ErrorLabel>Numar incorect</ErrorLabel>
                      ) : formData.phone.length >= 10 &&
                        !isNaN(formData.phone) ? (
                        <SuccesLabel>&#10004;</SuccesLabel>
                      ) : (
                        <InfoLabel>Minim 10 cifre</InfoLabel>
                      )}
                    </FieldLabel>
                    <input
                      type='text'
                      name='phone'
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder='Telefon'
                    />
                  </div>
                  <div>
                    <FieldLabel>
                      Email
                      {errors.includes('email') === true ? (
                        <ErrorLabel>Email invalid!</ErrorLabel>
                      ) : formData.email.length >= 6 ? (
                        <SuccesLabel>&#10004;</SuccesLabel>
                      ) : (
                        <InfoLabel>Minim 6 caractere</InfoLabel>
                      )}
                    </FieldLabel>
                    <input
                      type='text'
                      name='email'
                      value={formData.email}
                      onChange={handleChange}
                      placeholder='Email'
                      disabled
                    />
                  </div>
                </div>
                <div className='oneField'>
                  <FieldLabel>
                    Localitate
                    {errors.includes('city') === true ? (
                      <ErrorLabel>Localitatea e prea scurta!</ErrorLabel>
                    ) : formData.city.length >= 3 ? (
                      <SuccesLabel>&#10004;</SuccesLabel>
                    ) : (
                      <InfoLabel>Minim 3 caractere</InfoLabel>
                    )}
                  </FieldLabel>
                  <input
                    type='text'
                    name='city'
                    value={formData.city}
                    onChange={handleChange}
                    placeholder='Localitate'
                  />
                </div>
                <div className='multipleFields'>
                  <div>
                    <FieldLabel>
                      Strada
                      {errors.includes('street') === true ? (
                        <ErrorLabel>Strada e prea scurta!</ErrorLabel>
                      ) : formData.street.length >= 3 ? (
                        <SuccesLabel>&#10004;</SuccesLabel>
                      ) : (
                        <InfoLabel>Minim 3 caractere</InfoLabel>
                      )}
                    </FieldLabel>
                    <input
                      type='text'
                      name='street'
                      value={formData.street}
                      onChange={handleChange}
                      placeholder='Strada'
                    />
                  </div>
                  <div>
                    <FieldLabel>Nr strada</FieldLabel>
                    <input
                      type='text'
                      name='street_number'
                      value={formData.street_number}
                      onChange={handleChange}
                      placeholder='Nr strada'
                    />
                  </div>
                </div>
                <div className='multipleFields'>
                  <div>
                    <FieldLabel>Bloc</FieldLabel>
                    <input
                      type='text'
                      name='building'
                      value={formData.building}
                      onChange={handleChange}
                      placeholder='Bloc'
                    />
                  </div>
                  <div>
                    <FieldLabel>Scara</FieldLabel>
                    <input
                      type='text'
                      name='staircase'
                      value={formData.staircase}
                      onChange={handleChange}
                      placeholder='Scara'
                    />
                  </div>
                  <div>
                    <FieldLabel>Apartament</FieldLabel>
                    <input
                      type='text'
                      name='apartment'
                      value={formData.apartment}
                      onChange={handleChange}
                      placeholder='Apartament'
                    />
                  </div>
                </div>
                <div className='oneField'>
                  <FieldLabel>Nr. interfon</FieldLabel>
                  <input
                    type='text'
                    name='intercom'
                    value={formData.intercom}
                    onChange={handleChange}
                    placeholder='Nr. interfon'
                  />
                </div>
                <div className='submitContainer'>
                  <button className='submitButton'>Modifica datele</button>
                </div>
              </form>
            </GeneralFormStyled>
          </div>
        </PopUpLevel2Styled>
      </ModifyCustomerDataStyled>
    </>
  )
}

export default ModifyCustomerData
