import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { updatePassword } from '../../../../api/CustomerPasswordReset'
import { ChangePasswordStyled } from './ChangePasswordStyled'
import {
  GeneralFormStyled,
  FieldLabel,
  InfoLabel,
  SuccesLabel,
  ErrorLabel,
  CancelButton,
} from '../../../../styles/elements/formsStyle'
import ErrorAlert from '../../../../components/common/alert/ErrorAlert'
import SuccesAlert from '../../../../components/common/alert/SuccesAlert'
import { getCustomerByID } from '../../../../api/customers'
import { PopUpLevel2Styled } from '../../../../components/common/popUpWindows/PopUpLevel2Styled'

const cleanFormData = {
  password: '',
  passwordRepeat: '',
}

const validation = {
  password: (data) => data.password.length < 8,
  passwordRepeat: (data) => data.passwordRepeat !== data.password,
}

function ChangePassword({ cancel, elID }) {
  const [customerData, setCustomerData] = useState(cleanFormData)
  const [formData, setFormData] = useState(cleanFormData)
  const navigate = useNavigate()
  const [errors, setErrors] = useState([])
  const [responseErrors, setResponseErrors] = useState([])
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')

  // useEffect(() => {
  //   const customerData = JSON.parse(localStorage.getItem('userData'))
  //   if (customerData) {
  //     setCustomerData(customerData)
  //   }
  // }, [])

  useEffect(() => {
    getCustomerByID(elID, (response) => {
      setCustomerData(response)
    })
  }, [])

  const handleChange = (e) => {
    const { name, value } = e.target

    if (!validation[name]({ ...formData, [name]: value })) {
      setErrors(errors.filter((el) => el !== name))
    } else {
      if (!errors.includes(name)) setErrors([...errors, name])
    }

    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const validationErrors = Object.keys(validation).filter((el) =>
      validation[el](formData)
    )

    setErrors(validationErrors)
    if (validationErrors.length === 0) {
      updatePassword(customerData.id, formData, handleSuccess, handleError)
    }
  }

  function handleError(error) {
    console.error(error)

    // Parse the error response
    const errorResponse = error.response.data
    const parsedErrors = Object.values(errorResponse).flat()

    // Update the state with the parsed errors
    setResponseErrors(parsedErrors)
  }

  function handleSuccess(response) {
    console.log(response)
    setAlertMessage('Parola a fost schimbata cu succes!')
    setShowAlert(true)
    setFormData(cleanFormData)
    localStorage.removeItem('userData')
    const timer = setTimeout(() => {
      // window.location.reload();
      // window.location.href = '/login'
      document.body.style.overflow = ''
      cancel(false)
    }, 2100)

    return () => clearTimeout(timer)
  }

  // Function to remove an error message from the state
  const removeError = (index) => {
    setResponseErrors(responseErrors.filter((_, i) => i !== index))
  }

  return (
    <>
      {showAlert && <SuccesAlert message={alertMessage} />}

      <div>
        {responseErrors.map((message, index) => (
          <ErrorAlert
            key={index}
            message={message}
            onRemove={() => removeError(index)}
          />
        ))}
      </div>
      <PopUpLevel2Styled>
        <div className='popUpLevel2Container'>
          <ChangePasswordStyled>
            <div className='title'>
              <h2>Schimba parola</h2>
            </div>
            <CancelButton>
              <button className='cancel' onClick={() => cancel(false)}>
                Anuleaza
              </button>
            </CancelButton>
            <GeneralFormStyled>
              <form onSubmit={handleSubmit}>
                <div className='oneField'>
                  <FieldLabel>
                    Parola
                    {errors.includes('password') === true ? (
                      <ErrorLabel>Parola e prea scurta!</ErrorLabel>
                    ) : formData.password.length >= 8 ? (
                      <SuccesLabel>&#10004;</SuccesLabel>
                    ) : (
                      <InfoLabel>Minim 8 caractere</InfoLabel>
                    )}
                  </FieldLabel>
                  <input
                    type='password'
                    name='password'
                    value={formData.password}
                    onChange={handleChange}
                    placeholder='Parola'
                  />
                </div>
                <div className='oneField'>
                  <FieldLabel>
                    Repeta parola
                    {errors.includes('passwordRepeat') === true ? (
                      <ErrorLabel>Parolele nu se potrivesc!</ErrorLabel>
                    ) : formData.passwordRepeat.length >= 8 ? (
                      <SuccesLabel>&#10004;</SuccesLabel>
                    ) : (
                      <InfoLabel>Minim 8 caractere</InfoLabel>
                    )}
                  </FieldLabel>
                  <input
                    type='password'
                    name='passwordRepeat'
                    value={formData.passwordRepeat}
                    onChange={handleChange}
                    placeholder='Repeta parola'
                  />
                </div>
                <div className='submitContainer'>
                  <button className='redSubmitButton'>Schimba parola</button>
                </div>
              </form>
            </GeneralFormStyled>
          </ChangePasswordStyled>
        </div>
      </PopUpLevel2Styled>
    </>
  )
}

export default ChangePassword
