import styled from 'styled-components'
import { desktop, tablet, mobile } from '../../../styles/setings/mediaScreens'
import { COLORS } from '../../../styles/setings/colors'

export const HeaderStyled = styled.header`
  margin: auto;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 100;

  // height: 70px;
  .row.header {
    padding: 0.5em;
    background-color: ${COLORS.colorHeaderBackground};
    display: flex;
    flex-wrap: nowrap;
    // justify-content: center;
    ${desktop`
      height: 110px;
      align-items: flex-start;
      // box-shadow: 0px 5px 7px #989898;
      `}
    ${tablet` 
      // height: 120px;
      box-shadow: 0px 5px 7px #989898;
      `}
    ${mobile` 
      // height: 180px;
      box-shadow: 0px 5px 7px #989898;
      `}

    // .logo
    .identity {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      ${mobile` 
        flex-direction: row;
        justify-content: flex-start;
        `}

      .logo {
        display: flex;
        flex-direction: row;
        width: 100%;
        ${desktop` 
          // margin-top: 5px;
          margin-left: 10px;
        `}
        ${tablet`
          // margin-top: 15px;
          margin-left: 10px;
          `}
        ${mobile`
          // height: 190px;
          // flex-direction: column;
          // margin-top: 5px;
          margin-left: 0px;
          `}

        .logoImg {
          display: flex;
          justify-content: flex-start;

          img {
            height: 80px;
            max-width: 160px;
            margin: 0 15px 0 0;
            /* ${mobile`
            height: 70px;
            // position: absolute;
            top: 10px;
            left: 10px;
            `} */

            ${mobile`
            height: 60px;
            `}
          }

          .logo-icon {
            background-color: ${COLORS.colorLogoIconBg};
            border-radius: 5px;
            font-family: $logo-font-family;
            color: ${COLORS.colorLogoIconText};
            font-size: 2.5em;
            padding: 0 0.2em;
            margin-bottom: 0.2em;
            letter-spacing: 0.2rem;
            text-decoration: none;
          }

          ${mobile`
            // height: 190px;
            justify-content: space-between;
            width: auto;
            // margin: 10px;
            `}
        }

        .identityTitles {
          ${desktop``}
          ${tablet``}
          ${mobile`
            display: none;
            // display: flex;
            // flex-direction: column;
            // justify-content: center;
            // align-items: center;
            `}
          h2 {
            padding: 10px 10px 10px 0;
            margin-left: 15px;
            text-decoration: underline;
            text-decoration-thickness: 4px;
            text-decoration-color: ${COLORS.colorTextUnderlineDefault};
            span {
              font-weight: bold;
            }
            ${desktop`
              font-size: 1.7em;
              `}
            ${tablet`
              font-size: 1.7em;
              `}
            ${mobile`
              margin-left: 0;
              font-size: 1.6em;
              text-align: center;
              `}
          }
          .logoText {
            display: flex;
            justify-content: center;
            font-weight: bold;
            margin-left: 15px;
            letter-spacing: 0.2em;

            ${desktop`
              font-size: 1.2em;
              `}
            ${tablet`
              font-size: 1.2em;
              `}
            ${mobile`
              // margin-left: 0;
              font-size: 0.9em;
              `}
          }
        }
      }
    }
    .acountButons {
      display: flex;
      position: absolute;
      right: 4em;
      top: 1rem;
      width: auto;
      height: 2.1rem;
      /* cursor: pointer; */
      /* background-color: grey; */
      ${desktop`
    display: none;
  `}
      a {
        display: inline-block;
        /* background-color: ${COLORS.colorUserBgMenu}; */
        background-color: #cfcfcf;
        border: solid ${COLORS.colorUserBorderMenu} 1px;
        border-radius: 5px;
        padding: 0.1em 0.1em;
        /* color: ${COLORS.colorUserTextMenu}; */
        color: #000;
        font-weight: bold;
        font-size: 2em;
        /* width: 50px; */
        /* height: auto; */
        width: 2.5rem;
        height: 2.5rem;
        &.active {
          color: ${COLORS.colorUserActiveTextMenu};
          background-color: ${COLORS.colorUserActiveBgMenu};
          border: solid ${COLORS.colorUserActiveBorderMenu} 1px;
        }
        &:hover {
          color: ${COLORS.colorUserHovTextMenu};
          /* background-color: ${COLORS.colorUserHovBgMenu}; */
          background-color: #949494;
          border: solid ${COLORS.colorUserHovBorderMenu} 1px;
        }
      }
      .cart {
        display: flex;
        margin-left: 10px;
        width: auto;
      }
      .phone {
        margin-right: 10px;
      }
    }

    .userActions {
      ${tablet`
        display: none;
      `}
    }

    .userMenu {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-wrap: wrap;
      height: 40px;

      // ${tablet`
      //   display: none;
      // `}

      ul {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        li {
          display: flex;
          margin-left: 0.1em;
          margin-right: 0.5em;
          width: auto;
          a {
            /* background-color: ${COLORS.colorUserBgMenu}; */
            display: inline-block;
            background-color: #cfcfcf;
            color: #000;
            border: solid ${COLORS.colorUserBorderMenu} 1px;
            border-radius: 5px;
            padding: 0.1em 0.1em;
            /* color: ${COLORS.colorUserTextMenu}; */
            font-weight: bold;
            font-size: 2em;
            min-width: 2.5rem;
            height: 2.5rem;
            &.active {
              color: ${COLORS.colorUserActiveTextMenu};
              background-color: ${COLORS.colorUserActiveBgMenu};
              border: solid ${COLORS.colorUserActiveBorderMenu} 1px;
            }
            &:hover {
              color: ${COLORS.colorUserHovTextMenu};
              /* background-color: ${COLORS.colorUserHovBgMenu}; */
              background-color: #949494;
              border: solid ${COLORS.colorUserHovBorderMenu} 1px;
            }

            .cart {
              display: flex;
              margin-left: 10px;
              width: auto;
            }
          }
          .buttonLogin {
            background-color: ${COLORS.colorUserBgMenu};
            border: solid ${COLORS.colorUserBorderMenu} 1px;
            border-radius: 5px;
            padding: 0.5em 0.8em;
            color: ${COLORS.colorUserTextMenu};
            font-weight: bold;
            &.active {
              color: ${COLORS.colorUserActiveTextMenu};
              background-color: ${COLORS.colorUserActiveBgMenu};
              border: solid ${COLORS.colorUserActiveBorderMenu} 1px;
            }
            &:hover {
              color: ${COLORS.colorUserHovTextMenu};
              background-color: ${COLORS.colorUserHovBgMenu};
              border: solid ${COLORS.colorUserHovBorderMenu} 1px;
            }
          }
        }
        .phoneNr {
          font-size: 1.2em;
          font-weight: bold;
        }
      }
    }

    .searchBar {
      /* width: 300px; */
      /* margin-top: 55px; */
      /* display: flex; */
      /* justify-content: flex-end; */
    }
  }
`
